import React from 'react';
import { useApp } from '../contexts/AppContext';
import { ArrowRight } from 'lucide-react';

const TutorialOverlay = () => {
  const { 
    isTutorialActive, 
    tutorialStep, 
    advanceTutorial, 
    endTutorial 
  } = useApp();
  
  if (!isTutorialActive) {
    return null;
  }
  
  const getTutorialContent = () => {
    switch (tutorialStep) {
      case 'welcome':
        return {
          title: "Welcome to GotGame!",
          message: "Let's get you started with the basics. We'll show you how to swipe, like, and chat.",
          position: "center",
          nextStep: "swipeInstructions"
        };
      case 'swipeInstructions':
        return {
          title: "Swipe to Match",
          message: "Swipe right to like someone, or left to pass. When someone likes you back, it's a match!",
          position: "bottom",
          highlight: "swipe-area",
          nextStep: "likeInstructions"
        };
      case 'likeInstructions':
        return {
          title: "Like & Match",
          message: "Click the heart button to like someone. We've already set up a match for you to try!",
          position: "bottom-right",
          highlight: "like-button",
          nextStep: "chatInstructions"
        };
      case 'chatInstructions':
        return {
          title: "Chat with Matches",
          message: "Click on a match to start chatting. Let's try it now!",
          position: "top-right",
          highlight: "matches-tab",
          nextStep: "chooseChatInstructions"
        };
      case 'chooseChatInstructions':
        return {
          title: "Select a Match",
          message: "Click on your match to start a conversation.",
          position: "center",
          highlight: "match-list",
          nextStep: "chatInputInstructions"
        };
      case 'chatInputInstructions':
        return {
          title: "Start Chatting",
          message: "Type a message and hit send to start the conversation. Have fun!",
          position: "bottom",
          highlight: "chat-input",
          nextStep: "complete"
        };
      case 'complete':
        return {
          title: "You're All Set!",
          message: "You now know the basics. Enjoy chatting with your matches!",
          position: "center",
          isCompleted: true
        };
      default:
        return {
          title: "Explore GotGame",
          message: "Feel free to browse around and discover more features!",
          position: "center",
          isCompleted: true
        };
    }
  };
  
  const content = getTutorialContent();
  const positionClass = getPositionClass(content.position);
  
  const handleNextStep = () => {
    if (content.isCompleted) {
      endTutorial();
    } else if (content.nextStep) {
      advanceTutorial(content.nextStep);
    }
  };
  
  return (
    <div className="fixed inset-0 z-50 pointer-events-none">
      {/* Semi-transparent overlay */}
      <div className="absolute inset-0 bg-black/50 pointer-events-auto" />
      
      {/* Highlight area if specified */}
      {content.highlight && (
        <div className="absolute inset-0 flex items-center justify-center">
          <div 
            className="absolute w-64 h-64 rounded-full border-4 border-white/80 animate-pulse" 
            style={{
              // Position would be set by JS in a real implementation
              // This is just a placeholder position
              bottom: content.position.includes("bottom") ? "100px" : "auto",
              top: content.position.includes("top") ? "100px" : "auto",
              left: content.position.includes("left") ? "100px" : "auto",
              right: content.position.includes("right") ? "100px" : "auto",
              // If position is "center", center it
              ...((content.position === "center") && {
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              })
            }}
          />
        </div>
      )}
      
      {/* Tutorial message */}
      <div className={`pointer-events-auto absolute max-w-sm bg-gradient-to-br from-indigo-600 to-purple-600 p-4 rounded-lg shadow-xl ${positionClass}`}>
        <h3 className="text-xl font-bold text-white mb-2">{content.title}</h3>
        <p className="text-white/90 mb-4">{content.message}</p>
        <button 
          className="flex items-center justify-center bg-white text-indigo-600 py-2 px-4 rounded-md font-semibold hover:bg-white/90 transition-colors w-full"
          onClick={handleNextStep}
        >
          {content.isCompleted ? 'Got it!' : (
            <>
              Next <ArrowRight className="ml-2 w-4 h-4" />
            </>
          )}
        </button>
      </div>
    </div>
  );
};

// Helper function to get position classes
const getPositionClass = (position) => {
  switch (position) {
    case 'top-left':
      return 'top-4 left-4';
    case 'top-right':
      return 'top-4 right-4';
    case 'bottom-left':
      return 'bottom-4 left-4';
    case 'bottom-right':
      return 'bottom-4 right-4';
    case 'bottom':
      return 'bottom-4 left-1/2 transform -translate-x-1/2';
    case 'top':
      return 'top-4 left-1/2 transform -translate-x-1/2';
    case 'left':
      return 'left-4 top-1/2 transform -translate-y-1/2';
    case 'right':
      return 'right-4 top-1/2 transform -translate-y-1/2';
    case 'center':
    default:
      return 'top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2';
  }
};

export default TutorialOverlay; 