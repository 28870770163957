import React from 'react';
import { motion } from 'framer-motion';
import { useApp } from '../../contexts/AppContext';

const MemoryBar = () => {
  const { memoryUsage, subscriptionPlan } = useApp();
  const total = subscriptionPlan?.contextWindow || 5000;
  const used = memoryUsage?.used || 0;
  const percentage = Math.min(100, Math.round((used / total) * 100));

  return (
    <div className="bg-black/10 p-2">
      <div className="relative h-1.5 bg-white/10 rounded-full overflow-hidden">
        <motion.div
          className="absolute top-0 left-0 h-full bg-gradient-to-r from-blue-500 to-purple-500"
          initial={{ width: "0%" }}
          animate={{ width: `${percentage}%` }}
          transition={{ type: 'spring', damping: 10 }}
        />
      </div>
      <div className="flex justify-between text-xs text-white/60 mt-1">
        <span>{percentage}% Memory</span>
        <span>{(used/1000).toFixed(1)}k/{(total/1000).toFixed(1)}k tokens</span>
      </div>
    </div>
  );
};

export default MemoryBar;
