import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { MessageCircle, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const MatchModal = ({ isOpen, onClose, match }) => {
  const navigate = useNavigate();

  if (!isOpen || !match) return null;

  // Get the first photo URL
  const getPhotoUrl = () => {
    if (!match.profileData) {
      console.warn('Match has no profileData', match);
      return null;
    }
    
    const photos = match.profileData.photos;
    if (!photos) {
      console.warn('Match has no photos', match.profileData);
      return null;
    }

    // If photos is an array, get first photo's URL
    if (Array.isArray(photos) && photos.length > 0) {
      if (photos[0].url) return photos[0].url;
      // If the photo item itself is a string URL
      if (typeof photos[0] === 'string') return photos[0];
    }
    // If photos is a single object, get its URL
    if (photos.url) {
      return photos.url;
    }
    // If photos is a string, return it directly
    if (typeof photos === 'string') {
      return photos;
    }

    console.warn('Could not find valid photo URL in:', photos);
    return null;
  };

  const handleMessage = () => {
    // Get profile image URL first
    const profileImageUrl = getPhotoUrl();
    
    navigate('/chat', { 
      state: { 
        mode: 'swipe',
        matchId: match.id,
        character: {
          id: match.profileData.name.toLowerCase(),
          name: match.profileData.name,
          description: match.profileData.bio,
          photos: match.profileData.photos, // Pass the entire photos object/array
          profileImageUrl: profileImageUrl, // Explicitly include profile image URL
          personality: match.profileData.personality
        }
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50 flex items-center justify-center p-4"
      >
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
          className="bg-gradient-to-b from-purple-600 to-pink-500 rounded-3xl p-6 max-w-md w-full text-center relative"
        >
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-white/60 hover:text-white"
          >
            <X size={24} />
          </button>

          <div className="space-y-6">
            <div className="space-y-2">
              <h2 className="text-3xl font-bold text-white">It's a Match!</h2>
              <p className="text-white/80">You and {match.profileData.name} liked each other</p>
            </div>

            <div className="flex justify-center gap-4">
              <div className="w-32 h-32 rounded-full overflow-hidden border-4 border-white">
                {getPhotoUrl() ? (
                  <img
                    src={getPhotoUrl()}
                    alt={match.profileData.name}
                    className="w-full h-full object-cover"
                    onError={(e) => {
                      console.error('Image failed to load:', getPhotoUrl());
                      e.target.src = 'https://via.placeholder.com/128?text=?';
                    }}
                  />
                ) : (
                  <div className="w-full h-full bg-gray-300 flex items-center justify-center">
                    <span className="text-gray-600 text-2xl">?</span>
                  </div>
                )}
              </div>
            </div>

            <div className="space-y-4">
              <button
                onClick={handleMessage}
                className="w-full bg-white text-purple-600 rounded-full py-3 px-6 font-semibold flex items-center justify-center gap-2 hover:bg-white/90 transition-colors"
              >
                <MessageCircle size={20} />
                Send Message
              </button>
              <button
                onClick={onClose}
                className="w-full bg-transparent text-white border border-white rounded-full py-3 px-6 font-semibold hover:bg-white/10 transition-colors"
              >
                Keep Swiping
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default MatchModal;
