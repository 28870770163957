// src/components/chat/ChatMessages.jsx
import React, { forwardRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import CharacterPhotoRequest from './CharacterPhotoRequest';
import SnapchatStylePreview from './SnapchatStylePreview';
import PhotoGeneratingIndicator from './PhotoGeneratingIndicator';

const Message = forwardRef(({ msg, onAcceptPhoto, onDeclinePhoto, resetPhotoClicks }, ref) => {
  // If message content is a JSON string (happens on page reload), try to parse it
  if (typeof msg.content === 'string' && (msg.content.startsWith('{') || msg.content.includes('"type":"photo"'))) {
    try {
      const parsedContent = JSON.parse(msg.content);
      if (parsedContent.type === 'photo' && parsedContent.url) {
        // Convert to a proper photo message
        msg = {
          ...msg,
          type: 'photo',
          photoUrl: parsedContent.url,
          text: parsedContent.prompt || ''
        };
      }
    } catch (e) {
      console.error('Error parsing message JSON:', e);
    }
  }

  // Handle photo message
  if (msg.type === 'photo') {
    // Don't render if no valid URL
    if (!msg.photoUrl) {
      // Check if the message itself is the raw JSON string
      if (typeof msg === 'string' && msg.includes('"type":"photo"') && msg.includes('"url":')) {
        try {
          const parsedMsg = JSON.parse(msg);
          if (parsedMsg.url) {
            return (
              <motion.div
                ref={ref}
                layout
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.2, layout: { duration: 0.2 } }}
                className="self-start mb-4 max-w-[80%]"
              >
                <div className="bg-white/10 rounded-xl p-2 relative">
                  <img 
                    src={parsedMsg.url} 
                    alt={`Photo from ${parsedMsg.prompt || 'Character'}`}
                    className="rounded-lg w-full max-h-[500px] object-cover"
                    onLoad={() => console.log('📸 Image loaded successfully:', parsedMsg.url)}
                    onError={(e) => {
                      console.error('📸 Error loading image:', e, parsedMsg.url);
                      e.target.style.display = 'none';
                      e.target.parentNode.innerHTML += `<div class="p-4 text-sm text-white">Failed to load image. <a href="${parsedMsg.url}" target="_blank" class="underline">View external link</a></div>`;
                    }}
                  />
                </div>
              </motion.div>
            );
          }
        } catch (e) {
          console.error('Error parsing JSON message:', e);
        }
      }
      return null;
    }
    
    return (
      <motion.div
        ref={ref}
        layout
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.2, layout: { duration: 0.2 } }}
        className="self-start mb-4 max-w-[80%]"
      >
        <div className="bg-white/10 rounded-xl p-2 relative">
          <img 
            src={msg.photoUrl} 
            alt={`Photo from ${msg.character}`}
            className="rounded-lg w-full max-h-[500px] object-cover"
            onLoad={() => console.log('📸 Image loaded successfully:', msg.photoUrl)}
            onError={(e) => {
              console.error('📸 Error loading image:', e, msg.photoUrl);
              e.target.style.display = 'none';
              e.target.parentNode.innerHTML += `<div class="p-4 text-sm text-white">Failed to load image. <a href="${msg.photoUrl}" target="_blank" class="underline">View external link</a></div>`;
            }}
          />
        </div>
      </motion.div>
    );
  }
  
  // Handle Snapchat-style photo preview
  if (msg.type === 'photoRequest') {
    return (
      <motion.div
        ref={ref}
        layout
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.2, layout: { duration: 0.2 } }}
      >
        <SnapchatStylePreview 
          photoData={msg.photoData}
          character={msg.character}
          onOpen={() => onAcceptPhoto(msg.photoData)}
          resetClicked={resetPhotoClicks}
        />
      </motion.div>
    );
  }
  
  // Handle photo generation loading state
  if (msg.type === 'photoGenerating') {
    return (
      <motion.div
        ref={ref}
        layout
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.2, layout: { duration: 0.2 } }}
        className="self-start mb-4 max-w-[80%]"
      >
        <PhotoGeneratingIndicator />
      </motion.div>
    );
  }
  
  // Regular text message
  return (
    <motion.div
      ref={ref}
      layout
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.2, layout: { duration: 0.2 } }}
      className={`${msg.sender === 'ai' ? 'self-start' : 'self-end'} flex flex-col mb-4 max-w-[80%]`}
    >
      <div
        className={`${
          msg.sender === 'ai' ? 'bg-white/10' : 'bg-white/20'
        } rounded-xl p-4`}
      >
        {/* Check if text is actually a JSON photo object */}
        {typeof msg.text === 'string' && msg.text.includes('"type":"photo"') && msg.text.includes('"url"') ? (
          (() => {
            try {
              const photoData = JSON.parse(msg.text);
              if (photoData.type === 'photo' && photoData.url) {
                return (
                  <img 
                    src={photoData.url} 
                    alt={`Photo from ${msg.character}`}
                    className="rounded-lg w-full max-h-[500px] object-cover"
                    onLoad={() => console.log('📸 Image loaded from text JSON:', photoData.url)}
                    onError={(e) => {
                      console.error('📸 Error loading image from text JSON:', e, photoData.url);
                      e.target.style.display = 'none';
                      e.target.parentNode.innerHTML += `<div class="text-sm text-white">Failed to load image. <a href="${photoData.url}" target="_blank" class="underline">View external link</a></div>`;
                    }}
                  />
                );
              }
              return <p className="text-white whitespace-pre-wrap break-words">{msg.text}</p>;
            } catch (e) {
              console.error('Error parsing JSON in text message:', e);
              return <p className="text-white whitespace-pre-wrap break-words">{msg.text}</p>;
            }
          })()
        ) : (
          <p className="text-white whitespace-pre-wrap break-words">{msg.text}</p>
        )}
      </div>
    </motion.div>
  );
});

const TypingIndicator = forwardRef((props, ref) => (
  <motion.div
    ref={ref}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.2 }}
    className="bg-white/10 rounded-xl p-4 max-w-[80%] self-start"
  >
    <div className="flex space-x-2">
      <div className="w-2 h-2 bg-white/60 rounded-full animate-bounce" />
      <div className="w-2 h-2 bg-white/60 rounded-full animate-bounce delay-100" />
      <div className="w-2 h-2 bg-white/60 rounded-full animate-bounce delay-200" />
    </div>
  </motion.div>
));

const ChatMessages = React.memo(({ 
  messages, 
  isTyping, 
  chatMode, 
  messagesEndRef,
  onAcceptPhoto,
  onDeclinePhoto,
  resetPhotoClicks
}) => {
  return (
    <div className="flex-1 p-4 overflow-y-auto flex flex-col">
      <div className="flex-1 flex flex-col space-y-4">
        <AnimatePresence>
          {messages.map((msg) => (
            <Message 
              key={`${msg.id}-${msg.sender}-${msg.type || 'text'}`} 
              msg={msg}
              onAcceptPhoto={onAcceptPhoto}
              onDeclinePhoto={onDeclinePhoto}
              resetPhotoClicks={resetPhotoClicks}
            />
          ))}
          {isTyping && chatMode === 'text' && <TypingIndicator key="typing" />}
        </AnimatePresence>
      </div>
      <div ref={messagesEndRef} />
    </div>
  );
});

Message.displayName = 'Message';
TypingIndicator.displayName = 'TypingIndicator';
ChatMessages.displayName = 'ChatMessages';

export default ChatMessages;
