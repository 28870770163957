// src/components/chat/constants.js
export const GAME_SETTINGS = {
    timeTrial: {
      duration: 180, // 3 minutes
      description: 'Race against time to maximize your rizz'
    },
    lifeBar: {
      initialValue: 100,
      decayRate: 0.5,
      description: 'Keep your charm alive'
    },
    classic: {
      messageLimit: 14,
      description: 'Standard mode with message limit'
    },
    endless: {
      description: 'Chat until you drop'
    }
  };
  
  export const SCORE_IMPACTS = {
    EXCELLENT: { threshold: 9, impact: 15 },
    GOOD: { threshold: 7, impact: 5 },
    NEUTRAL: { threshold: 5, impact: -5 },
    BAD: { threshold: 3, impact: -15 },
    TERRIBLE: { threshold: 0, impact: -30 }
  };

  export const TEXTING_BEHAVIORS = {
    NORMAL: {
      name: "normal",
      description: "Responds with a single message at normal speed",
      multiTextProbability: 0.3,     // 50% chance of sending multiple texts
      maxConsecutiveMessages: 2,     // Can now send up to 2 messages
      responseDelay: {
        min: 500,                    // Minimum response delay in ms
        max: 3000                    // Maximum response delay in ms
      }
    },
    DOUBLE_TEXTER: {
      name: "doubleTexter",
      description: "Often sends two messages in a row",
      multiTextProbability: 0.6,     // 60% chance of sending multiple texts
      maxConsecutiveMessages: 2,     // Up to 2 messages at once
      responseDelay: {
        min: 800,
        max: 3500
      }
    },
    TRIPLE_TEXTER: {
      name: "tripleTexter",
      description: "Typically sends 3 consecutive messages",
      multiTextProbability: 0.8,     // 80% chance of sending multiple texts
      maxConsecutiveMessages: 3,     // Up to 3 messages at once
      responseDelay: {
        min: 700,
        max: 3000
      }
    },
    SPAM_TEXTER: {
      name: "spamTexter",
      description: "Bombards with many rapid messages",
      multiTextProbability: 0.9,     // 90% chance of sending multiple texts
      maxConsecutiveMessages: 5,     // Up to 5 messages at once!
      responseDelay: {
        min: 300,
        max: 1500                    // Faster between messages
      }
    },
    SLOW_RESPONDER: {
      name: "slowResponder",
      description: "Takes a long time to respond",
      multiTextProbability: 0.2,     // Occasionally double texts
      maxConsecutiveMessages: 2,
      responseDelay: {
        min: 5000,                   // Very slow responses
        max: 15000                   // Up to 15 seconds delay
      }
    },
    GHOSTER: {
      name: "ghoster",
      description: "Sometimes doesn't respond for a while, then might send multiple texts",
      multiTextProbability: 0.5,     // 50% chance of multi-texts when they do respond
      maxConsecutiveMessages: 3,
      ghostingProbability: 0.3,      // 30% chance of "ghosting" temporarily
      ghostingDelay: {
        min: 15000,                  // When ghosting, minimum of 15s delay
        max: 40000                   // Up to 40s of not responding
      },
      responseDelay: {
        min: 2000,
        max: 7000
      }
    }
  };