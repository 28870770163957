// src/services/ai.js

// Keep only necessary imports if any, remove AI SDK specific ones

export class AIService {
  constructor(authenticatedFetch) {
    this.API_URL = import.meta.env.VITE_API_URL;
    this.token = null;
    this.isGuestMode = false;
    this.authenticatedFetch = authenticatedFetch; // Keep authenticatedFetch if needed for other methods

    console.log('🤖 AIService: Constructor called', {
      hasToken: false,
      apiUrl: this.API_URL,
      envVars: Object.keys(import.meta.env)
    });
  }

  setToken(token) {
    console.log('🤖 AIService: Setting token', {
      hadToken: !!this.token,
      hasNewToken: !!token,
      tokenLength: token?.length
    });
    this.token = token;
  }

  setGuestMode(isGuest) {
    console.log('🤖 AIService: Setting guest mode', { isGuest });
    this.isGuestMode = isGuest;
  }

  getHeaders() {
    if (!this.token && !this.isGuestMode) {
      console.error('🤖 AIService: No token available when getting headers and not in guest mode');
      // Allow guest mode requests even without token
      // throw new Error('No authentication token available');
      return { 'Content-Type': 'application/json' }; 
    }

    const headers = { 'Content-Type': 'application/json' };
    if (!this.isGuestMode && this.token) {
        headers['Authorization'] = `Bearer ${this.token}`;
    }
    // Add guest mode header if applicable (backend might need this)
    if (this.isGuestMode) {
        headers['X-Guest-Mode'] = 'true';
    }
    console.log('🤖 AIService: Using headers', headers);
    return headers;
  }
  
  // Restore the old streamText/streamChat or adapt it to call the backend
  async streamText(input, onTokenOrOptions, optionsOrNone = {}) {
    // Handle both new and old call styles:
    // 1. New: streamText({ message, character, matchId, userId, onStreamUpdate })
    // 2. Old: streamText(messages, onToken, options)
    
    let messages;
    let onToken;
    let options;
    
    // Determine which call pattern is being used
    if (input && typeof input === 'object' && !Array.isArray(input) && input.message) {
      // NEW STYLE: Object with message property
      console.log('🤖 AIService: Using NEW call style with object params');
      const { message, character, matchId, userId, onStreamUpdate, previousMessages = [] } = input;
      
      // Get character information - either from the full object or just the name
      const characterInfo = typeof character === 'string' 
        ? { name: character } // Just the name
        : character; // Assume it's a full character object
      
      // Convert to messages array - include a system message
      messages = [
        // Add system message with character info
        { 
          role: 'system', 
          content: this.buildSystemPrompt(characterInfo)
        }
      ];

      // Add previous messages from the conversation if available
      if (previousMessages && Array.isArray(previousMessages) && previousMessages.length > 0) {
        console.log(`🤖 AIService: Including ${previousMessages.length} previous messages in conversation history`);
        
        // Map previous messages to the correct format
        const formattedPreviousMessages = previousMessages.map(msg => {
          // Skip system messages as we already added one
          if (msg.sender === 'system' || msg.role === 'system') {
            return null;
          }
          
          // Format message based on sender
          if (msg.sender === 'ai' || msg.role === 'assistant') {
            return {
              role: 'assistant',
              content: msg.text || msg.content || ''
            };
          } else {
            return {
              role: 'user',
              content: msg.text || msg.content || ''
            };
          }
        }).filter(msg => msg !== null); // Remove null entries
        
        // Add the formatted messages
        messages.push(...formattedPreviousMessages);
      }
      
      // Add the current user's message
      messages.push({ role: 'user', content: message });
      
      // Use the onStreamUpdate as token handler
      onToken = onStreamUpdate || (() => {});
      
      // Build options with full character info
      options = {
        characterInfo: characterInfo,
        matchId,
        userId
      };
      
      console.log('🤖 AIService: Converted to messages format:', {
        messageCount: messages.length,
        characterName: characterInfo.name,
        hasSystemMessage: true,
        includedPreviousMessages: previousMessages.length > 0,
        previousMessageCount: previousMessages.length,
        messagePreview: message.substring(0, 30)
      });
    } else {
      // OLD STYLE: Messages array
      console.log('🤖 AIService: Using OLD call style with array params');
      messages = input; // First parameter is messages array
      onToken = onTokenOrOptions; // Second parameter is the token callback 
      options = optionsOrNone; // Third parameter is options
    }
    
    // Prepare model and tools for backend streaming request
    console.log('🤖 AIService: Calling backend stream endpoint', {
      messages: Array.isArray(messages) ? messages.length : 'not an array',
      model: options.model,
      characterId: options.characterInfo?.name?.toLowerCase(),
      hasPhotos: !!options.characterInfo?.photos,
      hasProfileImage: !!options.characterInfo?.profileImageUrl,
      isGuest: !this.token
    });
    
    // Extract profile image URL from character info - check all possible paths
    let profileImageUrl = null;
    
    // Log full character info for debugging
    console.log('🤖 AIService: Character info debug:', {
      characterInfo: options.characterInfo,
      nameType: typeof options.characterInfo?.name,
      nameValue: options.characterInfo?.name,
      hasNameProperty: options.characterInfo ? Object.prototype.hasOwnProperty.call(options.characterInfo, 'name') : false,
      characterInfoKeys: options.characterInfo ? Object.keys(options.characterInfo) : []
    });
    
    if (options.characterInfo) {
      // Check direct profileImageUrl property first
      if (options.characterInfo.profileImageUrl) {
        profileImageUrl = options.characterInfo.profileImageUrl;
        console.log('🤖 AIService: Using profileImageUrl direct property');
      }
      // Check photos property
      else if (options.characterInfo.photos) {
        profileImageUrl = this.getProfileImageUrl(options.characterInfo.photos);
        console.log('🤖 AIService: Using extracted URL from photos property');
      }
      // Check profileData.photos as a fallback
      else if (options.characterInfo.profileData && options.characterInfo.profileData.photos) {
        profileImageUrl = this.getProfileImageUrl(options.characterInfo.profileData.photos);
        console.log('🤖 AIService: Using extracted URL from profileData.photos');
      }
      // Check images property as another fallback
      else if (options.characterInfo.images) {
        profileImageUrl = Array.isArray(options.characterInfo.images) && options.characterInfo.images.length > 0 
          ? options.characterInfo.images[0] 
          : options.characterInfo.images;
        console.log('🤖 AIService: Using images property');
      }
      
      console.log('🤖 AIService: Using profile image:', 
        profileImageUrl ? (profileImageUrl.substring(0, 40) + '...') : 'None available');
    }
    
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.token}`
    };
    console.log('🤖 AIService: Using headers', headers);
    
    try {
      // Send server-side request for streaming
      const response = await fetch(`${this.API_URL}/api/ai/chat/completions`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          model: options.model,
          messages,
          stream: true,
          characterId: typeof options.characterInfo?.name === 'string' 
            ? options.characterInfo.name.toLowerCase() 
            : (typeof options.characterInfo?.name === 'object' ? JSON.stringify(options.characterInfo.name) : String(options.characterInfo?.name || '')),
          profileImageUrl,
          matchId: options.matchId,
          userId: options.userId,
          toolChoice: 'auto' // Let AI decide when to use tools
        })
      });
      
      if (!response.ok) {
        throw new Error(`API response: ${response.status}`);
      }
      
      // Begin real-time processing of server-sent events
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let accumulatedContent = "";
      
      // Various tracking variables for the response
      let responseString = "";
      let accumulatedText = ""; // For accumulating text chunks into complete messages
      let lastMessageSentTime = Date.now();
      let toolCalls = [];
      let toolResults = [];
      let currentToolCall = null;
      
      // Constants for intelligent chunking
      const MIN_CHUNK_LENGTH = 40; // Increased minimum characters before sending a chunk
      const MAX_TIME_BETWEEN_CHUNKS = 1500; // Increased max milliseconds to wait before sending accumulated text
      const SENTENCE_ENDINGS = ['.', '!', '?', '...', '。', '！', '？']; // Sentence ending characters
      const CLAUSE_ENDINGS = [',', ';', ':', '-', '，', '；', '：', '、']; // Clause ending characters
      
      // Helper to check if text ends with any endings
      const endsWithAny = (text, endings) => {
        return endings.some(ending => {
          return ending.length === 1 
            ? text.endsWith(ending)
            : text.endsWith(ending);
        });
      };

      // Helper to determine if we should send the chunk now
      const shouldSendChunk = (text) => {
        const now = Date.now();
        const timeElapsed = now - lastMessageSentTime;
        
        // Always prioritize complete sentences for better user experience
        if (endsWithAny(text, SENTENCE_ENDINGS) && text.length >= MIN_CHUNK_LENGTH) {
          return true;
        }
        
        // Send if we have significant text with a clause ending and it's been a while
        if (endsWithAny(text, CLAUSE_ENDINGS) && timeElapsed > MAX_TIME_BETWEEN_CHUNKS/2 && text.length >= MIN_CHUNK_LENGTH * 1.5) {
          return true;
        }
        
        // Send anyway if it's been too long since last chunk and we have significant text
        if (timeElapsed > MAX_TIME_BETWEEN_CHUNKS && text.length >= MIN_CHUNK_LENGTH * 2) {
          return true;
        }
        
        return false;
      };
      
      // Process the stream chunk by chunk
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        
        // Convert binary chunk to text
        const chunk = decoder.decode(value, { stream: true });
        accumulatedContent += chunk;
        
        // Process each line that is a complete SSE data message
        const lines = accumulatedContent.split("\n\n");
        accumulatedContent = lines.pop() || ""; // Keep the last partial chunk
        
        for (const line of lines) {
          // Process each SSE data line
          if (line.startsWith("data: ")) {
            const jsonData = line.slice(6); // Remove "data: " prefix
            
            if (jsonData === "[DONE]") {
              console.log('🤖 AIService: Stream ended with [DONE] marker');
              
              // Send any remaining accumulated text
              if (accumulatedText.length > 0) {
                onToken(accumulatedText);
                accumulatedText = "";
                lastMessageSentTime = Date.now();
              }
              continue;
            }
            
            try {
              // Parse the JSON event
              const data = JSON.parse(jsonData);
              
              // Handle different event types
              if (data.type === "text") {
                // Text content - add to response
                const textContent = data.content || "";
                responseString += textContent;
                
                // Accumulate text for intelligent chunking
                accumulatedText += textContent;
                
                // Check if we should send this chunk now
                if (shouldSendChunk(accumulatedText)) {
                  onToken(accumulatedText);
                  accumulatedText = "";
                  lastMessageSentTime = Date.now();
                }
              } 
              else if (data.type === "tool_call") {
                // Tool call from backend - capture it
                console.log('🤖 AIService: Received tool call from backend:', data.toolCall);
                
                // Send any remaining accumulated text
                if (accumulatedText.length > 0) {
                  onToken(accumulatedText);
                  accumulatedText = "";
                  lastMessageSentTime = Date.now();
                }
                
                currentToolCall = data.toolCall;
                toolCalls.push(data.toolCall);
              } 
              else if (data.type === "tool_result") {
                // Tool result from backend
                console.log('🤖 AIService: Received tool result from backend:', data.toolResult);
                
                // Send any remaining accumulated text
                if (accumulatedText.length > 0) {
                  onToken(accumulatedText);
                  accumulatedText = "";
                  lastMessageSentTime = Date.now();
                }
                
                // Store the result
                toolResults.push(data.toolResult);
                
                // Check if result has imageUrl
                if (data.toolResult.result && data.toolResult.result.imageUrl) {
                  console.log('🖼️ AIService: Image URL received in tool result:', data.toolResult.result.imageUrl);
                } else {
                  console.warn('⚠️ AIService: No image URL in tool result!', data.toolResult.result);
                }
                
                // Log detailed info about result
                if (data.toolResult.result) {
                  console.log('🤖 AIService: Tool result details:', {
                    success: data.toolResult.result.success,
                    hasImageUrl: !!data.toolResult.result.imageUrl,
                    imageUrl: data.toolResult.result.imageUrl,
                    readyToView: data.toolResult.result.readyToView,
                    toolCallId: data.toolResult.toolCallId
                  });
                }
              } else if (data.type === 'credits') {
                // Handle credits update
                console.log('💰 AIService: Credits update received:', data.credits);
                if (this.onCreditsUpdate) {
                  this.onCreditsUpdate(data.credits);
                }
              } else if (data.type === 'error') {
                // Handle error in stream
                console.error('❌ AIService: Error in stream:', data.error);
                if (this.onStreamError) {
                  this.onStreamError(data.error);
                }
              }
            } catch (e) {
              console.error("Error parsing JSON from SSE:", e, jsonData);
            }
          }
        }
      }
      
      // Send any final accumulated text
      if (accumulatedText.length > 0) {
        onToken(accumulatedText);
      }
      
      console.log('🤖 AIService: Backend stream finished.');
      
      // Log detailed tool information 
      console.log('🔍 AIService TOOLS SUMMARY:', { 
        toolCalls: toolCalls.map(call => ({
          id: call.id,
          toolName: call.toolName,
          args: call.args ? JSON.stringify(call.args).substring(0, 100) : 'none'
        })),
        toolResults: toolResults.map(result => ({
          toolCallId: result.toolCallId,
          hasResult: !!result.result,
          resultKeys: result.result ? Object.keys(result.result) : [],
          success: result.result?.success,
          hasImageUrl: !!result.result?.imageUrl,
          hasReadyToView: !!result.result?.readyToView
        }))
      });
      
      // Log warning if we have toolCalls but no toolResults
      if (toolCalls.length > 0 && toolResults.length === 0) {
        console.warn('⚠️ AIService: Got tool calls but no tool results!', { toolCalls });
      }
      
      // Return the complete response along with tool information
      return {
        content: responseString,
        toolCalls,
        toolResults
      };
    } catch (error) {
      console.error('Error in streamText:', error);
      throw error;
    }
  }

  // Helper function to extract profile image URL
  getProfileImageUrl(photos) {
    if (!photos) return null;
    
    // Log the photos data structure for debugging
    console.log('🔍 AIService: Debug photos data structure:', JSON.stringify(photos));
    
    // If photos is an array, get first photo's URL
    if (Array.isArray(photos) && photos.length > 0) {
      if (photos[0].url) return photos[0].url;
      if (typeof photos[0] === 'string') return photos[0];
    }
    // If photos is a single object, get its URL
    if (photos.url) return photos.url;
    // If photos is a string, return it directly
    if (typeof photos === 'string') return photos;
    
    console.warn('AIService: Could not find valid photo URL in photos data', photos);
    return null;
  }

  // Helper function to build a comprehensive system prompt
  buildSystemPrompt(character) {
    // Ensure character object exists
    if (!character) return "You are a helpful AI assistant.";
    
    // Extract character information and ensure name is a string
    const {
      name: rawName = 'Character',
      description = '',
      bio = '',
      personality = '',
      age = '',
      interests = [],
      traits = [],
      occupation = '',
      background = ''
    } = character;
    
    // Ensure name is a string
    const name = typeof rawName === 'string' 
      ? rawName 
      : (typeof rawName === 'object' ? JSON.stringify(rawName) : String(rawName || 'Character'));
    
    // Build personality section
    let personalityText = '';
    if (personality) {
      personalityText = `\nPersonality: ${personality}`;
    } else if (traits && traits.length > 0) {
      personalityText = `\nPersonality traits: ${traits.join(', ')}`;
    }
    
    // Build interests section
    const interestsText = interests && interests.length > 0 
      ? `\nInterests: ${Array.isArray(interests) ? interests.join(', ') : interests}`
      : '';
    
    // Build background section
    const backgroundText = background 
      ? `\nBackground: ${background}`
      : '';
    
    // Build occupation section
    const occupationText = occupation
      ? `\nOccupation: ${occupation}`
      : '';
    
    // Build age section
    const ageText = age
      ? `\nAge: ${age}`
      : '';
    
    // Build description from bio or description
    const descriptionText = bio || description || '';
    
    // Compile full prompt
    return `You are ${name}. ${descriptionText}${ageText}${personalityText}${interestsText}${occupationText}${backgroundText}

You are texting with someone who is interested in getting to know you. Respond in a natural, concise texting style that reflects your personality. Keep your messages short (1-3 sentences max) like real text messages - never write long paragraphs.

Remember:
- Stay in character at all times
- Write short, casual messages like real texting
- Use sentence fragments, abbreviations, and emojis when appropriate
- Be engaging but keep responses brief and to the point
- ONLY use the generateCharacterPhoto tool when the user EXPLICITLY asks for a photo or what you look like (e.g., "send me a photo", "show me a picture", "what do you look like?")
- When using the generateCharacterPhoto tool, provide a detailed, descriptive prompt about how you look, what you're doing, your expression, and the setting
- Use descriptive language in your photo prompt like "smiling while sitting at coffee shop with laptop open" or "serious expression in office with bookshelves"
- NEVER send more than one photo in a row - if you just sent a photo, your next message must be text only
- DO NOT send a photo when the user is just complimenting a previous photo (e.g., "you look great", "beautiful photo", "nice picture")
- DO NOT send a photo when the user is just mentioning the word "photo" without actually requesting one
- Do not use "image" markdown, links, or any other method to share photos - ONLY use the generateCharacterPhoto tool.`;
  }

  // Keep other methods like setToken, setGuestMode if they are still used
  // Remove chatCompletion, healthCheck if they are not used or also need backend migration

}

export default AIService;
