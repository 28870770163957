import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const RegistrationPrompt = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [step, setStep] = useState('prompt'); // 'prompt', 'register'
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [savingMatches, setSavingMatches] = useState(false);
  
  const { upgradeGuestUser, getGuestProfileService } = useAuth();
  const navigate = useNavigate();
  
  const handleContinueAsGuest = () => {
    onClose();
  };
  
  const handleRegisterClick = () => {
    setStep('register');
  };
  
  const handleBackToPrompt = () => {
    setStep('prompt');
  };
  
  const handleRegister = async (e) => {
    e.preventDefault();
    
    // Validation
    if (!email.includes('@')) {
      setError('Please enter a valid email address');
      return;
    }
    
    if (password.length < 6) {
      setError('Password must be at least 6 characters');
      return;
    }
    
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    
    try {
      setIsLoading(true);
      setError('');
      
      // Get guest profile service to include data for transfer
      const guestProfileService = getGuestProfileService();
      let matches = [];
      
      if (guestProfileService) {
        // Get matches to include in transfer
        matches = guestProfileService.getMatches();
        setSavingMatches(true);
      }
      
      const result = await upgradeGuestUser(email, password);
      
      if (result.success) {
        // Redirect to swipe screen
        navigate('/swipe', { replace: true });
      } else {
        setError(result.error || 'Registration failed');
      }
    } catch (error) {
      console.error('Registration error:', error);
      setError(error.message || 'Registration failed');
    } finally {
      setIsLoading(false);
      setSavingMatches(false);
    }
  };
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-gradient-to-b from-indigo-600 via-purple-500 to-pink-500 rounded-lg max-w-md w-full p-6 shadow-2xl">
        {step === 'prompt' ? (
          <>
            <h2 className="text-2xl font-bold text-white mb-4 text-center">
              Upgrade Your Experience
            </h2>
            <p className="text-white/90 mb-6 text-center">
              You've used all your guest credits! Register now to continue chatting and unlock all features.
            </p>
            
            <div className="space-y-4">
              <button
                className="w-full bg-white text-indigo-600 font-semibold py-3 rounded-lg hover:bg-white/90 transition"
                onClick={handleRegisterClick}
              >
                Register Now
              </button>
              
              <button
                className="w-full bg-transparent border border-white/30 text-white font-semibold py-3 rounded-lg hover:bg-white/10 transition"
                onClick={handleContinueAsGuest}
              >
                Continue as Guest
              </button>
            </div>
          </>
        ) : (
          <form onSubmit={handleRegister}>
            <h2 className="text-2xl font-bold text-white mb-4 text-center">
              Create Account
            </h2>
            
            {error && (
              <div className="mb-4 p-3 bg-red-500/20 border border-red-500/50 rounded text-white text-sm">
                {error}
              </div>
            )}
            
            <div className="mb-4">
              <label className="block text-white mb-2" htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                className="w-full p-3 rounded-lg bg-white/20 text-white placeholder-white/70 focus:outline-none"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                disabled={isLoading}
              />
            </div>
            
            <div className="mb-4">
              <label className="block text-white mb-2" htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                className="w-full p-3 rounded-lg bg-white/20 text-white placeholder-white/70 focus:outline-none"
                placeholder="Create a password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                disabled={isLoading}
              />
            </div>
            
            <div className="mb-6">
              <label className="block text-white mb-2" htmlFor="confirmPassword">Confirm Password</label>
              <input
                type="password"
                id="confirmPassword"
                className="w-full p-3 rounded-lg bg-white/20 text-white placeholder-white/70 focus:outline-none"
                placeholder="Confirm your password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                disabled={isLoading}
              />
            </div>
            
            <p className="text-white/80 text-sm mb-6">
              Your match history and current chats will be saved to your new account.
            </p>
            
            <div className="flex space-x-4">
              <button
                type="button"
                onClick={handleBackToPrompt}
                disabled={isLoading}
                className="flex-1 bg-transparent border border-white/30 text-white font-semibold py-3 rounded-lg hover:bg-white/10 transition"
              >
                Back
              </button>
              
              <button
                type="submit"
                disabled={isLoading}
                className="flex-1 bg-white text-indigo-600 font-semibold py-3 rounded-lg hover:bg-white/90 transition"
              >
                {isLoading ? (savingMatches ? 'Saving Matches...' : 'Registering...') : 'Register'}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default RegistrationPrompt; 