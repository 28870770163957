import React from 'react';
import { motion } from 'framer-motion';
import { X, Clock } from 'lucide-react';

const PhotoGenerationConfirmation = ({ photoData, onAccept, onDecline, isOpen }) => {
  if (!isOpen) return null;
  
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-gradient-to-b from-gray-900 to-black rounded-xl shadow-xl max-w-md w-full p-5"
      >
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-white text-lg font-semibold">New Photo</h3>
          <button 
            onClick={onDecline}
            className="text-white/70 hover:text-white"
          >
            <X size={20} />
          </button>
        </div>
        
        <div className="bg-gray-800/50 rounded-lg p-4 mb-5">
          <p className="text-white">{photoData?.generationPrompt}</p>
        </div>
        
        <div className="mb-5">
          <div className="flex items-center text-white/70 mb-2">
            <Clock size={16} className="mr-2" />
            <span>Generation takes around 60 seconds</span>
          </div>
          <div className="flex items-center text-white/70">
            <span className="mr-2">•</span>
            <span>Costs {photoData?.creditCost || 1} credit</span>
          </div>
        </div>
        
        <div className="flex gap-3">
          <button
            onClick={onDecline}
            className="flex-1 py-3 rounded-lg bg-gray-800 text-white font-medium hover:bg-gray-700 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={onAccept}
            className="flex-1 py-3 rounded-lg bg-gradient-to-r from-purple-500 to-pink-500 text-white font-medium hover:opacity-90 transition-opacity"
          >
            Generate Photo
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default PhotoGenerationConfirmation; 