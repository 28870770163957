import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import { Mail, ArrowLeft, Loader, RefreshCw } from 'lucide-react';

const VerificationPending = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState(location.state?.email || '');
  const [isChecking, setIsChecking] = useState(true);
  const [checkCount, setCheckCount] = useState(0);
  const [resendStatus, setResendStatus] = useState({
    loading: false,
    success: false,
    error: null
  });
  const [successMessage, setSuccessMessage] = useState(location.state?.message || '');

  useEffect(() => {
    let intervalId;

    const checkVerificationStatus = async () => {
      try {
        const response = await fetch(
          `${import.meta.env.VITE_API_URL}/api/auth/verify-status?email=${email}`,
          {
            headers: {
              'Accept': 'application/json'
            }
          }
        );
        
        if (!response.ok) throw new Error('Failed to check status');
        
        const data = await response.json();
        console.log('Verification status:', data);
        
        if (data.isVerified) {
          clearInterval(intervalId);
          navigate('/login', { 
            state: { 
              message: 'Email verified successfully! You can now log in.',
              verifiedEmail: email 
            }
          });
        }
      } catch (error) {
        console.error('Error checking verification status:', error);
      } finally {
        setIsChecking(false);
        setCheckCount(prev => prev + 1);
      }
    };

    if (email) {
      checkVerificationStatus();
      intervalId = setInterval(checkVerificationStatus, 5000); // Check every 5 seconds
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [email, navigate]);

  const handleResendVerification = async () => {
    setResendStatus({ loading: true, success: false, error: null });
    
    try {
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/api/auth/resend-verification`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify({ email })
        }
      );

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || 'Failed to resend verification email');
      }

      setResendStatus({
        loading: false,
        success: true,
        error: null
      });

      // Reset success message after 5 seconds
      setTimeout(() => {
        setResendStatus(prev => ({ ...prev, success: false }));
      }, 5000);

    } catch (error) {
      console.error('Error resending verification:', error);
      setResendStatus({
        loading: false,
        success: false,
        error: error.message
      });

      // Clear error after 5 seconds
      setTimeout(() => {
        setResendStatus(prev => ({ ...prev, error: null }));
      }, 5000);
    }
  };

  if (!email) {
    return <Navigate to="/register" replace />;
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-600 via-purple-500 to-pink-500 flex items-center justify-center p-6">
      <div className="w-full max-w-md bg-white/10 backdrop-blur-lg rounded-xl p-6 space-y-6">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-white mb-4">Verify Your Email</h2>
          
          {successMessage && (
            <div className="mb-6 bg-green-500/20 border border-green-500/40 text-white p-4 rounded-lg text-left">
              {successMessage}
            </div>
          )}
          
          <p className="text-white opacity-90 mb-6">
            We've sent a verification link to <strong>{email}</strong>. Please check your email and click the link to activate your account.
          </p>
        </div>
        
        <div className="bg-white/10 rounded-lg p-4 mb-6 text-sm text-white/70">
          <p>Please check your email and click the verification link to complete your registration.</p>
          {isChecking && (
            <p className="mt-2 text-white/60">
              Checking verification status...
            </p>
          )}
        </div>

        <div className="space-y-4">
          <button
            onClick={handleResendVerification}
            disabled={resendStatus.loading}
            className={`w-full flex items-center justify-center gap-2 bg-white/20 hover:bg-white/30 text-white py-3 rounded-lg transition-colors ${
              resendStatus.loading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {resendStatus.loading ? (
              <Loader className="w-4 h-4 animate-spin" />
            ) : (
              <RefreshCw className="w-4 h-4" />
            )}
            Resend Verification Email
          </button>

          {resendStatus.success && (
            <p className="text-green-400 text-sm">
              ✓ Verification email resent successfully
            </p>
          )}

          {resendStatus.error && (
            <p className="text-red-400 text-sm">
              ✕ {resendStatus.error}
            </p>
          )}

          <button
            onClick={() => navigate('/login')}
            className="w-full bg-white/20 hover:bg-white/30 text-white py-3 rounded-lg transition-colors"
          >
            Return to Login
          </button>
          
          <button
            onClick={() => navigate('/register')}
            className="flex items-center justify-center w-full text-white/70 hover:text-white"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Register
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerificationPending;
