import { useRef, useCallback } from 'react';
import { TEXTING_BEHAVIORS } from '../constants';
import { getTextingBehaviorFromPersonality } from '../../../config/characters';

export function useTextingBehavior() {
  const textingTimeoutRef = useRef(null);
  const multiTextTimeoutRef = useRef(null);
  const ghostingTimeoutRef = useRef(null);
  const pendingMessagesRef = useRef([]);
  const isProcessingRef = useRef(false);

  // Clear all timeouts to prevent memory leaks
  const clearAllTimeouts = useCallback(() => {
    if (textingTimeoutRef.current) clearTimeout(textingTimeoutRef.current);
    if (multiTextTimeoutRef.current) clearTimeout(multiTextTimeoutRef.current);
    if (ghostingTimeoutRef.current) clearTimeout(ghostingTimeoutRef.current);
    textingTimeoutRef.current = null;
    multiTextTimeoutRef.current = null;
    ghostingTimeoutRef.current = null;
    pendingMessagesRef.current = [];
    isProcessingRef.current = false;
  }, []);

  // Get a random delay within the min-max range
  const getRandomDelay = useCallback((min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }, []);

  // Process a message with the appropriate behavior
  const processMessageWithBehavior = useCallback((aiResponse, personality, onMessage, onTypingChange) => {
    // Clear any existing timeouts
    clearAllTimeouts();

    // Skip processing if there's no response or it's empty
    if (!aiResponse || !aiResponse.trim()) {
      onTypingChange?.(false);
      return;
    }

    // Get texting behavior from personality
    const behaviorType = getTextingBehaviorFromPersonality(personality);
    const behavior = TEXTING_BEHAVIORS[behaviorType.toUpperCase()] || TEXTING_BEHAVIORS.NORMAL;

    console.log(`Using texting behavior: ${behavior.name}`);

    // Check if we should ghost temporarily
    const shouldGhost = behavior.ghostingProbability && Math.random() < behavior.ghostingProbability;

    // For ghosting behavior
    if (shouldGhost && behavior.ghostingDelay) {
      console.log("Character is ghosting temporarily...");
      onTypingChange?.(false); // Stop typing indicator while "ghosting"
      
      ghostingTimeoutRef.current = setTimeout(() => {
        console.log("Ghosting period over, starting to respond");
        handleMultiTextResponse(aiResponse, behavior, onMessage, onTypingChange);
      }, getRandomDelay(behavior.ghostingDelay.min, behavior.ghostingDelay.max));
      
      return;
    }

    // Normal or delayed response without ghosting
    handleMultiTextResponse(aiResponse, behavior, onMessage, onTypingChange);
  }, [clearAllTimeouts, getRandomDelay]);

  // Handle multi-text (double/triple/etc.) texting behavior
  const handleMultiTextResponse = useCallback((fullResponse, behavior, onMessage, onTypingChange) => {
    // Determine if this character will send multiple messages
    const willSendMultipleMessages = 
      behavior.maxConsecutiveMessages > 1 && 
      Math.random() < behavior.multiTextProbability;

    // If not sending multiple messages, just send the full response after a delay
    if (!willSendMultipleMessages) {
      onTypingChange?.(true); // Show typing indicator
      
      textingTimeoutRef.current = setTimeout(() => {
        onMessage?.(fullResponse);
        onTypingChange?.(false);
      }, getRandomDelay(behavior.responseDelay.min, behavior.responseDelay.max));
      
      return;
    }

    // For multi-text behavior, split the response into multiple messages
    const numMessages = Math.min(
      // Random number of messages up to max
      Math.floor(Math.random() * behavior.maxConsecutiveMessages) + 1,
      behavior.maxConsecutiveMessages
    );

    // Split the response intelligently into separate messages
    let messages = splitIntoMessages(fullResponse, numMessages);
    pendingMessagesRef.current = [...messages];
    
    // Start sending messages with delays between them
    sendNextPendingMessage(behavior, onMessage, onTypingChange);
  }, [getRandomDelay]);

  // Send the next pending message with appropriate delay
  const sendNextPendingMessage = useCallback((behavior, onMessage, onTypingChange) => {
    if (pendingMessagesRef.current.length === 0 || isProcessingRef.current) {
      if (pendingMessagesRef.current.length === 0) {
        onTypingChange?.(false); // No more messages, stop typing indicator
      }
      return;
    }

    isProcessingRef.current = true;
    onTypingChange?.(true); // Show typing indicator

    const nextMessage = pendingMessagesRef.current.shift();
    
    // Calculate typing delay based on message length and behavior
    const baseDelay = getRandomDelay(behavior.responseDelay.min, behavior.responseDelay.max);
    
    // Add typing delay based on message length (longer messages take longer to type)
    const typingDelay = Math.min(
      baseDelay,
      nextMessage.length * 10 // Roughly 10ms per character
    );

    // Schedule sending this message
    textingTimeoutRef.current = setTimeout(() => {
      onMessage?.(nextMessage);
      isProcessingRef.current = false;

      // If there are more messages, schedule the next one after a pause
      if (pendingMessagesRef.current.length > 0) {
        const pauseBetweenMessages = getRandomDelay(500, 2000); // Shorter pauses between multi-texts
        multiTextTimeoutRef.current = setTimeout(() => {
          sendNextPendingMessage(behavior, onMessage, onTypingChange);
        }, pauseBetweenMessages);
      } else {
        onTypingChange?.(false); // No more messages, stop typing indicator
      }
    }, typingDelay);
  }, [getRandomDelay]);

  // Intelligently split a response into multiple messages
  const splitIntoMessages = useCallback((text, numParts) => {
    if (numParts <= 1) return [text];
    
    // Try to split on sentence boundaries
    const sentences = text.match(/[^.!?]+[.!?]+/g) || [text];
    
    // If we have enough sentences, distribute them into messages
    if (sentences.length >= numParts) {
      const messages = [];
      const sentencesPerMessage = Math.ceil(sentences.length / numParts);
      
      for (let i = 0; i < numParts; i++) {
        const startIdx = i * sentencesPerMessage;
        const endIdx = Math.min(startIdx + sentencesPerMessage, sentences.length);
        
        if (startIdx < sentences.length) {
          messages.push(sentences.slice(startIdx, endIdx).join(' ').trim());
        }
      }
      
      return messages.filter(msg => msg.length > 0);
    }
    
    // If we don't have enough sentences, split by character count
    const avgLength = Math.ceil(text.length / numParts);
    const messages = [];
    
    let startIdx = 0;
    for (let i = 0; i < numParts - 1; i++) {
      // Find a good break point around the target length
      let endIdx = startIdx + avgLength;
      
      // Try to break at punctuation or space
      const punctuationBreak = text.substring(endIdx - 10, endIdx + 10).search(/[.!?,;]\s/);
      
      if (punctuationBreak !== -1) {
        endIdx = endIdx - 10 + punctuationBreak + 2; // +2 to include the punctuation and space
      } else {
        // Otherwise break at a space
        const spaceIdx = text.indexOf(' ', endIdx);
        if (spaceIdx !== -1 && spaceIdx < endIdx + 15) { // Don't go too far ahead
          endIdx = spaceIdx + 1;
        }
      }
      
      messages.push(text.substring(startIdx, endIdx).trim());
      startIdx = endIdx;
    }
    
    // Add the remaining text as the last message
    if (startIdx < text.length) {
      messages.push(text.substring(startIdx).trim());
    }
    
    return messages.filter(msg => msg.length > 0);
  }, []);

  return {
    processMessageWithBehavior,
    clearAllTimeouts
  };
}

export default useTextingBehavior; 