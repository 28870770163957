// useMessageHandling.js
import { useState, useRef, useCallback, useEffect } from 'react';
import { useApp } from '../../../contexts/AppContext';
import { useAuth } from '../../../contexts/AuthContext';
import { useLocation } from 'react-router-dom';
import { baseSystemPrompt } from '../../../config/characters';
import useTextingBehavior from './useTextingBehavior';
import { processMessageWithBehavior } from '../../../utils/textingBehavior';
import { debug as createDebug } from '../../../utils/debug';

const debug = createDebug('message-handling');
const debugLog = (...args) => process.env.NODE_ENV === 'development' && debug(...args);

const RAG_THRESHOLD = 5; // Threshold for when to start using RAG context

export function useMessageHandling(isGameActive, currentPersonality) {
  const location = useLocation();
  const {
    messages,
    setMessages,
    messageCount,
    setMessageCount,
    gameMode,
    setIsGameActive,
    analyzeAndUpdateLifeBar,
    aiService: appAIService,
    nsfwMode,
    selectedModel,
    chatMode,
    setMemoryUsage
  } = useApp();
  
  const { user, authenticatedFetch } = useAuth();
  const { creditsRemaining, subscriptionPlan, setCreditsRemaining, updateGameState } = useApp();

  const [inputText, setInputText] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [error, setError] = useState(null);
  const [aiTools, setAiTools] = useState([]);

  const startTime = useRef(Date.now());
  const messageIdRef = useRef(Date.now());
  const streamTimeoutRef = useRef(null);
  const currentMessagesRef = useRef(messages);

  // Initialize texting behavior hook
  const { processMessageWithBehavior, clearAllTimeouts } = useTextingBehavior();

  // Keep messages ref in sync
  useEffect(() => {
    currentMessagesRef.current = messages;
  }, [messages]);

  // Clean up timeouts on unmount
  useEffect(() => {
    return () => {
      clearAllTimeouts();
      if (streamTimeoutRef.current) {
        clearTimeout(streamTimeoutRef.current);
      }
    };
  }, [clearAllTimeouts]);

  // Memory usage is now handled entirely by AppContext

  const debugLog = (message, data = {}) => {
    console.group(`💬 useMessageHandling: ${message}`);
    console.log('State:', {
      isGameActive,
      messageCount,
      gameMode,
      isTyping,
      hasError: !!error,
      hasPersonality: !!currentPersonality,
      hasAIService: !!appAIService,
      personaName: currentPersonality?.name,
      currentMessages: messages,
      usingRAG: messageCount > RAG_THRESHOLD,
      nsfwMode // Log NSFW mode
    });
    if (Object.keys(data).length > 0) {
      console.log('Data:', data);
    }
    console.groupEnd();
  };

  const getNextMessageId = useCallback(() => {
    const id = `msg_${Date.now()}_${messageIdRef.current}`;
    messageIdRef.current += 1;
    return id;
  }, []);

  // Store message in RAG system
  const storeMessageInRAG = useCallback(async (message, isAI = false) => {
    // This functionality is not yet implemented
    console.log('RAG storage skipped for message:', message.text.substring(0, 30) + '...');
    return true;
  }, []);

  const getContextAwarePrompt = async (userMessage) => {
    try {
      const response = await authenticatedFetch('/api/chat/context', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId: user.id,
          characterId: currentPersonality.name,
          currentMessage: userMessage
        })
      });

      if (!response.ok) {
        throw new Error('Failed to get context');
      }

      const data = await response.json();
      return data.prompt;
    } catch (error) {
      console.error('Error getting context:', error);
      // Fall back to base prompt if context retrieval fails
      return baseSystemPrompt;
    }
  };

  // Function to add a new AI message
  const addAIMessage = useCallback((messageText, character, options = {}) => {
    // Ensure character is treated properly
    const characterName = typeof character === 'string' 
      ? character 
      : (character?.name || currentPersonality?.name || 'Character');
    
    // Special handling for text messages to avoid fragmenting
    if (options.type === 'text' && options.sender === 'ai') {
      // Check if the last message is from the AI and we should update it
      const lastMessage = currentMessagesRef.current.length > 0 
        ? currentMessagesRef.current[currentMessagesRef.current.length - 1] 
        : null;
      
      // If the last message is from the AI and it's a text message, update it rather than adding a new one
      if (lastMessage && lastMessage.sender === 'ai' && lastMessage.type === 'text') {
        // Create an updated message object
        const updatedMessage = {
          ...lastMessage,
          text: messageText,
          character: characterName
        };
        
        console.log('Updating existing AI message:', { 
          prevLength: lastMessage.text?.length,
          newLength: messageText?.length,
          characterName
        });
        
        // Replace the last message with the updated one
        const updatedMessages = [...currentMessagesRef.current.slice(0, -1), updatedMessage];
        setMessages(updatedMessages);
        currentMessagesRef.current = updatedMessages;
        
        return updatedMessage;
      }
    }
    
    // If we're not updating an existing message, create a new one
    const newMessage = {
      id: getNextMessageId(),
      text: messageText,
      sender: 'ai',
      character: characterName,
      // Add any additional options like type and photoUrl
      ...options
    };
    
    console.log('Adding new AI message with options:', { 
      messageText: typeof messageText === 'string' ? messageText.substring(0, 20) + '...' : 'non-string',
      characterName, 
      characterType: typeof character,
      options,
      resultMessage: newMessage
    });
    
    const newMessages = [...currentMessagesRef.current, newMessage];
    setMessages(newMessages);
    currentMessagesRef.current = newMessages;
    
    return newMessage;
  }, [getNextMessageId, setMessages, currentPersonality?.name]);

  // Function to save an AI message to the match
  const saveAIMessageToMatch = useCallback(async (messageText) => {
    if (!location.state?.matchId || !messageText.trim()) return null;
    
    try {
      // For guest users, handle locally
      if (user && user.isGuest) {
        console.log('Guest user: Saving AI message to local match');
        const characterName = location.state?.character?.name || currentPersonality?.name;
        // Create and save the AI message with character info
        const result = await authenticatedFetch(`/api/matches/${location.state.matchId}/messages`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            content: messageText,
            isAI: true,
            characterName: characterName
          })
        });
        
        if (!result.ok) {
          console.error('Failed to save AI message for guest user');
          throw new Error('Failed to save AI message');
        }
        
        const data = await result.json();
        console.log('Successfully saved AI message for guest user:', data);
        return data.message?.id || `guest_msg_${Date.now()}`;
      }
      
      const response = await authenticatedFetch(`/api/matches/${location.state.matchId}/messages`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          content: messageText,
          isAI: true
        })
      });
      
      const data = await response.json();
      return data.message?.id || `msg_${Date.now()}`;
    } catch (error) {
      console.error('Error saving AI message to match:', error);
      // Return a fallback ID so the UI doesn't break
      return `msg_${Date.now()}`;
    }
  }, [location.state?.matchId, authenticatedFetch, user, currentPersonality?.name]);

  // Function to process photo requests
  const addPhotoRequestMessage = useCallback((photoData) => {
    const newRequest = {
      id: getNextMessageId(),
      type: 'photoRequest',
      photoData,
      sender: 'ai',
      character: currentPersonality?.name,
    };
    
    const newMessages = [...currentMessagesRef.current, newRequest];
    setMessages(newMessages);
    currentMessagesRef.current = newMessages;
    
    return newRequest;
  }, [getNextMessageId, setMessages, currentPersonality?.name]);

  // Process tools and results
  const processToolResults = useCallback(async (result) => {
    console.log("🔍 processToolResults START:", {
      hasToolCalls: !!result.toolCalls,
      toolCallsLength: result.toolCalls?.length || 0,
      allToolNames: result.toolCalls?.map(call => call.toolName) || [],
      hasToolResults: !!result.toolResults,
      toolResultsLength: result.toolResults?.length || 0
    });
    
    if (!result.toolCalls || !result.toolCalls.length) {
      console.log("🔍 processToolResults: No tool calls found");
      return null;
    }
    
    const photoCall = result.toolCalls.find(call => call.toolName === 'generateCharacterPhoto');
    if (!photoCall) {
      console.log("🔍 processToolResults: No generateCharacterPhoto tool call found");
      return null;
    }
    
    console.log("🔍 processToolResults: Photo call found", photoCall);
    
    // Check if the previous message was a photo
    const recentMessages = currentMessagesRef.current.slice(-2);
    const previousMessageWasPhoto = recentMessages.length > 0 && 
      (recentMessages[0].type === 'photo' || recentMessages[0].type === 'photoRequest');
    
    if (previousMessageWasPhoto) {
      console.log("⛔ processToolResults: Previous message was a photo, blocking consecutive photo request");
      
      // Add a user-friendly message explaining why no photo was sent
      addAIMessage("I'll hold off on sending another photo right now. Let's continue our conversation!", 
        currentPersonality?.name, 
        { type: 'text', sender: 'ai' }
      );
      
      return true; // Return true to indicate we handled the request
    }
    
    const photoResult = result.toolResults?.find(result => 
      result.toolCallId === photoCall.id || result.toolCallId === photoCall.toolCallId
    )?.result;
    
    console.log("🔍 processToolResults: Photo result found:", photoResult);
    
    if (!photoResult) {
      console.log("🔍 processToolResults: No photo result found in tool results");
      return null;
    }
    
    if (photoResult.success) {
      // IMPORTANT: Check if this is a preview request (no imageUrl) or an actual photo
      if (!photoResult.imageUrl && photoResult.readyToView === false) {
        console.log("🔍 processToolResults: Adding Snapchat-style preview for photo request", photoResult);
        
        try {
          // Remove any existing photo requests first to avoid duplicates
          const updatedMessages = currentMessagesRef.current.filter(msg => 
            msg.type !== 'photoRequest'
          );
          setMessages(updatedMessages);
          currentMessagesRef.current = updatedMessages;
          
          // Add a snapchat-style preview message
          const newRequest = addPhotoRequestMessage(photoResult);
          
          console.log("🔍 processToolResults: Created photoRequest message", newRequest);
          return true;
        } catch (error) {
          console.error("🔍 processToolResults: Error adding photo request:", error);
          return null;
        }
      } else {
        // This is an already generated photo with imageUrl - show it directly
        console.log("🔍 processToolResults: Adding direct photo", photoResult.imageUrl);
        addAIMessage('', currentPersonality?.name, {
          type: 'photo',
          photoUrl: photoResult.imageUrl
        });
        return true;
      }
    } else if (photoResult.error) {
      // Add error message
      console.log("🔍 processToolResults: Adding error message for photo request", photoResult.error);
      addAIMessage(`I tried to send you a photo, but there was an error: ${photoResult.error}`, currentPersonality?.name);
      return true;
    }
    
    console.log("🔍 processToolResults: No matching condition, returning null");
    return null;
  }, [addAIMessage, currentPersonality?.name, getNextMessageId, setMessages, addPhotoRequestMessage]);

  // Check if the message is requesting a photo
  const isPhotoRequest = (text) => {
    // Extract the last two messages to check if the previous one was a photo
    const recentMessages = messages.slice(-2);
    const previousMessageWasPhoto = recentMessages.length > 0 && 
      (recentMessages[0].type === 'photo' || recentMessages[0].type === 'photoRequest');
    
    // If the previous message was a photo, don't allow another photo
    if (previousMessageWasPhoto) {
      console.log('🔍 Photo detection: Previous message was a photo, not allowing another consecutive photo');
      return false;
    }
    
    // Check if the message is explicitly requesting a photo
    const isExplicitPhotoRequest = 
      (/\b(photo|pic|picture|selfie|image|snap|shot)\b/i.test(text) && 
       (/\bsend\b/i.test(text) || /\btake\b/i.test(text) || 
        /\bcoffee\b/i.test(text) || /\bwork\b/i.test(text) ||
        /\bshow\b/i.test(text) || /\byourself\b/i.test(text)));
    
    console.log('🔍 Photo detection:', { 
      userMessage: text,
      isExplicitPhotoRequest,
      previousMessageWasPhoto,
      photoRelatedWords: /\b(photo|pic|picture|selfie|image|snap|shot)\b/i.test(text),
      actionWords: /\bsend\b/i.test(text) || /\btake\b/i.test(text) ||
                   /\bshow\b/i.test(text)
    });
    
    return isExplicitPhotoRequest;
  };

  // Function to handle sending a message
  const handleSendMessage = useCallback(async () => {
    console.log('🚨 handleSendMessage START - DETAILED DEBUG', {
      timestamp: new Date().toISOString(),
      inputText,
      isGameActive,
      currentPersonality: currentPersonality?.name,
      currentMatchId: location.state?.matchId,
      userId: user?.id,
      messageCount: messages.length
    });

    if (!isGameActive || !inputText.trim() || !currentPersonality || !appAIService) {
      console.log('🔍 handleSendMessage early return:', {
        isGameActive,
        hasInputText: !!inputText.trim(),
        hasPersonality: !!currentPersonality,
        hasAIService: !!appAIService
      });
      return;
    }
    
    const userMessageText = inputText.trim();

    // Check if the previous message was a photo - we'll use this to decide our flow
    const recentMessages = messages.slice(-2);
    const previousMessageWasPhoto = recentMessages.length > 0 && 
      (recentMessages[0].type === 'photo' || recentMessages[0].type === 'photoRequest');
    
    // Check if the message is requesting a photo
    const photoRequested = !previousMessageWasPhoto && isPhotoRequest(userMessageText);
    
    console.log('🔍 Message context:', { 
      userMessage: userMessageText,
      previousMessageWasPhoto,
      photoRequested
    });

    // Clear input and add user message
    setInputText('');
    addAIMessage(userMessageText, currentPersonality, { type: 'text', sender: 'user' });
    
    // Save user message to match if in match mode
    if (location.state?.matchId) {
      try {
        await authenticatedFetch(`/api/matches/${location.state.matchId}/messages`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            content: userMessageText,
            isAI: false
          })
        });
        console.log('User message saved to match');
      } catch (error) {
        console.error('Error saving user message to match:', error);
      }
    }
    
    setIsTyping(true);

    try {
      // If previous message was a photo, don't even call the AI - just respond with our message
      if (previousMessageWasPhoto) {
        console.log('🔍 Previous message was a photo, sending direct response without AI call');
        
        // Add a slight delay to make it feel natural
        await new Promise(resolve => setTimeout(resolve, 500 + Math.random() * 500));
        
        // Add the standard response
        const response = "Thanks for the compliment! I'm glad you like the photo. What would you like to chat about?";
        addAIMessage(response, currentPersonality.name, { type: 'text', sender: 'ai' });
        
        // Save AI message to match if in match mode
        if (location.state?.matchId) {
          try {
            await saveAIMessageToMatch(response);
            console.log('Direct response saved to match');
          } catch (error) {
            console.error('Error saving direct response to match:', error);
          }
        }
        
        return;
      }

      // If it's a photo request but tools aren't being triggered, try to help the model
      // by adding a hint to the character context
      let enhancedPersonality = currentPersonality;
      
      if (photoRequested) {
        // Create a temporary enhanced personality with a hint about photos
        enhancedPersonality = {
          ...currentPersonality,
          personality: (currentPersonality.personality || '') + 
                     " You enjoy sharing photos of yourself and use the generateCharacterPhoto tool when asked."
        };
        console.log('🔍 Enhanced personality with photo preferences');
      }

      // --- Call the AI service ---
      const result = await appAIService.streamText({
        message: userMessageText,
        character: enhancedPersonality, // Use enhanced personality for photo requests
        matchId: location.state?.matchId,
        userId: user?.id,
        previousMessages: messages,
        onStreamUpdate: (text) => {
          // Update the last AI message in real-time
          addAIMessage(text, currentPersonality.name || 'Character', { type: 'text', sender: 'ai' });
        },
      });
      // --- AI service call finished, 'result' is now defined ---

      // Log the full result for debugging
      console.log('🔍 Full AI service result:', JSON.stringify(result, null, 2));

      let aiResponse = result.content || '';
      let processedPhotoData = null;

      // Now process tool results if they exist
      if (result.toolCalls && result.toolCalls.length > 0) {
         console.log(`💬 useMessageHandling: AI response generated with tools, processing...`, {
           toolCalls: result.toolCalls,
           toolResults: result.toolResults || []
         });
         
         processedPhotoData = await processToolResults(result);
         console.log(`💬 useMessageHandling: Tool processing completed:`, { 
           processedPhotoData,
           hasToolResults: !!result.toolResults,
           toolResultsLength: result.toolResults?.length || 0
         });
         
         // If a photo was processed, the AI response might be redundant, clear it
         if (processedPhotoData) {
           console.log("ℹ️ Photo processed via tool call, clearing potential text response.");
           aiResponse = null; // Prevent adding default text if photo tool was used
         }
      }

      // Add final AI text message if one exists and wasn't handled above
      if (aiResponse) {
          console.log(`💬 useMessageHandling: Adding final AI text response.`);
           // Ensure the final text message isn't added twice if streaming already completed it
           const lastMsg = messages[messages.length - 1];
           // Check if the last message content fully contains the new aiResponse (streaming might finish early)
           const alreadyAdded = lastMsg && lastMsg.sender === 'ai' && lastMsg.type === 'text' && lastMsg.text === aiResponse;

           if (!alreadyAdded) {
                console.log("Adding/Updating final AI text message.");
                addAIMessage(aiResponse, currentPersonality.name, {
                   type: 'text',
                   sender: 'ai'
                 });
                
                // Save AI message to match if in match mode
                if (location.state?.matchId) {
                  try {
                    await saveAIMessageToMatch(aiResponse);
                    console.log('AI message saved to match');
                  } catch (error) {
                    console.error('Error saving AI message to match:', error);
                  }
                }
           } else {
             console.log("ℹ️ Final AI text response seems identical to last streamed message, skipping add.");
           }

      } else if (!processedPhotoData) {
         // Handle cases where there's no text response and no tools were processed/triggered
         console.warn("🤔 No AI text response and no photo data processed/triggered. AI might have failed silently.", {
           hasContent: !!result.content,
           contentLength: result.content?.length || 0,
           hasToolCalls: !!result.toolCalls,
           toolCallsLength: result.toolCalls?.length || 0,
           hasToolResults: !!result.toolResults,
           toolResultsLength: result.toolResults?.length || 0
         });
         
         const errorMessage = "(The AI didn't provide a text response or valid tool output)";
         addAIMessage(errorMessage, 
            currentPersonality.name, 
            { type: 'text', sender: 'ai', isError: true }
         );
         
         // Save error message to match if in match mode
         if (location.state?.matchId) {
           try {
             await saveAIMessageToMatch(errorMessage);
             console.log('Error message saved to match');
           } catch (error) {
             console.error('Error saving error message to match:', error);
           }
         }
      }

    } catch (error) {
      console.error('Error sending message:', error);
      const errorMessage = `Error: ${error.message}`;
      addAIMessage(errorMessage, currentPersonality.name, { type: 'text', sender: 'system', isError: true });
      
      // Save error message to match if in match mode
      if (location.state?.matchId) {
        try {
          await saveAIMessageToMatch(errorMessage);
          console.log('Error message saved to match');
        } catch (saveError) {
          console.error('Error saving error message to match:', saveError);
        }
      }
    } finally {
      setIsTyping(false);
      console.log('🔍 handleSendMessage END');
    }
  }, [inputText, isGameActive, currentPersonality, appAIService, addAIMessage, setInputText, setIsTyping, location.state, user?.id, processToolResults, messages, saveAIMessageToMatch, authenticatedFetch]);

  // Helper function to extract prompt (simple version)
  const extractPhotoPrompt = (text) => {
    const lowerText = text.toLowerCase();
    // Order matters: check for longer phrases first
    const keywords = [
      'send me a photo of', 'send a photo of', 'show me a photo of', 'take a photo of',
      'send pic of', 'send a pic of', 'send me a pic of', 'generate a photo of',
      'generate photo of', 'photo of', 'pic of',
      'send me a photo', 'send a photo', 'show me a photo', 'take a photo',
      'send pic', 'send a pic', 'send me a pic', 'generate a photo', 'generate photo'
    ];

    for (const keyword of keywords) {
      if (lowerText.includes(keyword)) { // Use includes for more flexibility
        // Find the start of the prompt after the keyword
        const keywordIndex = lowerText.indexOf(keyword);
        const promptStartIndex = keywordIndex + keyword.length;
        const prompt = text.substring(promptStartIndex).trim();
        // Basic filtering for potentially irrelevant trailing words if the keyword was mid-sentence
        // This is a simple heuristic and might need improvement
        const promptParts = prompt.split(' ');
        if (promptParts.length > 0 && ['please', 'now', 'thanks', 'thank you'].includes(promptParts[promptParts.length - 1].toLowerCase())) {
           promptParts.pop();
        }
        const cleanedPrompt = promptParts.join(' ').trim();

        console.log(`🔍 Extracted prompt using keyword '${keyword}': '${cleanedPrompt}'`);
        return cleanedPrompt || 'smiling, casual photo'; // Default if empty after keyword
      }
    }
    console.log(`🔍 No specific prompt keyword found in '${text}', using default.`);
    return 'smiling, casual photo'; // Default if no keyword matched
  };

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSendMessage();
      }
    },
    [handleSendMessage]
  );

  // Add function for accepting photo
  const acceptPhoto = useCallback(async (photoData) => {
    try {
      // Add the photo to messages
      const photoMessage = {
        id: getNextMessageId(),
        type: 'photo',
        photoUrl: photoData.imageUrl,
        sender: 'ai',
        character: currentPersonality?.name,
      };
      
      const newMessages = [...currentMessagesRef.current, photoMessage];
      setMessages(newMessages);
      currentMessagesRef.current = newMessages;
      
      // Save the photo message to the match if in match mode
      if (location.state?.matchId) {
        await saveAIMessageToMatch(`[PHOTO:${photoData.imageUrl}]`);
      }
      
      // Remove the photo request message
      const updatedMessages = currentMessagesRef.current.filter(msg => 
        msg.type !== 'photoRequest'
      );
      setMessages(updatedMessages);
      currentMessagesRef.current = updatedMessages;
      
      return true;
    } catch (error) {
      console.error('Error accepting photo:', error);
      return false;
    }
  }, [getNextMessageId, currentPersonality, location.state, saveAIMessageToMatch]);
  
  // Add function for declining photo
  const declinePhoto = useCallback(() => {
    // Remove the photo request message
    const updatedMessages = currentMessagesRef.current.filter(msg => 
      msg.type !== 'photoRequest'
    );
    setMessages(updatedMessages);
    currentMessagesRef.current = updatedMessages;
  }, []);

  return {
    inputText,
    setInputText,
    isTyping,
    error,
    setError,
    handleSendMessage,
    handleKeyPress,
    messageCount,
    getNextMessageId,
    storeMessageInRAG,
    addAIMessage,
    addPhotoRequestMessage,
    processToolResults,
    acceptPhoto,
    declinePhoto,
    setTools: setAiTools
  };
}

export default useMessageHandling;
