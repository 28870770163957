import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { ArrowRight, ArrowLeft } from 'lucide-react';

// Step component for each onboarding step
const OnboardingStep = ({ title, children, isActive }) => {
  if (!isActive) return null;
  
  return (
    <div className="flex flex-col items-center max-w-sm w-full">
      <h2 className="text-2xl font-bold text-white mb-6 text-center">{title}</h2>
      {children}
    </div>
  );
};

const GuestOnboarding = () => {
  const { createGuestUser } = useAuth();
  const navigate = useNavigate();
  
  const [currentStep, setCurrentStep] = useState(0);
  const [name, setName] = useState('');
  const [age, setAge] = useState('');
  const [interestedIn, setInterestedIn] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  
  // Validation functions
  const isNameValid = () => name.trim().length >= 2;
  const isAgeValid = () => {
    const ageNum = parseInt(age);
    return !isNaN(ageNum) && ageNum >= 18 && ageNum <= 100;
  };
  const isInterestedInValid = () => interestedIn === 'men' || interestedIn === 'women';
  
  const canGoNext = () => {
    if (currentStep === 0) return isNameValid();
    if (currentStep === 1) return isAgeValid();
    if (currentStep === 2) return isInterestedInValid();
    return false;
  };
  
  const handleNext = () => {
    if (canGoNext()) {
      if (currentStep === 2) {
        handleSubmit();
      } else {
        setCurrentStep(prev => prev + 1);
      }
    }
  };
  
  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(prev => prev - 1);
    }
  };
  
  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      setError('');
      
      const result = await createGuestUser(name, age, interestedIn);
      
      if (result.success) {
        // Navigate to swipe screen and start tutorial
        navigate('/swipe', { 
          state: { 
            isTutorial: true,
            tutorialStep: 'welcome'
          } 
        });
      } else {
        setError(result.error || 'Failed to create guest account');
      }
    } catch (error) {
      console.error('Error creating guest account:', error);
      setError(error.message || 'Failed to create guest account');
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <div className="h-screen bg-gradient-to-b from-indigo-600 via-purple-500 to-pink-500 flex flex-col items-center justify-center p-4">
      <div className="fixed top-4 left-4">
        <button 
          onClick={() => navigate('/login')}
          className="text-white/70 hover:text-white"
        >
          Back to login
        </button>
      </div>
      
      <div className="bg-white/10 backdrop-blur-sm p-6 rounded-xl w-full max-w-sm">
        {error && (
          <div className="mb-4 p-3 bg-red-500/20 border border-red-500/50 rounded text-white text-sm">
            {error}
          </div>
        )}
        
        <OnboardingStep 
          title="What's your name?" 
          isActive={currentStep === 0}
        >
          <input
            type="text"
            className="w-full p-3 rounded-lg bg-white/20 text-white placeholder-white/70 focus:outline-none mb-4"
            placeholder="Enter your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            disabled={isLoading}
          />
          {name && !isNameValid() && (
            <p className="text-red-300 text-sm mb-4">Name must be at least 2 characters</p>
          )}
        </OnboardingStep>
        
        <OnboardingStep 
          title="How old are you?" 
          isActive={currentStep === 1}
        >
          <input
            type="number"
            className="w-full p-3 rounded-lg bg-white/20 text-white placeholder-white/70 focus:outline-none mb-4"
            placeholder="Enter your age"
            value={age}
            onChange={(e) => setAge(e.target.value)}
            min="18"
            max="100"
            disabled={isLoading}
          />
          {age && !isAgeValid() && (
            <p className="text-red-300 text-sm mb-4">You must be at least 18 years old</p>
          )}
        </OnboardingStep>
        
        <OnboardingStep 
          title="I'm interested in..." 
          isActive={currentStep === 2}
        >
          <div className="flex w-full gap-4 mb-4">
            <button
              className={`flex-1 p-3 rounded-lg ${
                interestedIn === 'women' 
                  ? 'bg-pink-500 text-white' 
                  : 'bg-white/20 text-white/70 hover:bg-white/30 hover:text-white'
              } focus:outline-none transition-colors`}
              onClick={() => setInterestedIn('women')}
              disabled={isLoading}
            >
              Women
            </button>
            <button
              className={`flex-1 p-3 rounded-lg ${
                interestedIn === 'men' 
                  ? 'bg-blue-500 text-white' 
                  : 'bg-white/20 text-white/70 hover:bg-white/30 hover:text-white'
              } focus:outline-none transition-colors`}
              onClick={() => setInterestedIn('men')}
              disabled={isLoading}
            >
              Men
            </button>
          </div>
        </OnboardingStep>
        
        {/* Navigation buttons */}
        <div className="flex justify-between mt-6">
          <button
            onClick={handleBack}
            disabled={currentStep === 0 || isLoading}
            className={`flex items-center justify-center p-2 rounded-lg ${
              currentStep === 0 || isLoading 
                ? 'opacity-50 cursor-not-allowed text-white/50'
                : 'text-white hover:bg-white/10'
            }`}
          >
            <ArrowLeft className="w-5 h-5 mr-1" />
            Back
          </button>
          
          <button
            onClick={handleNext}
            disabled={!canGoNext() || isLoading}
            className={`flex items-center justify-center px-4 py-2 rounded-lg ${
              !canGoNext() || isLoading
                ? 'opacity-50 cursor-not-allowed bg-indigo-500/50 text-white/70'
                : 'bg-indigo-500 hover:bg-indigo-600 text-white'
            } transition-colors`}
          >
            {isLoading ? 'Processing...' : (
              currentStep === 2 ? 'Start' : (
                <>
                  Next
                  <ArrowRight className="w-5 h-5 ml-1" />
                </>
              )
            )}
          </button>
        </div>
      </div>
      
      {/* Progress dots */}
      <div className="flex mt-4 gap-2">
        {[0, 1, 2].map(step => (
          <div 
            key={step} 
            className={`rounded-full ${
              step === currentStep 
                ? 'bg-white w-3 h-3' 
                : 'bg-white/30 w-2 h-2'
            } transition-all`}
          />
        ))}
      </div>
    </div>
  );
};

export default GuestOnboarding; 