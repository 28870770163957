import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

import { API_URL } from '../config/api.js';


const PaymentPlans = () => {
  const navigate = useNavigate();
  const { user, authenticatedFetch } = useAuth();
  const [plans, setPlans] = React.useState([]);
  const [currentPlan, setCurrentPlan] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    fetchPlans();
    if (user) {
      fetchCurrentPlan();
    }
  }, [user]);

  const fetchPlans = async () => {
    try {
      const response = await fetch(`${API_URL}/api/subscription/plans`);
      if (!response.ok) {
        const text = await response.text();
        console.error('Error response:', {
          status: response.status,
          statusText: response.statusText,
          body: text,
          url: response.url
        });
        throw new Error(`Failed to fetch plans: ${response.status}`);
      }
      const data = await response.json();
      setPlans(data);
    } catch (err) {
      setError('Failed to load subscription plans');
      console.error('Error fetching plans:', err);
    } finally {
      setLoading(false);
    }
  };

  const fetchCurrentPlan = async () => {
    try {
      const response = await authenticatedFetch(`${API_URL}/api/subscription/my-subscription`);
      if (!response.ok) {
        const text = await response.text();
        console.error('Error response:', {
          status: response.status,
          statusText: response.statusText,
          body: text,
          url: response.url
        });
        throw new Error(`Failed to fetch current plan: ${response.status}`);
      }
      const data = await response.json();
      setCurrentPlan(data);
    } catch (err) {
      console.error('Error fetching current plan:', err);
    }
  };

  const handleSelectPlan = async (planId) => {
    if (!user) {
      navigate('/login', { state: { from: '/payment-plans' } });
      return;
    }

    // Don't allow selecting the current plan
    if (currentPlan?.plan?.id === planId) {
      return;
    }

    try {
      const response = await authenticatedFetch(`${API_URL}/api/subscription/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ planId })
      });

      if (!response.ok) {
        const text = await response.text();
        console.error('Error response:', {
          status: response.status,
          statusText: response.statusText,
          body: text,
          url: response.url
        });
        throw new Error(`Failed to create checkout session: ${response.status}`);
      }
      
      const { url } = await response.json();
      window.location.href = url;
    } catch (err) {
      setError('Failed to start checkout process');
      console.error('Error starting checkout:', err);
    }
  };

  if (loading) return <div className="flex justify-center items-center h-96">Loading plans...</div>;
  if (error) return <div className="text-red-500 text-center p-4">{error}</div>;

  return (
    <div className="bg-gray-900 h-screen overflow-y-auto">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 pb-24 relative">
        <button
          onClick={() => navigate('/swipe')}
          className="absolute left-4 top-4 text-white hover:text-gray-300 flex items-center"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
          <span className="ml-2">Back</span>
        </button>
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
            Choose Your Plan
          </h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-300 sm:mt-4">
            Get access to advanced features and more credits
          </p>
        </div>

        <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:gap-6">
          {plans.filter(plan => plan.name !== 'free').map((plan) => (
            <div key={plan.id} className={`rounded-lg shadow-lg divide-y divide-gray-700 bg-gray-800 ${currentPlan?.plan?.id === plan.id ? 'ring-2 ring-blue-500' : ''}`}>
              <div className="p-6">
                <h3 className="text-2xl font-semibold text-white">{plan.name.charAt(0).toUpperCase() + plan.name.slice(1)}</h3>
                <p className="mt-4">
                  <span className="text-4xl font-extrabold text-white">${plan.price}</span>
                  <span className="text-base font-medium text-gray-300">/mo</span>
                </p>
                <p className="mt-2 text-sm text-gray-300">
                  {plan.name === 'free' 
                    ? `${plan.monthlyCredits.toLocaleString()} credits to try`
                    : `${plan.monthlyCredits.toLocaleString()} credits/month`
                  }
                </p>
                <p className="mt-1 text-sm text-gray-300">
                  {plan.contextWindow.toLocaleString()} token context window
                </p>
                <p className="mt-1 text-sm text-gray-300">
                  {plan.dailySwipeLimit === -1 ? 'Unlimited' : plan.dailySwipeLimit} swipes/day
                </p>
                <p className="mt-1 text-sm text-gray-300">
                  {plan.dailySuperLikeLimit} instant likes/day
                </p>
              </div>
              <div className="px-6 pt-6 pb-8">
                <button
                  onClick={() => handleSelectPlan(plan.id)}
                  disabled={currentPlan?.plan?.id === plan.id}
                  className={`w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white 
                    ${currentPlan?.plan?.id === plan.id 
                      ? 'bg-blue-600 opacity-75 cursor-not-allowed' 
                      : 'bg-purple-600 hover:bg-purple-700'}`}
                >
                  {currentPlan?.plan?.id === plan.id ? 'Current Plan' : 'Select Plan'}
                </button>
              </div>
            </div>
          ))}
        </div>

        {currentPlan && (
          <div className="mt-8 text-center">
            <div className="text-gray-300 mb-4">
              <p>Credits remaining: {currentPlan.creditsRemaining.toLocaleString()}</p>
              <p className="text-sm">Next refresh: {new Date(currentPlan.lastCreditRefresh).toLocaleDateString()}</p>
            </div>
            {currentPlan.plan?.name !== 'free' && (
              <button
                onClick={async () => {
                  try {
                    const response = await authenticatedFetch(`${API_URL}/api/subscription/create-portal-session`, {
                      method: 'POST'
                    });
                    if (!response.ok) throw new Error('Failed to create portal session');
                    const { url } = await response.json();
                    window.location.href = url;
                  } catch (err) {
                    console.error('Error opening portal:', err);
                    setError('Failed to open subscription portal');
                  }
                }}
                className="text-gray-400 hover:text-gray-300 text-sm underline"
              >
                Manage Subscription
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentPlans;
