import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import GuestProfileService from '../services/GuestProfileService';

const AuthContext = createContext(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(localStorage.getItem('token'));
  const services = useRef(null);

  // Get the API URL from environment variable
  const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:5023';

  const guestProfileService = new GuestProfileService();

  const register = async (email, password, name, interested_in, birthdate) => {
    console.log('Starting registration process for:', email);
    try {
      const response = await fetch(`${API_URL}/api/auth/register`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({ email, password, name, interested_in, birthdate }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Registration failed');
      }
      
      const data = await response.json();
      console.log('Registration successful:', { email, name, interested_in });
      return data;
    } catch (error) {
      console.error('Registration error:', error);
      throw error;
    }
  };

  const createGuestUser = async (name, age, interestedIn) => {
    try {
      console.log('Creating guest user:', { name, age, interestedIn });
      
      // Normalize gender preference
      let normalizedInterest = interestedIn;
      if (normalizedInterest) {
        // Convert to lowercase
        normalizedInterest = normalizedInterest.toLowerCase();
        
        // For guest profiles, we use men/women, but the backend wants male/female
        // So we need to map consistently for both guest UI and backend
        // This is why we'll keep "men/women" format for guest data, and convert when needed
      }
      
      // Generate a temporary guest ID
      const guestId = `guest_${Date.now()}`;
      
      // Calculate age preferences (min age 18, max age +3 years)
      const minAgePreference = Math.max(18, age - 3);
      const maxAgePreference = age + 3;
      
      // Create guest user object with minimum required data
      const guestUser = {
        id: guestId,
        name: name,
        age: parseInt(age),
        interested_in: normalizedInterest, // Use normalized version
        min_age_preference: minAgePreference,
        max_age_preference: maxAgePreference,
        isGuest: true,
        credits: 25, // Give guests 25 credits to use real AI
        swipes: 5, // Give guests 5 swipes
        superLikes: 1, // Give guests 1 super like
        guestCreatedAt: new Date().toISOString()
      };
      
      // Save guest user to local storage
      localStorage.setItem('guestUser', JSON.stringify(guestUser));
      setUser(guestUser);
      
      // Initialize profiles for this user
      console.log('Initializing profiles for guest user with preferences:', { 
        interestedIn: interestedIn, 
        age: parseInt(age) 
      });
      
      const initializedProfiles = guestProfileService.initializeProfiles(interestedIn, parseInt(age));
      
      if (!initializedProfiles) {
        console.error('Failed to initialize profiles for guest user');
        // Try again with different parameters
        guestProfileService.initializeProfiles(interestedIn === 'men' ? 'women' : 'men', 25);
      }
      
      // Set guest mode in AI service if available
      if (services.current?.aiService) {
        console.log('Setting guest mode in AI service during user creation');
        services.current.aiService.setGuestMode(true);
      }
      
      // Also try to set it on the global instance if available
      if (window.appServices?.aiService) {
        console.log('Setting guest mode in global AI service during user creation');
        window.appServices.aiService.setGuestMode(true);
      }
      
      console.log('Guest user created:', guestUser);
      return { success: true, user: guestUser };
    } catch (error) {
      console.error('Guest user creation error:', error);
      return { success: false, error: 'Failed to create guest account' };
    }
  };

  const upgradeGuestUser = async (email, password) => {
    try {
      const guestData = JSON.parse(localStorage.getItem('guestUser'));
      if (!guestData) {
        throw new Error('No guest data found');
      }
      
      console.log('Upgrading guest user to registered account');
      
      // Normalize the gender preference value to match backend expectations
      let interestedIn = guestData.interested_in;
      if (interestedIn) {
        // Convert to lowercase
        interestedIn = interestedIn.toLowerCase();
        
        // Convert from "men/women" format to "male/female" if needed
        if (interestedIn === 'men') interestedIn = 'male';
        if (interestedIn === 'women') interestedIn = 'female';
        
        // Validate that it's now a valid value
        if (!['male', 'female'].includes(interestedIn)) {
          console.error('Invalid gender preference:', interestedIn);
          throw new Error('Please select a valid gender preference (male/female)');
        }
      } else {
        throw new Error('Missing gender preference in guest data');
      }
      
      // Validate and normalize age preferences to prevent validation errors
      let minAgePreference = guestData.min_age_preference || 18;
      let maxAgePreference = guestData.max_age_preference || 35;
      
      // Ensure min age is at least 18
      minAgePreference = Math.max(18, minAgePreference);
      
      // Ensure max age is capped at a reasonable value and greater than min
      maxAgePreference = Math.min(100, Math.max(minAgePreference + 1, maxAgePreference));
      
      console.log('Normalized age preferences:', { minAgePreference, maxAgePreference });
      
      // Collect all matches and conversations for transfer
      console.log('Collecting guest matches and messages for transfer');
      const matches = guestProfileService.getMatches() || [];
      
      // Get all message history from localStorage
      const conversations = {};
      
      // If we have matches, get their messages
      if (matches && matches.length > 0) {
        for (const match of matches) {
          if (match && match.id) {
            // Get messages for this match
            const messages = guestProfileService.getMessages(match.id);
            if (messages && messages.length > 0) {
              conversations[match.id] = messages;
            }
          }
        }
      }
      
      console.log(`Prepared ${matches.length} matches and ${Object.keys(conversations).length} conversations for transfer`);
      
      // Register with guest data included
      const response = await fetch(`${API_URL}/api/auth/register-from-guest`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          email,
          password,
          name: guestData.name,
          age: guestData.age,
          interested_in: interestedIn, // Use normalized version
          min_age_preference: minAgePreference,
          max_age_preference: maxAgePreference,
          guestId: guestData.id,
          matches,
          conversations
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Registration failed');
      }
      
      const data = await response.json();
      console.log('Guest upgrade successful:', { 
        email, 
        matchesImported: data.matchesImported || 0
      });
      
      // Clear guest data
      localStorage.removeItem('guestUser');
      
      // Clear all guest localStorage items
      for (const key of Object.keys(localStorage)) {
        if (key.startsWith('gotgame_guest_')) {
          localStorage.removeItem(key);
        }
      }
      
      // Clear guest profile service data
      guestProfileService.clear();
      
      return { 
        success: true, 
        message: `Account created successfully! ${data.matchesImported || 0} matches were transferred.` 
      };
    } catch (error) {
      console.error('Guest upgrade error:', error);
      return { success: false, error: error.message || 'Registration failed' };
    }
  };

  const login = async (email, password) => {
    try {
      const response = await fetch(`${API_URL}/api/auth/login`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      
      if (response.ok) {
        localStorage.setItem('token', data.token);
        setToken(data.token);
        setUser(data.user);
        return { success: true };
      } else {
        console.error('Login failed:', data.message);
        return { success: false, error: data.message };
      }
    } catch (error) {
      console.error('Login error:', error);
      return { success: false, error: 'Login failed' };
    }
  };

  const refreshToken = async () => {
    try {
      const response = await fetch(`${API_URL}/api/auth/refresh-token`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.token);
        setToken(data.token);
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  const authenticatedFetch = async (url, options = {}) => {
    // Check if user is guest
    const guestUser = user?.isGuest ? user : null;
    
    try {
      // For guest users, we need different handling
      if (guestUser) {
        console.log('Guest user fetch:', url, options?.method || 'GET');
        
        // Regex for match messages API
        const matchMessagesRegex = /\/api\/matches\/([^\/]+)\/messages/;
        
        // For certain endpoints that would need authentication, simulate behavior
        if (url.includes('/api/subscription/check-credits')) {
          // Simulate credits check for guest
          const { model, tokens } = JSON.parse(options.body);
          // For guest users, simply charge 1 credit per message regardless of model or tokens
          const creditsNeeded = 1; // Fixed cost for guest users
          
          // Read the latest credit value from localStorage
          try {
            const freshGuestData = JSON.parse(localStorage.getItem('guestUser'));
            if (freshGuestData && freshGuestData.credits !== undefined) {
              guestUser.credits = freshGuestData.credits;
              console.log('Using fresh guest credits from localStorage:', guestUser.credits);
            }
          } catch (e) {
            console.error('Error reading fresh credits from localStorage:', e);
          }
          
          console.log('Guest user credit check:', { 
            available: guestUser.credits, 
            needed: creditsNeeded,
            sufficient: guestUser.credits >= creditsNeeded
          });
          
          if (guestUser.credits < creditsNeeded) {
            // Throw error to simulate 402 response
            const error = new Error('Guest credits depleted');
            error.status = 402;
            error.json = () => ({ 
              error: 'Guest credits depleted', 
              hasEnough: false,
              creditsNeeded 
            });
            throw error;
          }
          
          // Update guest credits (directly modify the user object and save to localStorage)
          guestUser.credits -= creditsNeeded;
          localStorage.setItem('guestUser', JSON.stringify(guestUser));
          setUser({...guestUser}); // Update state to trigger re-render
          
          // Also dispatch a credit update event to ensure AppContext is updated
          const creditEvent = new CustomEvent('creditsUpdate', {
            detail: { remaining: guestUser.credits }
          });
          window.dispatchEvent(creditEvent);
          
          // Return mock response
          return {
            ok: true,
            json: () => ({ hasEnough: true, creditsNeeded, creditsRemaining: guestUser.credits })
          };
        }
        
        // Handle match messages fetching for guest users - more flexible matching
        const matchMessageMatch = url.match(matchMessagesRegex);
        if (matchMessageMatch && (options?.method === 'GET' || !options?.method)) {
          const matchId = matchMessageMatch[1]; // Extract matchId from regex match
          
          // Force refresh from localStorage before getting messages
          guestProfileService.refreshMessagesFromStorage();
          
          // Get stored messages for this match
          const messages = guestProfileService.getMessages(matchId);
          console.log(`Guest user: Fetching messages for match ${matchId}`, { 
            messageCount: messages.length 
          });
          
          return {
            ok: true,
            json: () => ({ messages })
          };
        }
        
        // Handle message creation for guest users - more flexible matching
        if (matchMessageMatch && options?.method === 'POST') {
          const matchId = matchMessageMatch[1]; // Extract matchId from regex match
          const { content, isAI, characterName } = JSON.parse(options.body);
          
          console.log(`Guest user: Saving ${isAI ? 'AI' : 'user'} message to match ${matchId}`, {
            contentLength: content.length,
            characterName
          });
          
          try {
            const message = guestProfileService.saveMessage(matchId, content, isAI);
            console.log(`Successfully saved ${isAI ? 'AI' : 'user'} message to match`, {
              matchId,
              messageId: message.id
            });
            
            return {
              ok: true,
              json: () => ({ message })
            };
          } catch (error) {
            console.error(`Failed to save ${isAI ? 'AI' : 'user'} message for guest user:`, error);
            return {
              ok: false,
              status: 500,
              json: () => ({ 
                error: `Failed to save message: ${error.message}` 
              })
            };
          }
        }
        
        // PROFILE ENDPOINTS FOR GUEST MODE
        // Handle profile fetching for guest users
        if (url.includes('/api/profiles/next') || url.endsWith('/next')) {
          const urlParams = new URLSearchParams(url.split('?')[1]);
          const gender = urlParams.get('gender') || guestUser.interested_in;
          
          // Make sure guest profile service is initialized with the correct gender parameter
          if (!guestProfileService.profiles || guestProfileService.profiles.length === 0) {
            console.log('Reinitializing guest profiles with:', { interestedIn: gender, age: guestUser.age });
            guestProfileService.initializeProfiles(gender, guestUser.age);
          }
          
          const nextProfile = guestProfileService.getNextProfile();
          
          // If nextProfile is null, return an empty success response
          if (!nextProfile) {
            console.warn('No guest profiles available');
            return {
              ok: true,
              json: () => ({ success: false, error: 'No profiles available' })
            };
          }
          
          return {
            ok: true,
            json: () => ({ success: true, profile: nextProfile })
          };
        }
        
        // Handle profile liking for guest users
        if (url.includes('/api/profiles/like') && options.method === 'POST') {
          const { profileId } = JSON.parse(options.body);
          const result = guestProfileService.likeProfile(profileId);
          
          return {
            ok: true,
            json: () => result
          };
        }
        
        // Handle profile disliking for guest users
        if (url.includes('/api/profiles/dislike') && options.method === 'POST') {
          const { profileId } = JSON.parse(options.body);
          const result = guestProfileService.dislikeProfile(profileId);
          
          return {
            ok: true,
            json: () => result
          };
        }
        
        // Handle profile interaction analytics for guest users
        if (url.includes('/api/profiles/interaction') && options.method === 'POST') {
          // For guest users, just pretend it succeeded since we don't need to store analytics
          console.log('Guest mode: Interaction analytics recording simulated');
          return {
            ok: true,
            json: () => ({ success: true, message: 'Interaction recorded (guest mode)' })
          };
        }
        
        // Handle matches creation for guest users
        if (url.includes('/api/matches') && options.method === 'POST' && !url.includes('/messages')) {
          const body = JSON.parse(options.body);
          const profile = body.profile;
          
          // Create a match using the profile
          const result = guestProfileService.likeProfile(profile.id);
          
          if (result.match) {
            return {
              ok: true,
              json: () => ({ 
                matched: true, 
                match: {
                  id: `match_${Date.now()}`,
                  profile_id: profile.id,
                  created_at: new Date().toISOString()
                } 
              })
            };
          } else {
            return {
              ok: true,
              json: () => ({ matched: false })
            };
          }
        }
        
        // Handle matches fetching for guest users
        if (url.includes('/api/matches') && !url.includes('/messages') && options?.method !== 'POST') {
          console.log('Guest user: Fetching matches from guest service');
          const matches = guestProfileService.getMatches();
          
          return {
            ok: true,
            json: () => ({ matches: matches || [] })
          };
        }
        
        // Handle profile interactions for guest users
        if (url.includes('/api/profiles/interaction') && options.method === 'POST') {
          const { profileId, status } = JSON.parse(options.body);
          
          // No real need to do anything here for guest mode, just return success
          return {
            ok: true,
            json: () => ({ success: true })
          };
        }
        
        // For guest users, return mock data for certain endpoints
        if (url.includes('/api/chat/context')) {
          return {
            ok: true,
            json: () => ({ 
              prompt: 'Guest user prompt', 
              totalTokens: 0, 
              maxTokens: 5000 
            })
          };
        }
        
        // Add regex for unmatch API
        const matchUnmatchRegex = /\/api\/matches\/([^\/]+)\/unmatch/;

        // Handle match unmatching API for guest users
        const matchUnmatchMatch = url.match(matchUnmatchRegex);
        if (matchUnmatchMatch && options?.method === 'POST') {
          const matchId = matchUnmatchMatch[1]; // Extract matchId from regex match
          
          // Check if there's a body to parse, but don't require it
          let bodyData = {};
          try {
            if (options && options.body) {
              bodyData = JSON.parse(options.body);
            }
          } catch (e) {
            console.log('No valid JSON body for unmatch request, continuing anyway');
          }
          
          // Ensure matchId exists
          if (!matchId) {
            console.error('No valid match ID found in URL');
            return {
              ok: false,
              status: 400,
              json: () => ({ success: false, error: 'No valid match ID provided' })
            };
          }
          
          console.log(`Guest user: Unmatching match ${matchId}`, bodyData);
          
          // Use the safer method first as a backup
          const safeResult = guestProfileService.safeUnmatchProfile(matchId);
          console.log('Safe unmatch completed with result:', safeResult);

          try {
            // Safely call full unmatchProfile with error handling
            console.log('About to call unmatchProfile with ID:', matchId);
            
            // Call unmatchProfile directly with null checks
            let result;
            try {
              result = guestProfileService.unmatchProfile(matchId);
            } catch (e) {
              console.error('Error calling unmatchProfile:', e);
              result = { success: false, error: e.message };
            }
            
            console.log('Result from unmatchProfile:', result);
            
            // Create a completely safe result object without accessing any properties that might be undefined
            const safeResult = {
              success: result && result.success === true,
              message: result && result.message ? result.message : 'Operation completed',
              profile: result && result.profile ? result.profile : { id: matchId },
              error: result && result.error ? result.error : undefined
            };
            
            console.log('Safe result object:', safeResult);
            
            return {
              ok: safeResult.success,
              status: safeResult.success ? 200 : 400,
              json: () => safeResult
            };
          } catch (error) {
            console.error(`Error in unmatch handler for match ${matchId}:`, error);
            return {
              ok: false,
              status: 500,
              json: () => ({ 
                success: false, 
                error: `Internal error: ${error.message}` 
              })
            };
          }
        }
        
        // For most other endpoints, proceed normally for backend that supports guest mode
        console.log('Guest user fetch:', url);
      }
      
      const headers = {
        ...options.headers
      };
      
      // Add auth token for normal users
      if (!guestUser && localStorage.getItem('token')) {
        headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      }

      let response = await fetch(url.startsWith('/') ? `${API_URL}${url}` : url, { 
        ...options, 
        headers 
      });

      if (!guestUser && response.status === 401) {
        const refreshed = await refreshToken();
        if (refreshed) {
          headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
          response = await fetch(url.startsWith('/') ? `${API_URL}${url}` : url, { 
            ...options, 
            headers 
          });
        } else {
          logout();
          throw new Error('Session expired');
        }
      }

      return response;
    } catch (error) {
      throw error;
    }
  };

  const updateUser = (userData) => {
    // If user is guest, update local storage
    if (user?.isGuest) {
      const updatedUser = {...user, ...userData};
      localStorage.setItem('guestUser', JSON.stringify(updatedUser));
      setUser(updatedUser);
      return;
    }
    
    // Otherwise update user state normally
    setUser(prevUser => ({
      ...prevUser,
      ...userData
    }));
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('guestUser');
    setToken(null);
    setUser(null);
    
    // Clear guest profile service data
    guestProfileService.clear();
  };

  useEffect(() => {
    const verifyToken = async () => {
      setLoading(true); // Start loading
      try {
        const currentToken = localStorage.getItem('token');

        if (currentToken) {
          // If a token exists, ALWAYS try to verify it first
          console.log('Found token, attempting verification...');
          const response = await fetch(`${API_URL}/api/auth/verify-token`, {
            headers: { 'Authorization': `Bearer ${currentToken}` }
          });
          
          if (response.ok) {
            const data = await response.json();
            console.log('Token verified successfully, setting user:', data.user);
            setUser(data.user);
            setToken(currentToken);
            // If token is verified, clear any potential stale guest data
            localStorage.removeItem('guestUser');
            setLoading(false);
            return; // Successfully loaded user, exit
          } else {
            // Token verification failed (expired, invalid)
            console.log('Token verification failed, removing token.');
            localStorage.removeItem('token');
            setToken(null);
            // Continue to check for guest data ONLY if token fails
          }
        }

        // --- Only check for guest data if NO token was found or verification failed ---
        const guestData = localStorage.getItem('guestUser');
        if (guestData) {
          console.log('No valid token, found guest user data.');
          try {
             const parsedGuestData = JSON.parse(guestData);
             // Basic validation to prevent bad data issues
             if (parsedGuestData && parsedGuestData.id && parsedGuestData.isGuest) {
                setUser(parsedGuestData);
             } else {
                console.warn('Invalid guest data found, removing.');
                localStorage.removeItem('guestUser');
             }
          } catch (e) {
             console.error('Error parsing guest data, removing:', e);
             localStorage.removeItem('guestUser');
          }
        } else {
           // No token and no guest data
           setUser(null);
        }

      } catch (error) {
        console.error('Error during initial auth check:', error);
        // Clear potentially problematic state on error
        localStorage.removeItem('token');
        localStorage.removeItem('guestUser');
        setToken(null);
        setUser(null);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    verifyToken();
  }, []); // Run only on initial mount

  useEffect(() => {
    const currentToken = localStorage.getItem('token');
    if (currentToken !== token) {
      setToken(currentToken);
    }
  }, [token]);

  const getGuestProfileService = () => {
    if (!user?.isGuest) {
      return null;
    }
    return guestProfileService;
  };

  // Initialize app services with appropriate token
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // Access services from the global window object that AppContext sets up
      services.current = window.appServices || services.current;
      
      console.log('🔒 AuthContext: Checking services for guest mode update', {
        hasServices: !!services.current,
        isGuest: !!user?.isGuest
      });
      
      if (services.current?.aiService) {
        if (token) {
          console.log('🔒 AuthContext: Setting token in AI service');
          services.current.aiService.setToken(token);
        }

        // Set guest mode if user is a guest
        if (user?.isGuest) {
          console.log('🔒 AuthContext: Setting guest mode in AI service to TRUE');
          services.current.aiService.setGuestMode(true);
        } else if (services.current.aiService.isGuestMode) {
          console.log('🔒 AuthContext: Setting guest mode in AI service to FALSE');
          services.current.aiService.setGuestMode(false);
        }
      }
    }, 100);
    return () => clearTimeout(timeoutId);
  }, [token, user?.isGuest]);

  // Initialize profile service when guest user is loaded
  useEffect(() => {
    if (user?.isGuest) {
      // Make sure guest profile service is initialized with the correct preferences
      if (!guestProfileService.userGender || !guestProfileService.userAge) {
        console.log('Initializing guest profile service from loaded user:', {
          interestedIn: user.interested_in,
          age: user.age
        });
        guestProfileService.initializeProfiles(user.interested_in, user.age);
      }
    }
  }, [user?.isGuest, user?.interested_in, user?.age]);

  // Listen for credits and memory updates
  useEffect(() => {
    // For guest users, we'll intercept all credit update events from streaming
    // to ensure we only deduct 1 credit per message
    const handleGuestCreditUpdate = (event) => {
      event.stopPropagation();

      // If this is a real guest user, ensure proper credit handling
      if (user?.isGuest) {
        // Get the latest guest user state
        const freshGuestData = JSON.parse(localStorage.getItem('guestUser'));
        if (freshGuestData) {
          // If we're receiving the same value, don't update to avoid cycles
          if (event.detail && typeof event.detail.remaining === 'number' && 
              freshGuestData.credits === event.detail.remaining) {
            console.log('🔒 AuthContext: Skipping duplicate credit update:', event.detail.remaining);
            return;
          }
          
          // Manually deduct only 1 credit
          let newCredits;
          
          // If detail.isInitial is true, we're just initializing, don't deduct
          if (event.detail && event.detail.isInitial) {
            newCredits = freshGuestData.credits;
            console.log('🔒 AuthContext: Initial credit check, not deducting:', newCredits);
          } else {
            // Regular update - deduct 1 credit
            newCredits = Math.max(0, freshGuestData.credits - 1);
            console.log('🔒 AuthContext: Deducting 1 credit, new balance:', newCredits);
          }
          
          // Save back to localStorage
          freshGuestData.credits = newCredits;
          localStorage.setItem('guestUser', JSON.stringify(freshGuestData));
          
          // Update the user state directly to keep it in sync
          setUser({
            ...user,
            credits: newCredits
          });
          
          // Create new event with our controlled deduction
          const controlledEvent = new CustomEvent('creditsUpdate', {
            detail: { remaining: newCredits }
          });
          
          // Dispatch the controlled event
          window.dispatchEvent(controlledEvent);
        }
      }
    };
    
    // Only set up the intercept for guest users
    if (user?.isGuest) {
      // This is added with capture: true to intercept before normal handlers
      window.addEventListener('creditsUpdate', handleGuestCreditUpdate, { capture: true });
      
      // Broadcast initial credits on mount to ensure sync across components
      try {
        const guestData = JSON.parse(localStorage.getItem('guestUser'));
        if (guestData && typeof guestData.credits === 'number') {
          console.log('🔒 AuthContext: Broadcasting initial guest credits:', guestData.credits);
          const initialEvent = new CustomEvent('creditsUpdate', {
            detail: { remaining: guestData.credits, isInitial: true }
          });
          window.dispatchEvent(initialEvent);
        }
      } catch (e) {
        console.error('Error broadcasting initial guest credits:', e);
      }
      
      return () => {
        window.removeEventListener('creditsUpdate', handleGuestCreditUpdate, { capture: true });
      };
    }
  }, [user?.isGuest, user]);

  const value = {
    user,
    loading,
    login,
    register,
    logout,
    token,
    authenticatedFetch,
    updateUser,
    createGuestUser,
    upgradeGuestUser,
    getGuestProfileService
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext };
export default AuthProvider;
