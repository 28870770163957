import { getGuestProfiles, getRandomMatchProfile } from '../data/guestProfiles';

// Storage keys
const GUEST_STORAGE_PREFIX = 'gotgame_guest_';
const GUEST_PREFS_KEY = `${GUEST_STORAGE_PREFIX}preferences`;
const GUEST_MATCHES_KEY = `${GUEST_STORAGE_PREFIX}matches`;
const GUEST_PROFILES_KEY = `${GUEST_STORAGE_PREFIX}profiles`;
const GUEST_MESSAGES_KEY = `${GUEST_STORAGE_PREFIX}messages`;

// Simulates a backend profile service for guest users
class GuestProfileService {
  constructor() {
    this.profiles = {};
    this.currentProfileIndex = 0;
    this.likedProfiles = [];
    this.dislikedProfiles = [];
    this.matches = [];
    this.userGender = null;
    this.userAge = null;
    this.messages = {}; // Message history by matchId
    
    // Try to restore state from localStorage
    this.restoreState();
  }
  
  // Save current state to localStorage
  saveState() {
    try {
      // Save user preferences
      localStorage.setItem(GUEST_PREFS_KEY, JSON.stringify({
        gender: this.userGender,
        age: this.userAge
      }));
      
      // Save matches
      localStorage.setItem(GUEST_MATCHES_KEY, JSON.stringify(this.matches));
      
      // Save messages history
      localStorage.setItem(GUEST_MESSAGES_KEY, JSON.stringify(this.messages));
      
      // Save profiles (optional, but good for maintaining state)
      localStorage.setItem(GUEST_PROFILES_KEY, JSON.stringify({
        profiles: this.profiles,
        currentIndex: this.currentProfileIndex,
        liked: this.likedProfiles,
        disliked: this.dislikedProfiles
      }));
      
      console.log('Guest state saved to localStorage', {
        userGender: this.userGender,
        userAge: this.userAge,
        matchesCount: this.matches.length,
        messagesCount: Object.keys(this.messages).length
      });
    } catch (error) {
      console.error('Error saving guest state to localStorage:', error);
    }
  }
  
  // Restore state from localStorage
  restoreState() {
    try {
      // Restore preferences
      const prefsData = localStorage.getItem(GUEST_PREFS_KEY);
      if (prefsData) {
        const prefs = JSON.parse(prefsData);
        this.userGender = prefs.gender;
        this.userAge = prefs.age;
        console.log('Restored guest preferences:', { gender: this.userGender, age: this.userAge });
      }
      
      // Restore matches
      const matchesData = localStorage.getItem(GUEST_MATCHES_KEY);
      if (matchesData) {
        this.matches = JSON.parse(matchesData);
        console.log('Restored guest matches:', { count: this.matches.length });
      }
      
      // Restore message history
      const messagesData = localStorage.getItem(GUEST_MESSAGES_KEY);
      if (messagesData) {
        this.messages = JSON.parse(messagesData);
        console.log('Restored guest messages:', { 
          matchCount: Object.keys(this.messages).length,
          totalMessages: Object.values(this.messages).flat().length
        });
      }
      
      // Restore profiles state
      const profilesData = localStorage.getItem(GUEST_PROFILES_KEY);
      if (profilesData) {
        const data = JSON.parse(profilesData);
        this.profiles = data.profiles;
        this.currentProfileIndex = data.currentIndex;
        this.likedProfiles = data.liked;
        this.dislikedProfiles = data.disliked;
        console.log('Restored guest profiles state');
      }
    } catch (error) {
      console.error('Error restoring guest state from localStorage:', error);
      // If restore fails, we'll just start fresh
    }
  }

  // Initialize profiles based on user preferences
  initializeProfiles(gender, age) {
    // 'gender' here is what the user is interested in, not the user's gender
    // So if gender = 'women', user wants to see women profiles
    this.userGender = gender;
    this.userAge = parseInt(age);
    
    console.log(`Initializing guest profiles for: interested in ${gender}, age ${age}`);
    
    // Get profiles of the gender the user is interested in, not the opposite
    this.profiles = getGuestProfiles(gender, this.userAge);
    this.currentProfileIndex = 0;
    
    console.log(`Retrieved ${this.profiles.length} profiles for gender: ${gender}`);
    
    // Shuffle profiles for randomness
    this.shuffleProfiles();
    
    // Save state after initialization
    this.saveState();
    
    return this.profiles.length > 0;
  }
  
  // Shuffle profiles for variety
  shuffleProfiles() {
    for (let i = this.profiles.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [this.profiles[i], this.profiles[j]] = [this.profiles[j], this.profiles[i]];
    }
  }
  
  // Get the next profile to show
  getNextProfile() {
    // Check if profiles are initialized
    if (!this.profiles || !Array.isArray(this.profiles) || this.profiles.length === 0) {
      console.error('No profiles available. Initializing with default values.');
      
      // Re-initialize with some defaults if user data is available
      if (this.userGender && this.userAge) {
        const oppositeGender = this.userGender === 'men' ? 'women' : 'men';
        this.profiles = getGuestProfiles(oppositeGender, this.userAge);
        
        if (!this.profiles || this.profiles.length === 0) {
          console.error('Failed to get profiles for', { gender: oppositeGender, age: this.userAge });
          return null;
        }
        
        this.shuffleProfiles();
      } else {
        console.error('Cannot initialize profiles without user gender and age');
        return null;
      }
    }

    // If we've gone through all profiles, reshuffle and start over
    if (this.currentProfileIndex >= this.profiles.length) {
      this.shuffleProfiles();
      this.currentProfileIndex = 0;
    }
    
    // Find the next profile that hasn't been liked or disliked
    let foundValidProfile = false;
    let profile = null;
    let startIndex = this.currentProfileIndex;
    let loopCount = 0;
    
    // Loop through profiles until we find one that hasn't been interacted with yet
    // or until we've checked all available profiles
    while (!foundValidProfile && loopCount < this.profiles.length) {
      if (this.currentProfileIndex >= this.profiles.length) {
        this.shuffleProfiles();
        this.currentProfileIndex = 0;
      }
      
      profile = this.profiles[this.currentProfileIndex++];
      
      if (!profile) {
        console.error('Retrieved null profile at index', this.currentProfileIndex - 1);
        loopCount++;
        continue;
      }
      
      // Check if this profile ID has already been liked or disliked
      const isLiked = this.likedProfiles.includes(profile.id);
      const isDisliked = this.dislikedProfiles.includes(profile.id);
      
      // Check if this profile is already matched (extra check to be safe)
      const isMatched = this.matches.some(match => 
        match.profileData?.id === profile.id || 
        match.profile?.id === profile.id
      );
      
      // Only show profiles that haven't been interacted with
      if (!isLiked && !isDisliked && !isMatched) {
        foundValidProfile = true;
        console.log('Found valid profile that hasn\'t been interacted with:', profile.name);
      } else {
        console.log('Skipping already interacted profile:', profile.name, { 
          isLiked, 
          isDisliked,
          isMatched
        });
        loopCount++;
      }
    }
    
    // If we couldn't find a valid profile after checking all available ones,
    // get new profiles or return null
    if (!foundValidProfile) {
      console.log('No uninteracted profiles found after checking', loopCount, 'profiles');
      
      // If we've interacted with all profiles, consider getting fresh ones
      // or showing a message to the user that they've seen everyone
      return null;
    }
    
    // Defensive check for images
    const images = profile.images || [];
    const interests = profile.interests || ['Interesting Person'];
    
    // Convert profile format to match what SwipeScreen expects
    return {
      ...profile,
      // Convert images array to photos array with url property expected by SwipeScreen
      photos: images.map(image => ({ url: image })),
      // Add default fields that might be expected
      location: "Nearby",
      occupation: interests[0] || "Interesting Person"
    };
  }
  
  // Simulate user liking a profile
  likeProfile(profileId) {
    const profile = this.profiles.find(p => p.id === profileId);
    if (!profile) return null;
    
    this.likedProfiles.push(profileId);
    
    // 70% chance of match when user likes someone
    const isMatch = Math.random() < 0.7;
    
    if (isMatch) {
      // Create match with profileData directly for consistency
      this.matches.push({
        id: `match_${Date.now()}`,
        // Store profile directly in profileData for MatchesScreen compatibility
        profileData: {
          ...profile,
          // Ensure photos field is present in expected format
          photos: profile.photos || (profile.images ? profile.images.map(url => ({ url })) : []),
          // Add location if missing
          location: profile.location || "Nearby",
          // Add occupation if missing
          occupation: profile.occupation || profile.interests?.[0] || "Interesting Person"
        },
        createdAt: new Date().toISOString(),
        lastMessage: null
      });
      
      // Save state after creating a match
      this.saveState();
      
      return { match: true, profile };
    }
    
    return { match: false };
  }
  
  // Simulate user disliking a profile
  dislikeProfile(profileId) {
    this.dislikedProfiles.push(profileId);
    // Save state after disliking
    this.saveState();
    return { success: true };
  }
  
  // Get the user's matches
  getMatches() {
    // Transform all matches to have profileData in the expected format
    return this.matches.map(match => {
      // If match already has profileData, use it
      if (match.profileData) {
        return match;
      }
      
      // Otherwise transform profile to profileData
      return {
        ...match,
        profileData: {
          ...match.profile,
          // Convert images to photos format if needed
          photos: match.profile.photos || (match.profile.images ? match.profile.images.map(url => ({ url })) : []),
          // Add location if missing
          location: match.profile.location || "Nearby",
          // Add occupation if missing
          occupation: match.profile.occupation || match.profile.interests?.[0] || "Interesting Person"
        }
      };
    });
  }
  
  // Get a specific match by ID
  getMatch(matchId) {
    if (!matchId) {
      console.error('getMatch called with invalid matchId:', matchId);
      return null;
    }
    
    if (!this.matches || !Array.isArray(this.matches)) {
      console.error('matches property is not an array:', this.matches);
      return null;
    }
    
    console.log(`Looking for match with ID: ${matchId} in ${this.matches.length} matches`);
    
    // Do a more comprehensive check on each match
    for (let i = 0; i < this.matches.length; i++) {
      const match = this.matches[i];
      if (!match) {
        console.warn(`Invalid match at index ${i}:`, match);
        continue;
      }
      
      if (match.id === matchId) {
        console.log(`Found match at index ${i}:`, match);
        return match;
      }
    }
    
    console.warn(`No match found with ID: ${matchId}`);
    return null;
  }
  
  // Create a mock chat for a match
  createChat(matchId) {
    const match = this.getMatch(matchId);
    if (!match) return null;
    
    return {
      id: `chat_${matchId}`,
      matchId,
      profile: match.profile,
      messages: []
    };
  }
  
  // Save a message to a chat
  saveMessage(matchId, message, isAI = false) {
    if (!matchId) {
      console.error('Cannot save message: No match ID provided');
      throw new Error('No match ID provided');
    }
    
    if (!message || !message.trim()) {
      console.error('Cannot save message: Empty message content');
      throw new Error('Empty message content');
    }
    
    // First try to find the match
    let match = this.getMatch(matchId);
    
    // If match doesn't exist, create a temporary one to fix the "match not found" issue
    if (!match) {
      console.warn(`Match with ID ${matchId} not found. Creating a temporary match...`);
      
      // Create a temporary character if we don't have this match
      const tempCharacterName = 'Character';
      const matchProfile = {
        id: `profile_${Date.now()}`,
        name: tempCharacterName,
        age: 25,
        images: [],
        photos: []
      };
      
      // Create a new match 
      match = {
        id: matchId,
        profileData: matchProfile,
        createdAt: new Date().toISOString(),
        lastMessage: null
      };
      
      // Add to matches
      this.matches.push(match);
      console.log(`Created temporary match with ID ${matchId}`, match);
      
      // Save state to persist this new match
      this.saveState();
    }
    
    // Create the message - use a format that works with both backend API and frontend UI
    const messageId = `msg_${Date.now()}${Math.floor(Math.random() * 1000)}`;
    const newMessage = {
      id: messageId,
      // Include both content (API format) and text (UI format) for compatibility
      content: message,
      text: message,
      // Include both senderId (API format) and sender (UI format)
      senderId: isAI ? null : 'guest',
      sender: isAI ? 'ai' : 'user',
      createdAt: new Date().toISOString(),
      // Add character for UI expectations
      character: match.profileData?.name || 'Character'
    };
    
    // Update last message in match
    match.lastMessage = {
      content: message,
      timestamp: new Date().toISOString(),
      isRead: true
    };
    
    // Store the message in history
    if (!this.messages[matchId]) {
      this.messages[matchId] = [];
    }
    this.messages[matchId].push(newMessage);
    
    console.log(`Saved ${isAI ? 'AI' : 'user'} message to match ${matchId}:`, {
      messageId,
      contentPreview: message.substring(0, 30) + (message.length > 30 ? '...' : ''),
      totalMessages: this.messages[matchId].length
    });
    
    // Save state after updating message
    try {
      this.saveState();
      console.log(`Successfully persisted message ${messageId} to localStorage`);
    } catch (error) {
      console.error(`Failed to persist message to localStorage:`, error);
      // Continue even if localStorage fails - at least the message is in memory
    }
    
    return newMessage;
  }
  
  // Get messages for a chat
  getMessages(matchId) {
    if (!matchId) {
      console.warn('getMessages called with invalid matchId');
      return [];
    }
    
    try {
      // Ensure we have the freshest data from localStorage first
      this.refreshMessagesFromStorage();
      
      // Get messages for this match, or empty array if none exist
      const messages = this.messages[matchId] || [];
      
      // Debug log to help track message retrieval
      console.log(`Getting ${messages.length} messages for match ${matchId}:`, {
        messages: messages.map(msg => ({
          id: msg.id,
          sender: msg.sender || (msg.senderId === null ? 'ai' : 'user'),
          contentPreview: msg.content?.substring(0, 15) || ''
        })),
        hasUserMessage: messages.some(msg => msg.sender === 'user' || msg.senderId !== null),
        hasAiMessage: messages.some(msg => msg.sender === 'ai' || msg.senderId === null)
      });
      
      return messages;
    } catch (error) {
      console.error('Error getting messages:', error);
      return [];
    }
  }
  
  // Helper method to refresh messages data from localStorage
  refreshMessagesFromStorage() {
    try {
      const messagesData = localStorage.getItem(GUEST_MESSAGES_KEY);
      if (messagesData) {
        const parsedMessages = JSON.parse(messagesData);
        // Only update if there's actual data
        if (parsedMessages && Object.keys(parsedMessages).length > 0) {
          this.messages = parsedMessages;
          console.log('Refreshed messages from localStorage:', { 
            matchCount: Object.keys(this.messages).length,
            totalMessages: Object.values(this.messages).flat().length
          });
        }
      }
    } catch (error) {
      console.error('Error refreshing messages from localStorage:', error);
    }
  }
  
  // Clear data when guest session ends
  clear() {
    this.profiles = {};
    this.currentProfileIndex = 0;
    this.likedProfiles = [];
    this.dislikedProfiles = [];
    this.matches = [];
    this.userGender = null;
    this.userAge = null;
    this.messages = {}; // Clear message history
    
    // Clear localStorage
    localStorage.removeItem(GUEST_PREFS_KEY);
    localStorage.removeItem(GUEST_MATCHES_KEY);
    localStorage.removeItem(GUEST_PROFILES_KEY);
    localStorage.removeItem(GUEST_MESSAGES_KEY);
    
    console.log('Guest data cleared');
  }

  // Unmatch a profile
  unmatchProfile(matchId) {
    console.log('GuestProfileService.unmatchProfile called with ID:', matchId);
    console.log('Current matches:', this.matches);
    
    if (!matchId) {
      console.error('Cannot unmatch: No match ID provided');
      return { success: false, error: 'No match ID provided' };
    }
    
    console.log(`Unmatching profile with match ID: ${matchId}`);
    
    try {
      // Find the match to get information before removal
      console.log('Looking for match with ID:', matchId);
      const match = this.getMatch(matchId);
      console.log('Found match:', match);
      
      // Check if match exists
      if (!match) {
        console.warn(`Match with ID ${matchId} not found`);
        return { success: false, error: 'Match not found' };
      }
      
      // Verify the match has required properties
      if (!match.id) {
        console.error('Match object is missing ID property:', match);
        return { success: false, error: 'Invalid match object (missing ID)' };
      }
      
      // Find the match index
      console.log('Finding match index in array...');
      const matchIndex = this.matches.findIndex(m => m && m.id === matchId);
      console.log('Match index:', matchIndex);
      
      if (matchIndex === -1) {
        console.warn(`Match with ID ${matchId} not found in array`);
        return { success: false, error: 'Match not found in array' };
      }
      
      // Get profile info for the response (with null checks)
      const profileData = match.profileData || { id: matchId };
      
      // Remove the match
      console.log('Removing match at index:', matchIndex);
      this.matches.splice(matchIndex, 1);
      console.log('After removal, matches array length:', this.matches.length);
      
      // Remove any associated messages
      if (this.messages[matchId]) {
        delete this.messages[matchId];
        console.log(`Deleted messages for match ${matchId}`);
      }
      
      // Save state after unmatching
      console.log('Saving state after unmatch...');
      this.saveState();
      
      console.log(`Successfully unmatched profile with match ID: ${matchId}`);
      const returnValue = { 
        success: true,
        message: 'Successfully unmatched',
        profile: profileData
      };
      console.log('Returning:', returnValue);
      return returnValue;
    } catch (error) {
      console.error(`Error unmatching profile ${matchId}:`, error);
      return {
        success: false,
        error: `Unmatch failed: ${error.message}`
      };
    }
  }

  // Safe unmatch method that doesn't throw errors and just updates UI state
  safeUnmatchProfile(matchId) {
    if (!matchId) {
      console.log('Cannot unmatch: No match ID provided');
      return false;
    }
    
    try {
      console.log(`Safe unmatching for match ID: ${matchId}`);
      
      // Find the match index directly
      const matchIndex = (this.matches || []).findIndex(m => m && m.id === matchId);
      
      if (matchIndex === -1) {
        console.log(`No match found with ID: ${matchId}`);
        return false;
      }
      
      // Remove the match directly
      this.matches.splice(matchIndex, 1);
      
      // Remove messages if they exist
      if (this.messages && this.messages[matchId]) {
        delete this.messages[matchId];
      }
      
      // Save updated state
      this.saveState();
      
      console.log(`Successfully unmatched: ${matchId}`);
      return true;
    } catch (error) {
      console.error('Error in safeUnmatchProfile:', error);
      return false;
    }
  }
}

export default GuestProfileService; 