// tools/CharacterImageTool.js
import { z } from 'zod';
import { tool } from 'ai';

// Note: Removed import for ImageGenerationService

export const createCharacterImageTool = (character, authenticatedFetch) => {
  return tool({
    name: 'generateCharacterPhoto',
    description: `Generate a photo of yourself (${character.name}) to share with the user. Use this when you want to show the user what you look like or share a photo that matches the conversation context.`,
    parameters: z.object({
      prompt: z.string().describe(`An image prompt describing what you (${character.name}) are doing, your expression, and the setting. Be specific and descriptive about the scene, your pose, expression, and surroundings.`)
    }),
    execute: async ({ prompt }) => {
      try {
        console.log(`🔍 Photo request from ${character.name} with prompt: ${prompt}`);
        console.log(`🔍 Character data:`, {
          id: character.id,
          name: character.name,
          hasProfileImage: !!character.profileImageUrl,
          profileImagePreview: character.profileImageUrl 
            ? character.profileImageUrl.substring(0, 30) + '...' 
            : 'none'
        });
        
        // Return data for photo request (without making API call yet)
        return {
          success: true,
          generationPrompt: prompt,
          creditCost: 1,
          readyToView: false, // For Snapchat-style preview
          characterId: character.id,
          characterName: character.name,
          characterDescription: character.personality || character.description || '',
          profileImageUrl: character.profileImageUrl || null,
          specificPrompt: prompt,
          seed: Math.floor(Math.random() * 1000000),
          matchId: character.matchId
        };
      } catch (error) {
        console.error('Error in generateCharacterPhoto tool:', error);
        return {
          success: false,
          error: error.message || 'An error occurred preparing the photo request',
          readyToView: false
        };
      }
    }
  });
}; 