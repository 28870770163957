import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { API_URL } from '../config/api.js';

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { authenticatedFetch } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const sessionId = searchParams.get('session_id');
    if (!sessionId) {
      setError('No session ID found');
      setLoading(false);
      return;
    }

    // Verify the session and update the subscription
    const verifySession = async () => {
      try {
        const response = await authenticatedFetch(`${API_URL}/api/subscription/verify-session/${sessionId}`);
        
        if (!response.ok) {
          throw new Error('Failed to verify payment session');
        }

        // Wait a moment to ensure subscription is active
        setTimeout(() => {
          navigate('/payment-plans', { replace: true });
        }, 2000);
      } catch (err) {
        console.error('Error verifying session:', err);
        setError('Failed to verify payment. Please contact support if the issue persists.');
      } finally {
        setLoading(false);
      }
    };

    verifySession();
  }, [searchParams, authenticatedFetch, navigate]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-900 flex flex-col items-center justify-center text-white">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
        <h2 className="mt-8 text-2xl font-semibold">Processing your payment...</h2>
        <p className="mt-2 text-gray-400">Please wait while we confirm your subscription.</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-900 flex flex-col items-center justify-center text-white">
        <div className="bg-red-500 bg-opacity-10 rounded-lg p-8">
          <h2 className="text-2xl font-semibold text-red-500">Payment Error</h2>
          <p className="mt-2 text-gray-400">{error}</p>
          <button
            onClick={() => navigate('/payment-plans')}
            className="mt-6 px-4 py-2 bg-purple-600 hover:bg-purple-700 rounded-md"
          >
            Return to Plans
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col items-center justify-center text-white">
      <div className="bg-green-500 bg-opacity-10 rounded-lg p-8">
        <h2 className="text-2xl font-semibold text-green-500">Payment Successful!</h2>
        <p className="mt-2 text-gray-400">Your subscription has been activated.</p>
        <p className="mt-1 text-gray-400">Redirecting you back to the plans page...</p>
      </div>
    </div>
  );
};

export default PaymentSuccess;
