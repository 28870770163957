import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext'; // Import useAuth

const TestImageGeneration = () => {
  const { authenticatedFetch } = useAuth(); // Get authenticatedFetch
  const [isGenerating, setIsGenerating] = useState(false);
  const [generatedImage, setGeneratedImage] = useState(null);
  const [error, setError] = useState(null);

  const generateTestImage = async () => {
    setIsGenerating(true);
    setError(null);
    
    try {
      // Call the backend API endpoint
      const response = await authenticatedFetch('/api/images/generate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          characterName: "Alex",
          characterDescription: "A confident 28-year-old with short brown hair and glasses",
          specificPrompt: "smiling, at a coffee shop",
          matchId: null, // Provide null or a test ID if needed
          characterId: 'alex_test' // Provide a test ID
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to generate image');
      }
      
      const result = await response.json();
      
      setGeneratedImage(result);
      console.log("Generated image result:", result);
    } catch (err) {
      console.error("Error generating image:", err);
      setError(err.message || "Failed to generate image");
    } finally {
      setIsGenerating(false);
    }
  };
  
  return (
    <div className="p-4 bg-white rounded-lg shadow max-w-md mx-auto my-8">
      <h2 className="text-xl font-bold mb-4">Test Image Generation</h2>
      
      <button 
        onClick={generateTestImage}
        disabled={isGenerating}
        className="bg-gradient-to-r from-purple-500 to-pink-500 text-white px-4 py-2 rounded-lg disabled:opacity-50"
      >
        {isGenerating ? 'Generating...' : 'Generate Test Image'}
      </button>
      
      {error && (
        <div className="mt-4 p-3 bg-red-100 text-red-700 rounded">
          {error}
        </div>
      )}
      
      {generatedImage && (
        <div className="mt-4">
          <p className="text-sm text-gray-500 mb-2">Generated with prompt: {generatedImage.prompt}</p>
          <img 
            src={generatedImage.imageUrl} // Use imageUrl from API response
            alt="Generated character" 
            className="rounded-lg w-full"
          />
        </div>
      )}
    </div>
  );
};

export default TestImageGeneration; 