import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useApp } from '../contexts/AppContext';
import { ChevronLeft, LogOut, LayoutDashboard } from 'lucide-react';

const SettingsScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, authenticatedFetch, logout, updateUser } = useAuth();
  const { 
    selectedModel, 
    setSelectedModel, 
    canUseSimV2,
    swipesRemaining,
    superLikesRemaining,
    purchaseSuperLikes
  } = useApp();
  const [interestedIn, setInterestedIn] = useState(user?.interested_in || '');
  const [minAge, setMinAge] = useState(user?.min_age_preference || 21);
  const [maxAge, setMaxAge] = useState(user?.max_age_preference || 35);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [purchasing, setPurchasing] = useState(false);

  const handlePurchaseSuperLikes = async () => {
    setPurchasing(true);
    try {
      await purchaseSuperLikes();
      // Success message handled by the function
    } catch (err) {
      setError('Failed to purchase super likes');
    } finally {
      setPurchasing(false);
    }
  };

  const updatePreferences = async () => {
    try {
      setSaving(true);
      setError(null);

      const response = await authenticatedFetch('/api/user/update-preferences', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          interested_in: interestedIn,
          min_age_preference: minAge,
          max_age_preference: maxAge,
          selected_model: selectedModel
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to update preferences');
      }

      // Update user context with new preferences
      if (data.user) {
        updateUser(data.user);
      }

      // Check if any preferences changed
      const preferencesChanged = 
        user?.interested_in !== interestedIn ||
        user?.min_age_preference !== minAge ||
        user?.max_age_preference !== maxAge ||
        user?.selected_model !== selectedModel;

      // If any preferences changed, force new profile fetch
      if (preferencesChanged) {
        navigate('/swipe');
      } else {
        // Only preserve state if no preferences changed
        navigate('/swipe', {
          state: {
            profiles: location.state?.profiles,
            currentIndex: location.state?.currentIndex
          }
        });
      }
    } catch (err) {
      setError(err.message || 'Failed to update preferences');
    } finally {
      setSaving(false);
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login', { replace: true });
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleBack = () => {
    // Check if any preferences changed
    const preferencesChanged = 
      user?.interested_in !== interestedIn ||
      user?.min_age_preference !== minAge ||
      user?.max_age_preference !== maxAge ||
      user?.selected_model !== selectedModel;

    // If any preferences changed, force new profile fetch
    if (preferencesChanged) {
      navigate('/swipe');
    } else {
      // Only preserve state if no preferences changed
      navigate('/swipe', {
        state: {
          profiles: location.state?.profiles,
          currentIndex: location.state?.currentIndex
        }
      });
    }
  };

  return (
    <div className="h-screen bg-gray-100 flex flex-col">
      {/* Fixed header */}
      <div className="max-w-md w-full mx-auto px-4 py-4 bg-gray-100">
        <div className="flex items-center justify-between">
          <button
            onClick={handleBack}
            className="bg-white p-2 rounded-full shadow-sm hover:bg-gray-50 transition-colors"
          >
            <ChevronLeft size={24} className="text-gray-600" />
          </button>
          <h1 className="text-2xl font-bold">Settings</h1>
          <div className="w-8"></div> {/* Spacer for centering */}
        </div>
      </div>

      {/* Scrollable content */}
      <div className="flex-1 overflow-y-auto">
        <div className="max-w-md mx-auto px-4 pb-20">
          <div className="bg-white rounded-xl shadow-sm p-6 space-y-6">
          {error && (
            <div className="bg-red-50 border border-red-200 text-red-600 p-3 rounded-lg">
              {error}
            </div>
          )}

          {/* Swipe and Super Like Stats */}
          <div className="border-b border-gray-200 pb-6">
            <h3 className="text-lg font-semibold mb-4">Daily Limits</h3>
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div className="bg-blue-50 p-4 rounded-lg">
                <div className="text-blue-600 font-medium">Swipes Left</div>
                <div className="text-2xl font-bold text-blue-700">
                  {swipesRemaining === -1 ? '∞' : swipesRemaining}
                </div>
              </div>
              <div className="bg-purple-50 p-4 rounded-lg">
                <div className="text-purple-600 font-medium">Super Likes</div>
                <div className="text-2xl font-bold text-purple-700">
                  {superLikesRemaining}
                </div>
              </div>
            </div>
            <button
              onClick={handlePurchaseSuperLikes}
              disabled={purchasing}
              className="w-full bg-gradient-to-r from-purple-500 to-indigo-600 text-white font-semibold py-3 px-4 rounded-lg hover:from-purple-600 hover:to-indigo-700 transition-all disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {purchasing ? 'Purchasing...' : 'Buy 6 Super Likes ($5)'}
            </button>
          </div>

          {/* Admin Dashboard Link */}
          {user?.isAdmin && (
            <div className="border-b border-gray-200 pb-6">
              <button
                onClick={() => navigate('/admin')}
                className="w-full flex items-center justify-center gap-2 bg-indigo-600 text-white font-semibold py-3 px-4 rounded-lg hover:bg-indigo-700 transition-colors"
              >
                <LayoutDashboard size={20} />
                Admin Dashboard
              </button>
            </div>
          )}

          <div className="space-y-6">
            {/* AI Model Selection */}
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                AI Model
              </label>
              <div className="grid grid-cols-2 gap-4">
                <button
                  type="button"
                  onClick={() => setSelectedModel('SIM v1')}
                  className={`px-4 py-2 rounded-lg border transition-all ${
                    selectedModel === 'SIM v1'
                      ? 'bg-purple-100 border-purple-300 text-purple-700'
                      : 'bg-gray-50 border-gray-200 text-gray-600 hover:bg-gray-100'
                  }`}
                >
                  SIM v1
                </button>
                <button
                  type="button"
                  onClick={() => canUseSimV2 && setSelectedModel('SIM v2')}
                  className={`px-4 py-2 rounded-lg border transition-all ${
                    selectedModel === 'SIM v2'
                      ? 'bg-purple-100 border-purple-300 text-purple-700'
                      : canUseSimV2
                      ? 'bg-gray-50 border-gray-200 text-gray-600 hover:bg-gray-100'
                      : 'bg-gray-50 border-gray-200 text-gray-400 cursor-not-allowed'
                  }`}
                >
                  SIM v2 {!canUseSimV2 && '🔒'}
                </button>
              </div>
              {!canUseSimV2 && (
                <p className="mt-2 text-sm text-gray-500">
                  Upgrade to Hobby or higher to unlock SIM v2
                </p>
              )}
            </div>

            {/* Interest Selection */}
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                I'm interested in
              </label>
              <div className="grid grid-cols-2 gap-4">
                <button
                  type="button"
                  onClick={() => setInterestedIn('female')}
                  className={`px-4 py-2 rounded-lg border transition-all ${
                    interestedIn === 'female'
                      ? 'bg-purple-100 border-purple-300 text-purple-700'
                      : 'bg-gray-50 border-gray-200 text-gray-600 hover:bg-gray-100'
                  }`}
                >
                  Women
                </button>
                <button
                  type="button"
                  onClick={() => setInterestedIn('male')}
                  className={`px-4 py-2 rounded-lg border transition-all ${
                    interestedIn === 'male'
                      ? 'bg-purple-100 border-purple-300 text-purple-700'
                      : 'bg-gray-50 border-gray-200 text-gray-600 hover:bg-gray-100'
                  }`}
                >
                  Men
                </button>
              </div>
            </div>

            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Age Range: {minAge} - {maxAge}
              </label>
              <div className="space-y-4">
                <div>
                  <label className="block text-gray-500 text-sm mb-1">Minimum Age</label>
                  <input
                    type="range"
                    min="18"
                    max="100"
                    value={minAge}
                    onChange={(e) => setMinAge(Math.min(parseInt(e.target.value), maxAge))}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-purple-600"
                  />
                </div>
                <div>
                  <label className="block text-gray-500 text-sm mb-1">Maximum Age</label>
                  <input
                    type="range"
                    min="18"
                    max="100"
                    value={maxAge}
                    onChange={(e) => setMaxAge(Math.max(parseInt(e.target.value), minAge))}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-purple-600"
                  />
                </div>
              </div>
            </div>
          </div>

          <button
            onClick={updatePreferences}
            disabled={saving}
            className={`w-full bg-purple-600 text-white font-semibold py-3 px-4 rounded-lg hover:bg-purple-700 transition-colors ${
              saving ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {saving ? 'Saving...' : 'Save Changes'}
          </button>

          <div className="border-t border-gray-200 pt-6">
            <button
              onClick={() => navigate('/payment-plans')}
              className="w-full flex items-center justify-center gap-2 bg-gradient-to-r from-indigo-500 to-purple-600 text-white font-semibold py-3 px-4 rounded-lg hover:from-indigo-600 hover:to-purple-700 transition-all mb-4"
            >
              <span>💎</span>
              Upgrade Your Plan
            </button>
          </div>

          <div className="border-t border-gray-200 pt-6">
            <button
              onClick={handleLogout}
              className="w-full flex items-center justify-center gap-2 text-red-600 font-medium hover:text-red-700 transition-colors"
            >
              <LogOut size={20} />
              Log Out
            </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsScreen;
