// Pre-generated profiles for guest mode
// Each profile follows the same structure as API-returned profiles

// Helper to generate unique IDs
const generateId = (prefix, index) => `guest_${prefix}_${index}`;

// Women profiles for different age ranges
const womenProfiles = {
  // 18-25 age range
  young: [
    {
      id: generateId('women_young', 1),
      name: "Sophia",
      age: 23,
      bio: "Art student with a passion for photography. Looking for someone to explore galleries with. Coffee addict and dog lover. Ask me about my latest exhibition!",
      interests: ["Photography", "Art", "Coffee", "Travel"],
      images: ["https://gotgames.org/guest_profile_images/guest_woman_sophia/guest_profile-guest_woman_sophia-1743176137963.jpg"],
      personality: "Creative, thoughtful, and a bit of a night owl. I value deep conversations and authenticity.",
      textingBehavior: "doubleTexter"
    },
    {
      id: generateId('women_young', 2),
      name: "Emma",
      age: 24,
      bio: "Psychology major by day, amateur chef by night. Let's talk about your favorite book or debate the best pizza toppings.",
      interests: ["Cooking", "Reading", "Psychology", "Hiking"],
      images: ["https://gotgames.org/guest_profile_images/guest_woman_emma/guest_profile-guest_woman_emma-1743176127774.jpg"],
      personality: "Curious, empathetic, and always up for a good debate. I'm passionate about understanding people.",
      textingBehavior: "tripleTexter"
    },
    {
      id: generateId('women_young', 3),
      name: "Olivia",
      age: 21,
      bio: "Music festival enthusiast looking for someone to share playlists with. Yes, I play guitar, and no, I won't play Wonderwall.",
      interests: ["Music", "Concerts", "Guitar", "Travel"],
      images: ["https://gotgames.org/guest_profile_images/guest_woman_olivia/guest_profile-guest_woman_olivia-1743176137836.jpg"],
      personality: "Energetic, spontaneous, with a touch of sarcasm. I live for the moment and love adventures.",
      textingBehavior: "spamTexter"
    },
    {
      id: generateId('women_young', 4),
      name: "Zoe",
      age: 19,
      bio: "College sophomore studying environmental science. Plant mom and volunteer at the local animal shelter. Looking for someone who cares about the planet as much as I do.",
      interests: ["Environment", "Animals", "Activism", "Nature"],
      images: ["https://gotgames.org/guest_profile_images/guest_woman_zoe/guest_profile-guest_woman_zoe-1743176127761.jpg"],
      personality: "Passionate, determined, and optimistic about making a difference. I can be intense about causes I care about.",
      textingBehavior: "normal"
    },
    {
      id: generateId('women_young', 5),
      name: "Mia",
      age: 25,
      bio: "Tech startup employee by day, DJ by night. Looking for someone who can keep up with my schedule and join me for spontaneous road trips.",
      interests: ["Music", "Technology", "Travel", "Food"],
      images: ["https://gotgames.org/guest_profile_images/guest_woman_mia/guest_profile-guest_woman_mia-1743176137898.jpg"],
      personality: "Ambitious, sociable, and always on the move. I struggle with work-life balance but I'm trying to improve.",
      textingBehavior: "slowResponder"
    }
  ],
  
  // 26-35 age range
  mid: [
    {
      id: generateId('women_mid', 1),
      name: "Charlotte",
      age: 29,
      bio: "Marketing director with a passion for indie films and weekend hikes. Looking for someone who appreciates both ambition and balance.",
      interests: ["Marketing", "Film", "Hiking", "Wine"],
      images: ["https://gotgames.org/guest_profile_images/guest_woman_charlotte/guest_profile-guest_woman_charlotte-1743176162485.jpg"],
      personality: "Driven, analytical, and thoughtful. I've learned to balance my ambition with self-care and meaningful connections.",
      textingBehavior: "doubleTexter"
    },
    {
      id: generateId('women_mid', 2),
      name: "Ava",
      age: 31,
      bio: "Travel blogger who's visited 42 countries and counting. Looking for someone to add stamps to my passport with. Currently planning a Southeast Asia trip.",
      interests: ["Travel", "Writing", "Photography", "Languages"],
      images: ["https://gotgames.org/guest_profile_images/guest_woman_ava/guest_profile-guest_woman_ava-1743176163501.jpg"],
      personality: "Adventurous, independent, and culturally curious. I value freedom and experiences over possessions.",
      textingBehavior: "normal"
    },
    {
      id: generateId('women_mid', 3),
      name: "Isabella",
      age: 27,
      bio: "Pediatric nurse who loves cooking elaborate meals and singing karaoke (badly). Looking for someone who makes me laugh.",
      interests: ["Healthcare", "Cooking", "Music", "Comedy"],
      images: ["https://gotgames.org/guest_profile_images/guest_woman_isabella/guest_profile-guest_woman_isabella-1743176162411.jpg"],
      personality: "Compassionate, nurturing, with a quirky sense of humor. My job can be emotionally demanding, so I value authentic connections.",
      textingBehavior: "tripleTexter"
    },
    {
      id: generateId('women_mid', 4),
      name: "Harper",
      age: 33,
      bio: "Architect and urban sketcher. Coffee enthusiast and amateur boxer. I appreciate good design in all things, especially conversations.",
      interests: ["Architecture", "Art", "Boxing", "Coffee"],
      images: ["https://gotgames.org/guest_profile_images/guest_woman_harper/guest_profile-guest_woman_harper-1743176162670.jpg"],
      personality: "Creative, detail-oriented, and direct. I appreciate honesty and find beauty in the structures of everyday life.",
      textingBehavior: "slowResponder"
    },
    {
      id: generateId('women_mid', 5),
      name: "Lily",
      age: 35,
      bio: "Tech entrepreneur who still finds time for yoga and volunteering. Looking for someone who values personal growth and giving back.",
      interests: ["Technology", "Yoga", "Volunteering", "Entrepreneurship"],
      images: ["https://gotgames.org/guest_profile_images/guest_woman_lily/guest_profile-guest_woman_lily-1743176162514.jpg"],
      personality: "Ambitious, mindful, and community-oriented. I've learned to balance drive with presence and wellness.",
      textingBehavior: "ghoster"
    }
  ],
  
  // 36+ age range
  mature: [
    {
      id: generateId('women_mature', 1),
      name: "Elizabeth",
      age: 38,
      bio: "Psychology professor and published author. Wine enthusiast who loves meaningful conversations. Looking for intellectual connection and adventure.",
      interests: ["Psychology", "Writing", "Wine", "Travel"],
      images: ["https://gotgames.org/guest_profile_images/guest_woman_elizabeth/guest_profile-guest_woman_elizabeth-1743176189191.jpg"],
      personality: "Intellectual, insightful, and quietly confident. I value depth over breadth in relationships and pursuits.",
      textingBehavior: "doubleTexter"
    },
    {
      id: generateId('women_mature', 2),
      name: "Samantha",
      age: 42,
      bio: "Executive chef who travels for food inspiration. Recently back from a culinary tour of Japan. Looking for someone who appreciates the art of dining.",
      interests: ["Cooking", "Travel", "Food", "Culture"],
      images: ["https://gotgames.org/guest_profile_images/guest_woman_samantha/guest_profile-guest_woman_samantha-1743176189038.jpg"],
      personality: "Passionate, perfectionistic, and adventurous with flavors and experiences. I can be intense but I'm learning to savor life's moments.",
      textingBehavior: "slowResponder"
    },
    {
      id: generateId('women_mature', 3),
      name: "Victoria",
      age: 45,
      bio: "Financial advisor by day, amateur theater performer by night. Recently divorced and rediscovering myself. Looking for genuine connection.",
      interests: ["Finance", "Theater", "Reading", "Hiking"],
      images: ["https://gotgames.org/guest_profile_images/guest_woman_victoria/guest_profile-guest_woman_victoria-1743176188814.jpg"],
      personality: "Analytical, expressive, and resilient. I've learned that vulnerability is strength and am comfortable with who I am.",
      textingBehavior: "normal"
    },
    {
      id: generateId('women_mature', 4),
      name: "Grace",
      age: 40,
      bio: "Human rights lawyer who unwinds with gardening and pottery. Looking for someone passionate about making a difference.",
      interests: ["Law", "Gardening", "Pottery", "Activism"],
      images: ["https://gotgames.org/guest_profile_images/guest_woman_grace/guest_profile-guest_woman_grace-1743176188723.jpg"],
      personality: "Passionate, principled, and nurturing. I fight for justice but need peaceful spaces to recharge.",
      textingBehavior: "tripleTexter"
    },
    {
      id: generateId('women_mature', 5),
      name: "Catherine",
      age: 49,
      bio: "Art gallery owner with a love for jazz and spontaneous weekend trips. Empty nester with newfound freedom to explore life's pleasures.",
      interests: ["Art", "Music", "Travel", "Food & Wine"],
      images: ["https://gotgames.org/guest_profile_images/guest_woman_catherine/guest_profile-guest_woman_catherine-1743176189172.jpg"],
      personality: "Cultured, independent, and experiencing a renaissance in my life. I value authenticity and am excited about this new chapter.",
      textingBehavior: "ghoster"
    }
  ]
};

// Men profiles for different age ranges
const menProfiles = {
  // 18-25 age range
  young: [
    {
      id: generateId('men_young', 1),
      name: "Ethan",
      age: 24,
      bio: "Computer science student and amateur rock climber. Looking for someone to code and climb with, not necessarily in that order.",
      interests: ["Technology", "Climbing", "Hiking", "Gaming"],
      images: ["https://gotgames.org/guest_profile_images/guest_man_ethan/guest_profile-guest_man_ethan-1743176214610.jpg"],
      personality: "Analytical, adventurous, and quietly confident. I enjoy solving problems and pushing my limits.",
      textingBehavior: "normal"
    },
    {
      id: generateId('men_young', 2),
      name: "Noah",
      age: 22,
      bio: "Music production student working on my first EP. Looking for inspiration and someone to share late-night creative sessions.",
      interests: ["Music", "Production", "Concerts", "Art"],
      images: ["https://gotgames.org/guest_profile_images/guest_man_noah/guest_profile-guest_man_noah-1743176215396.jpg"],
      personality: "Creative, passionate, and nocturnal. I feel things deeply and express myself through music.",
      textingBehavior: "tripleTexter"
    },
    {
      id: generateId('men_young', 3),
      name: "Liam",
      age: 20,
      bio: "Environmental science major and avid surfer. Looking for someone who cares about the planet and isn't afraid of the ocean.",
      interests: ["Environment", "Surfing", "Sustainability", "Travel"],
      images: ["https://gotgames.org/guest_profile_images/guest_man_liam/guest_profile-guest_man_liam-1743176214573.jpg"],
      personality: "Laid-back, environmentally conscious, and deeply connected to nature. I'm most at home in the water.",
      textingBehavior: "slowResponder"
    },
    {
      id: generateId('men_young', 4),
      name: "Mason",
      age: 25,
      bio: "Starting my own fitness app while working as a personal trainer. Coffee addict and weekend hiker. Let's motivate each other.",
      interests: ["Fitness", "Technology", "Entrepreneurship", "Outdoors"],
      images: ["https://gotgames.org/guest_profile_images/guest_man_mason/guest_profile-guest_man_mason-1743176214579.jpg"],
      personality: "Driven, disciplined, and optimistic. I believe in pushing limits and helping others find their strength.",
      textingBehavior: "doubleTexter"
    },
    {
      id: generateId('men_young', 5),
      name: "Jacob",
      age: 19,
      bio: "Film student with a passion for documentaries. Looking for someone to explore the city with and find hidden stories.",
      interests: ["Film", "Photography", "Urban Exploration", "Reading"],
      images: ["https://gotgames.org/guest_profile_images/guest_man_jacob/guest_profile-guest_man_jacob-1743176214835.jpg"],
      personality: "Observant, thoughtful, and curious about people's stories. I see the world through a lens of narrative.",
      textingBehavior: "spamTexter"
    }
  ],
  
  // 26-35 age range
  mid: [
    {
      id: generateId('men_mid', 1),
      name: "William",
      age: 32,
      bio: "Software engineer by day, amateur chef by night. Recently got into brewing my own beer. Looking for someone to taste test my experiments.",
      interests: ["Technology", "Cooking", "Beer", "Music"],
      images: ["https://gotgames.org/guest_profile_images/guest_man_william/guest_profile-guest_man_william-1743176240130.jpg"],
      personality: "Analytical, detail-oriented, with a dry sense of humor. I enjoy the precision of coding and cooking equally.",
      textingBehavior: "normal"
    },
    {
      id: generateId('men_mid', 2),
      name: "James",
      age: 29,
      bio: "Marketing director who moonlights as a DJ on weekends. Looking for someone who enjoys both thought-provoking conversations and dancing until dawn.",
      interests: ["Marketing", "Music", "Nightlife", "Psychology"],
      images: ["https://gotgames.org/guest_profile_images/guest_man_james/guest_profile-guest_man_james-1743176240128.jpg"],
      personality: "Social, creative, and analytically minded. I have different sides to my personality and appreciate the same in others.",
      textingBehavior: "doubleTexter"
    },
    {
      id: generateId('men_mid', 3),
      name: "Alexander",
      age: 34,
      bio: "Architect with a passion for sustainable design. Recently returned from a year working abroad in Scandinavia. Coffee enthusiast and minimalist.",
      interests: ["Architecture", "Sustainability", "Travel", "Design"],
      images: ["https://gotgames.org/guest_profile_images/guest_man_alexander/guest_profile-guest_man_alexander-1743176240129.jpg"],
      personality: "Thoughtful, environmentally conscious, and appreciative of clean design in all things. I value purpose and intention.",
      textingBehavior: "tripleTexter"
    },
    {
      id: generateId('men_mid', 4),
      name: "Benjamin",
      age: 28,
      bio: "Healthcare consultant and weekend warrior triathlete. Looking for someone who challenges me intellectually and keeps up with me physically.",
      interests: ["Fitness", "Healthcare", "Business", "Outdoors"],
      images: ["https://gotgames.org/guest_profile_images/guest_man_benjamin/guest_profile-guest_man_benjamin-1743176240253.jpg"],
      personality: "Driven, analytical, and competitive with myself. I push boundaries and am always working toward the next goal.",
      textingBehavior: "slowResponder"
    },
    {
      id: generateId('men_mid', 5),
      name: "Daniel",
      age: 31,
      bio: "Freelance photographer specializing in travel and food. Just returned from a month in Southeast Asia. Looking for my next adventure companion.",
      interests: ["Photography", "Travel", "Food", "Culture"],
      images: ["https://gotgames.org/guest_profile_images/guest_man_daniel/guest_profile-guest_man_daniel-1743176240133.jpg"],
      personality: "Observant, independent, and adaptable. I value experiences over possessions and am happiest exploring new places.",
      textingBehavior: "ghoster"
    }
  ],
  
  // 36+ age range
  mature: [
    {
      id: generateId('men_mature', 1),
      name: "Matthew",
      age: 42,
      bio: "Executive chef who travels for food inspiration. Recently launched my first restaurant. Looking for someone who appreciates culinary adventures.",
      interests: ["Cooking", "Entrepreneurship", "Travel", "Wine"],
      images: ["https://gotgames.org/guest_profile_images/guest_man_matthew/guest_profile-guest_man_matthew-1743176265806.jpg"],
      personality: "Passionate, perfectionistic, and creative. I pour my heart into my work and value authentic connection outside of it.",
      textingBehavior: "normal"
    },
    {
      id: generateId('men_mature', 2),
      name: "Christopher",
      age: 45,
      bio: "Financial analyst by day, amateur astronomy enthusiast by night. Recently divorced and rediscovering what matters. Looking for meaningful connection.",
      interests: ["Finance", "Astronomy", "Reading", "Hiking"],
      images: ["https://gotgames.org/guest_profile_images/guest_man_christopher/guest_profile-guest_man_christopher-1743176265946.jpg"],
      personality: "Analytical, thoughtful, and going through a period of personal growth. I've learned that vulnerability is valuable.",
      textingBehavior: "doubleTexter"
    },
    {
      id: generateId('men_mature', 3),
      name: "Andrew",
      age: 39,
      bio: "University professor specializing in environmental policy. Published author and avid cyclist. Looking for an intellectual and active partner.",
      interests: ["Environment", "Politics", "Cycling", "Literature"],
      images: ["https://gotgames.org/guest_profile_images/guest_man_andrew/guest_profile-guest_man_andrew-1743176265388.jpg"],
      personality: "Intellectual, principled, and curious. I value deep discussions and active engagement with the world around us.",
      textingBehavior: "tripleTexter"
    },
    {
      id: generateId('men_mature', 4),
      name: "David",
      age: 48,
      bio: "Successful entrepreneur who's recently sold my company. Taking time to explore new interests in sailing and photography. Looking for new beginnings.",
      interests: ["Business", "Sailing", "Photography", "Travel"],
      images: ["https://gotgames.org/guest_profile_images/guest_man_david/guest_profile-guest_man_david-1743176265193.jpg"],
      personality: "Confident, accomplished, and entering a new phase of life. I'm rediscovering my passions beyond work.",
      textingBehavior: "slowResponder"
    },
    {
      id: generateId('men_mature', 5),
      name: "Michael",
      age: 53,
      bio: "Architect transitioning to teaching. Empty nester with a newfound appreciation for life's simple pleasures. Looking for someone to share this chapter with.",
      interests: ["Architecture", "Teaching", "Wine", "Travel"],
      images: ["https://gotgames.org/guest_profile_images/guest_man_michael/guest_profile-guest_man_michael-1743176265846.jpg"],
      personality: "Reflective, creative, and at peace with where I am in life. I've learned what matters and am looking to share meaningful experiences.",
      textingBehavior: "ghoster"
    }
  ]
};

// Function to get profiles based on gender and age
export const getGuestProfiles = (gender, age) => {
  const profilesByGender = gender === 'women' ? womenProfiles : menProfiles;
  
  let ageCategory;
  if (age <= 25) {
    ageCategory = 'young';
  } else if (age <= 35) {
    ageCategory = 'mid';
  } else {
    ageCategory = 'mature';
  }
  
  return profilesByGender[ageCategory] || [];
};

// Function to get a random match profile for guest mode tutorial
export const getRandomMatchProfile = (interestedIn) => {
  // interestedIn is the gender the user wants to see
  // So if interestedIn='women', we should return a woman profile
  console.log('Getting random match profile for interest in:', interestedIn);
  
  // If no preference is provided, randomly pick a gender
  if (!interestedIn) {
    interestedIn = Math.random() > 0.5 ? 'women' : 'men';
    console.log('No gender preference provided, defaulting to:', interestedIn);
  }
  
  const allProfiles = [...menProfiles.young, ...menProfiles.mid, ...menProfiles.mature, 
                      ...womenProfiles.young, ...womenProfiles.mid, ...womenProfiles.mature];
  
  // Filter to the gender the user is interested in
  const filteredProfiles = allProfiles.filter(profile => {
    return (interestedIn === 'women' && profile.id.includes('women')) || 
           (interestedIn === 'men' && profile.id.includes('men'));
  });
  
  // If no profiles match (which shouldn't happen), use all profiles
  const profilesToUse = filteredProfiles.length > 0 ? filteredProfiles : allProfiles;
  
  // Return a random profile
  const selectedProfile = profilesToUse[Math.floor(Math.random() * profilesToUse.length)];
  console.log('Selected match profile:', selectedProfile?.name, selectedProfile?.id);
  return selectedProfile;
};

export default {
  getGuestProfiles,
  getRandomMatchProfile
}; 