import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useApp } from '../contexts/AppContext';
import { Heart, X, Info, Settings, Star } from 'lucide-react';
import MatchModal from './MatchModal';

const LoadingSpinner = () => (
  <div className="h-[70vh] flex flex-col items-center justify-center bg-gradient-to-b from-indigo-600 via-purple-500 to-pink-500 rounded-2xl">
    <div className="relative w-32 h-32">
      {/* Spinner rings */}
      <motion.div
        className="absolute inset-0 border-4 border-white/30 rounded-full"
        animate={{
          rotate: 360,
          scale: [1, 1.1, 1],
        }}
        transition={{
          duration: 2,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />
      <motion.div
        className="absolute inset-2 border-4 border-white/50 rounded-full"
        animate={{
          rotate: -360,
          scale: [1, 0.9, 1],
        }}
        transition={{
          duration: 3,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />
      <motion.div
        className="absolute inset-4 border-4 border-white rounded-full"
        animate={{
          rotate: 360,
          scale: [1, 1.1, 1],
        }}
        transition={{
          duration: 1.5,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />
    </div>
    <motion.p 
      className="mt-8 text-white text-xl font-medium"
      animate={{
        opacity: [0.5, 1, 0.5],
      }}
      transition={{
        duration: 2,
        repeat: Infinity,
        ease: "easeInOut"
      }}
    >
      Finding matches...
    </motion.p>
  </div>
);

const SwipeScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { 
    user, 
    authenticatedFetch, 
    getGuestProfileService 
  } = useAuth();
  const { 
    superLikesRemaining, 
    swipesRemaining,
    useSwipe,
    useSuperLike,
    setSwipesRemaining,
    setSuperLikesRemaining
  } = useApp();
  const [profiles, setProfiles] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showInfo, setShowInfo] = useState(false);
  const [direction, setDirection] = useState(null);
  const [showMatch, setShowMatch] = useState(false);
  const [currentMatch, setCurrentMatch] = useState(null);
  const [fetchingMore, setFetchingMore] = useState(false);
  const [dailyLimitReached, setDailyLimitReached] = useState(false);
  const [error, setError] = useState(null);
  const isFetchingRef = useRef(false);
  const lastPreferences = useRef({ min: user?.min_age_preference, max: user?.max_age_preference });

  // Add a localStorage key for caching
  const CACHED_PROFILES_KEY = `gotgame_cached_profiles`;
  const CACHED_INDEX_KEY = `gotgame_cached_index`;

  const debugLog = (message, data = {}) => {
    console.group(`🎭 SwipeScreen: ${message}`);
    console.log('State:', {
      profilesCount: profiles.length,
      currentIndex,
      loading,
      fetchingMore,
      isFetching: isFetchingRef.current,
      userInterestedIn: user?.interested_in,
      agePrefs: `${user?.min_age_preference}-${user?.max_age_preference}`,
      superLikesLeft: superLikesRemaining,
      swipesLeft: swipesRemaining
    });
    if (Object.keys(data).length > 0) {
      console.log('Data:', data);
    }
    console.groupEnd();
  };

  // Check if profiles match current age preferences
  const checkProfilesMatchPreferences = (profiles, minAge, maxAge) => {
    return profiles.every(profile => 
      profile.age >= minAge && profile.age <= maxAge
    );
  };

  // Effect to handle age preference changes
  useEffect(() => {
    if (user?.min_age_preference && user?.max_age_preference) {
      const preferencesChanged = 
        lastPreferences.current.min !== user.min_age_preference ||
        lastPreferences.current.max !== user.max_age_preference;

      if (preferencesChanged && profiles.length > 0) {
        const profilesMatch = checkProfilesMatchPreferences(
          profiles,
          user.min_age_preference,
          user.max_age_preference
        );

        if (!profilesMatch) {
          debugLog('Profiles do not match new age preferences, resetting...', {
            currentProfiles: profiles.map(p => p.age),
            newPrefs: `${user.min_age_preference}-${user.max_age_preference}`
          });
          // Clear current profiles and set loading to trigger new fetch
          setProfiles([]);
          setCurrentIndex(0);
          setLoading(true);
        }
      }

      // Update last preferences
      lastPreferences.current = {
        min: user.min_age_preference,
        max: user.max_age_preference
      };
    }
  }, [user?.min_age_preference, user?.max_age_preference]);

  // When component mounts, first check for cached profiles in localStorage or location state
  useEffect(() => {
    // Try to load profiles from multiple sources in order of priority:
    // 1. Location state (coming back from matches/settings)
    // 2. LocalStorage cache
    // 3. Fetch new profiles if none of the above
    if (location.state?.profiles && location.state?.profiles.length > 0) {
      // Coming back from another screen with profile state
      debugLog('Using profiles from navigation state', { 
        profileCount: location.state.profiles.length,
        currentIndex: location.state.currentIndex
      });
      setProfiles(location.state.profiles);
      setCurrentIndex(location.state.currentIndex || 0);
      setLoading(false);
    } else {
      // Try to load from localStorage
      try {
        const cachedProfilesJson = localStorage.getItem(CACHED_PROFILES_KEY);
        const cachedIndexStr = localStorage.getItem(CACHED_INDEX_KEY);
        
        if (cachedProfilesJson && cachedIndexStr) {
          const cachedProfiles = JSON.parse(cachedProfilesJson);
          const cachedIndex = parseInt(cachedIndexStr, 10);
          
          if (Array.isArray(cachedProfiles) && cachedProfiles.length > 0 && 
              cachedIndex < cachedProfiles.length) {
            debugLog('Using cached profiles from localStorage', { 
              profileCount: cachedProfiles.length, 
              currentIndex: cachedIndex 
            });
            setProfiles(cachedProfiles);
            setCurrentIndex(cachedIndex);
            setLoading(false);
            return; // Skip fetching new profiles
          }
        }
      } catch (error) {
        console.error('Error loading cached profiles:', error);
      }
      
      // If we get here, we need to fetch profiles
      debugLog('No cached profiles found, fetching new profiles');
      fetchProfiles();
    }
    
    // Cleanup function to prevent multiple fetches
    return () => {
      isFetchingRef.current = false;
    };
  }, []);
  
  // Add effect to save profiles to localStorage whenever they change
  useEffect(() => {
    if (profiles.length > 0) {
      try {
        localStorage.setItem(CACHED_PROFILES_KEY, JSON.stringify(profiles));
        localStorage.setItem(CACHED_INDEX_KEY, currentIndex.toString());
        debugLog('Cached profiles to localStorage', { 
          profileCount: profiles.length, 
          currentIndex 
        });
      } catch (error) {
        console.error('Error caching profiles to localStorage:', error);
      }
    }
  }, [profiles, currentIndex]);

  const fetchProfiles = async () => {
    if (isFetchingRef.current) {
      debugLog('Already fetching profiles, skipping');
      return;
    }
    
    try {
      debugLog('Fetching profiles...', {
        ageRange: `${user.min_age_preference}-${user.max_age_preference}`,
        isGuest: user?.isGuest
      });
      isFetchingRef.current = true;
      setFetchingMore(true);
      
      // Try to get next available profile
      const response = await authenticatedFetch(
        `/api/profiles/next?gender=${user.interested_in}`
      );
      const data = await response.json();
      
      debugLog('Fetch response:', data);

      if (data.success && data.profile) {
        // Single profile response
        debugLog('Adding profile', data.profile);
        setProfiles(prev => [...prev, data.profile]);
      } else if (data.error === 'Daily swipe limit reached') {
        debugLog('Daily limit reached');
        setDailyLimitReached(true);
      } else {
        console.error('No more existing profiles available');
        
        // Special handling for guest mode - try direct method if API fails
        if (user?.isGuest) {
          debugLog('Guest mode detected, trying direct profile access');
          // Get the guest profile service that was retrieved at the component level
          const guestService = getGuestProfileService();
          
          if (guestService) {
            // Initialize profiles if needed
            if (!guestService.profiles || !Array.isArray(guestService.profiles) || guestService.profiles.length === 0) {
              guestService.initializeProfiles(user.interested_in, user.age);
            }
            
            const profile = guestService.getNextProfile();
            if (profile) {
              debugLog('Got profile directly from guest service', profile);
              setProfiles(prev => [...prev, profile]);
            } else {
              // No more uninteracted profiles available
              debugLog('No more uninteracted profiles available');
              setDailyLimitReached(true); // Reuse this UI state to show "that's all for today" message
            }
          }
        }
      }
    } catch (error) {
      console.error('Error fetching profiles:', error);
      
      // Special handling for guest mode on error
      if (user?.isGuest) {
        debugLog('Error in guest mode, trying direct profile access');
        // Get the guest profile service that was retrieved at the component level
        const guestService = getGuestProfileService();
        
        if (guestService) {
          // Initialize profiles if needed
          if (!guestService.profiles || !Array.isArray(guestService.profiles) || guestService.profiles.length === 0) {
            guestService.initializeProfiles(user.interested_in, user.age);
          }
          
          const profile = guestService.getNextProfile();
          if (profile) {
            debugLog('Got profile directly from guest service', profile);
            setProfiles(prev => [...prev, profile]);
          } else {
            // No more uninteracted profiles available
            debugLog('No more uninteracted profiles available');
            setDailyLimitReached(true); // Reuse this UI state to show "that's all for today" message
          }
        }
      }
    } finally {
      setLoading(false);
      setFetchingMore(false);
      isFetchingRef.current = false;
    }
  };

  // Fetch more profiles when we're getting low
  useEffect(() => {
    if (profiles.length - currentIndex <= 2 && !isFetchingRef.current) {
      debugLog('Running low on profiles, fetching more...', {
        profilesLeft: profiles.length - currentIndex
      });
      fetchProfiles();
    }
  }, [currentIndex, profiles.length]);

  const handleDragEnd = (event, info) => {
    const swipeThreshold = 100;
    const swipeDirection = info.offset.x;
    const velocity = info.velocity.x;

    if (Math.abs(swipeDirection) > swipeThreshold || Math.abs(velocity) > 800) {
      if (swipeDirection > 0) {
        setDirection('right');
        handleLike();
      } else {
        setDirection('left');
        handlePass();
      }
    }
  };

  const updateInteraction = async (profileId, status, isSuperLike = false) => {
    try {
      debugLog('Updating interaction', { profileId, status, isSuperLike });
      
      // For guest users, handle directly through service
      if (user?.isGuest) {
        const guestService = getGuestProfileService();
        if (guestService) {
          if (status === 'liked' || status === 'superliked') {
            const result = guestService.likeProfile(profileId);
            // If it's a match, return the match data
            if (result.match) {
              return {
                matched: true,
                match: {
                  ...result.match,
                  profileData: profiles[currentIndex]
                }
              };
            }
          } else if (status === 'passed') {
            guestService.dislikeProfile(profileId);
          }
        }
        return { matched: false };
      }
      
      // For regular users, try multiple possible endpoints
      let result = { matched: false };
      
      // If user liked or super liked, try creating a match
      if (status === 'liked' || status === 'superliked') {
        // From inspecting matchRoutes.js and matchController.js, we see that matching 
        // is handled through /api/matches
        try {
          const profile = profiles[currentIndex];
          
          // Call the match creation endpoint
          const response = await authenticatedFetch('/api/matches', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 
              profile,
              superLike: status === 'superliked'
            })
          });
          
          if (response.ok) {
            const data = await response.json();
            if (data.matched) {
              result = {
                matched: true,
                match: {
                  ...data.match,
                  profileData: profile
                }
              };
              
              debugLog('Match created successfully', result);
              
              // If a match was successfully created, return it
              return result;
            }
          } else if (response.status !== 404) {
            // Handle specific errors
            const errorData = await response.json();
            debugLog('Match endpoint returned error', errorData);
            
            if (errorData.error === 'Daily swipe limit reached') {
              throw new Error('Daily swipe limit reached');
            } else if (errorData.error === 'No super likes remaining') {
              throw new Error('No super likes remaining');
            }
          }
        } catch (error) {
          if (error.message === 'Daily swipe limit reached' || 
              error.message === 'No super likes remaining') {
            throw error; // Re-throw these specific errors to be handled by caller
          }
          console.error('Error creating match:', error);
        }
      }
      
      // Record the interaction for analytics purposes
      try {
        // Skip analytics recording for guest users since the backend doesn't support it
        if (!user?.isGuest) {
          await authenticatedFetch('/api/profiles/interaction', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 
              profileId,
              status
            })
          });
          debugLog('Recorded interaction for analytics');
        } else {
          debugLog('Skipped analytics recording for guest user');
        }
      } catch (error) {
        // Just log this error, don't let it stop the flow
        console.error('Error recording interaction:', error);
        debugLog('Interaction analytics recording failed', { error: error.message });
      }
      
      return result;
    } catch (error) {
      console.error('Error updating interaction:', error);
      throw error; // Let the calling function handle specific errors
    }
  };

  // Update handleSuperLike, handleLike, and handlePass to clear cache after swiping
  const clearProfileCache = (profileId) => {
    debugLog('Clearing profile cache after interaction', { profileId });
    // We don't need to clear the cache, as we're updating currentIndex and that will
    // update the localStorage cache. The profiles array with the correct currentIndex
    // is all we need to maintain state.
  };

  // Update the handlers to call clearProfileCache:
  const handleLike = async () => {
    const profile = profiles[currentIndex];
    debugLog('Handling like', { profile });
    
    // Only proceed if we have enough swipes
    if (swipesRemaining === 0) {
      setError('Daily swipe limit reached');
      setTimeout(() => {
        setError(null); 
      }, 3000);
      return;
    }
    
    try {
      // Record interaction and trigger API
      const result = await updateInteraction(profile.id, 'liked');
      
      // Update cache as profile has been interacted with
      clearProfileCache(profile.id);
      
      // Update state for animation
      setDirection('right');
      
      // *** IMMEDIATELY update the UI to show one less swipe ***
      // For unlimited swipes, keep it as -1
      if (swipesRemaining !== -1) {
        // Decrement the swipe count
        const newSwipeCount = Math.max(0, swipesRemaining - 1);
        debugLog('Updating swipe count in UI', { 
          previous: swipesRemaining, 
          new: newSwipeCount 
        });
        setSwipesRemaining(newSwipeCount);
        
        // Also update localStorage for guest users
        if (user?.isGuest) {
          try {
            const guestData = JSON.parse(localStorage.getItem('guestUser'));
            if (guestData) {
              guestData.swipes = Math.max(0, (guestData.swipes || 0) - 1);
              localStorage.setItem('guestUser', JSON.stringify(guestData));
              debugLog('Updated guest swipes in localStorage', { 
                newSwipes: guestData.swipes 
              });
            }
          } catch (e) {
            console.error('Error updating guest swipes in localStorage:', e);
          }
        }
      }
      
      // If a match was created, show the match screen
      if (result && result.matched) {
        debugLog('Match created from like!', result);
        
        // Use the match from the result, or create one if it's missing
        const matchData = result.match || createMatchFromProfile(profile, false);
        
        // Ensure we have profileData
        if (!matchData.profileData && profile) {
          matchData.profileData = profile;
        }
        
        setCurrentMatch(matchData);
        setTimeout(() => {
          setShowMatch(true);
        }, 500);
      }
    } catch (error) {
      console.error('Error handling like:', error);
      
      // Handle specific error messages
      if (error.message === 'Daily swipe limit reached') {
        setError('Daily swipe limit reached');
        setTimeout(() => {
          setError(null);
        }, 3000);
      } else {
        // For other errors, show generic message
        setError('Unable to like profile. Please try again.');
        setTimeout(() => {
          setError(null);
        }, 3000);
      }
      
      // Reset direction to prevent animation
      setDirection(null);
    }
  };
  
  const handleSuperLike = async () => {
    const profile = profiles[currentIndex];
    debugLog('Handling super like', { profile });
    
    // Only proceed if we have enough super likes
    if (superLikesRemaining === 0) {
      setError('No super likes remaining');
      setTimeout(() => {
        setError(null);
      }, 3000);
      return;
    }
    
    try {
      // Record interaction and trigger API
      const result = await updateInteraction(profile.id, 'superliked', true);
      
      // Update cache as profile has been interacted with
      clearProfileCache(profile.id);
      
      // Update state for animation
      setDirection('right');
      
      // *** IMMEDIATELY update the UI to show one less super like ***
      // Decrement the super like count
      const newSuperLikeCount = Math.max(0, superLikesRemaining - 1);
      debugLog('Updating super like count in UI', { 
        previous: superLikesRemaining, 
        new: newSuperLikeCount 
      });
      setSuperLikesRemaining(newSuperLikeCount);
      
      // Also update localStorage for guest users
      if (user?.isGuest) {
        try {
          const guestData = JSON.parse(localStorage.getItem('guestUser'));
          if (guestData) {
            guestData.superLikes = Math.max(0, (guestData.superLikes || 0) - 1);
            localStorage.setItem('guestUser', JSON.stringify(guestData));
            debugLog('Updated guest super likes in localStorage', { 
              newSuperLikes: guestData.superLikes 
            });
          }
        } catch (e) {
          console.error('Error updating guest super likes in localStorage:', e);
        }
      }
      
      // With super likes, we always want to show a match
      // If the API didn't return a match, create one locally
      const hasServerMatch = result && result.matched;
      
      // Use match from result or create one
      const matchData = hasServerMatch && result.match ? 
        result.match : 
        createMatchFromProfile(profile, true);
      
      // Ensure we have profileData
      if (!matchData.profileData && profile) {
        matchData.profileData = profile;
      }
      
      debugLog('Match created from super like!', { 
        fromServer: hasServerMatch, 
        match: matchData 
      });
      
      setCurrentMatch(matchData);
      setTimeout(() => {
        setShowMatch(true);
      }, 500);
    } catch (error) {
      console.error('Error handling super like:', error);
      
      // Handle specific error messages
      if (error.message === 'No super likes remaining') {
        setError('No super likes remaining');
        setTimeout(() => {
          setError(null);
        }, 3000);
      } else {
        // For other errors, show generic message
        setError('Unable to super like profile. Please try again.');
        setTimeout(() => {
          setError(null);
        }, 3000);
      }
      
      // Reset direction to prevent animation
      setDirection(null);
    }
  };
  
  const handlePass = async () => {
    const profile = profiles[currentIndex];
    debugLog('Handling pass', { profile });
    
    try {
      await updateInteraction(profile.id, 'passed');
      
      // For guest users, explicitly handle the dislike action
      if (user?.isGuest) {
        const guestService = getGuestProfileService();
        if (guestService && profile?.id) {
          guestService.dislikeProfile(profile.id);
          debugLog('Guest mode: explicitly disliked profile', { profileId: profile.id });
        }
      }
      
      // Also update cache (no need to clear, just let currentIndex update)
      clearProfileCache(profile.id);
      
      // Animation completion handler will move to the next card
    } catch (error) {
      console.error('Error handling pass:', error);
      // Still allow the animation to continue and move to next card
      
      // Show a brief error but allow the pass to continue
      setError('Error recording dislike, but continuing');
      setTimeout(() => {
        setError(null);
      }, 2000);
    }
  };

  const handleCloseMatch = () => {
    setShowMatch(false);
    // Wait for the animation to complete before clearing state
    setTimeout(() => {
      // Just reset the match state, but don't change the card index
      // The animation's onAnimationComplete handler will advance the card
      setCurrentMatch(null);
      // Don't call setCurrentIndex here as it causes double advancement
    }, 300);
  };

  // Helper function to create a match object from a profile
  const createMatchFromProfile = (profile, isSuperLike = false) => {
    const matchId = `match_${Date.now()}`;
    return {
      id: matchId,
      timestamp: new Date().toISOString(),
      profileData: profile,
      superLike: isSuperLike,
      messages: []
    };
  };

  const goToMatches = () => {
    navigate('/matches', {
      state: {
        returnTo: '/swipe',
        profiles,
        currentIndex
      }
    });
  };

  const goToSettings = () => {
    navigate('/settings', {
      state: {
        returnTo: '/swipe',
        profiles,
        currentIndex
      }
    });
  };

  const renderMainContent = () => {
    if (dailyLimitReached) {
      const isGuestWithNoProfiles = user?.isGuest && profiles.length === 0;
      
      return (
        <div className="h-[70vh] flex flex-col items-center justify-center bg-gradient-to-b from-indigo-600 via-purple-500 to-pink-500 rounded-2xl">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-center px-6"
          >
            <h2 className="text-white text-3xl font-bold mb-4">
              {isGuestWithNoProfiles ? "You've seen everyone!" : "That's all for today!"}
            </h2>
            <p className="text-white/90 text-xl">
              {isGuestWithNoProfiles 
                ? "You've gone through all available profiles" 
                : "Come back tomorrow for more matches"}
            </p>
            <motion.div
              animate={{
                scale: [1, 1.1, 1],
                opacity: [0.8, 1, 0.8],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut"
              }}
              className="mt-8"
            >
              <Heart className="text-white w-16 h-16 mx-auto" />
            </motion.div>
          </motion.div>
        </div>
      );
    }

    if (loading || (currentIndex >= profiles.length && fetchingMore)) {
      debugLog('Showing loading spinner', {
        loading,
        currentIndex,
        profilesLength: profiles.length,
        fetchingMore
      });
      return <LoadingSpinner />;
    }

    const currentProfile = profiles[currentIndex];
    if (!currentProfile) {
      debugLog('No current profile');
      return null;
    }

    // Get the first photo URL from the array
    const photoUrl = Array.isArray(currentProfile.photos) ? currentProfile.photos[0] : currentProfile.photos;
    const profileId = currentProfile.id || `profile-${currentIndex}`;
    
    debugLog('Rendering profile', { 
      currentProfile, 
      photoUrl,
      profileId,
      direction
    });

    return (
      <AnimatePresence>
        <motion.div
          key={currentProfile.id}
          className="h-[70vh] w-full bg-white rounded-2xl shadow-lg overflow-hidden relative"
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={handleDragEnd}
          onAnimationComplete={() => {
            // Only advance card if direction is set (swipe happened)
            if (direction) {
              setTimeout(() => {
                setCurrentIndex(currentIndex + 1);
                setDirection(null);
              }, 200);
            }
          }}
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ 
            scale: 1,
            opacity: 1,
            x: direction === 'left' ? -1000 : direction === 'right' ? 1000 : 0,
            rotate: direction === 'left' ? -20 : direction === 'right' ? 20 : 0
          }}
          exit={{ opacity: 0 }}
          transition={{ 
            type: 'spring',
            stiffness: 500,
            damping: 50
          }}
        >
          {photoUrl ? (
            <img
              src={photoUrl.url}
              alt={currentProfile.name}
              className="w-full h-full object-cover"
              onError={(e) => {
                console.error('Image failed to load:', photoUrl);
                e.target.src = 'https://via.placeholder.com/400x600?text=Image+Failed+to+Load';
              }}
              draggable="false"
            />
          ) : (
            <div className="w-full h-full flex items-center justify-center bg-gray-200">
              <p className="text-gray-500">No image available</p>
            </div>
          )}

          <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/80 to-transparent p-6 pointer-events-none">
            <h2 className="text-white text-3xl font-bold mb-2">
              {currentProfile.name}, {currentProfile.age}
            </h2>
            <p className="text-white/90 text-lg mb-1">
              {currentProfile.occupation}
            </p>
            <p className="text-white/80">
              {currentProfile.location}
            </p>
          </div>

          <button
            onClick={() => setShowInfo(!showInfo)}
            className="absolute top-4 right-4 bg-white/20 backdrop-blur-sm p-2 rounded-full"
          >
            <Info className="text-white" size={24} />
          </button>

          {showInfo && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              className="absolute inset-0 bg-black/80 backdrop-blur-sm p-6 flex flex-col justify-center"
              onClick={() => setShowInfo(false)}
            >
              <h3 className="text-white text-xl font-semibold mb-4">About {currentProfile.name}</h3>
              <p className="text-white/90 mb-4">{currentProfile.bio}</p>
              <div className="flex flex-wrap gap-2">
                {currentProfile.interests.map((interest, index) => (
                  <span
                    key={index}
                    className="bg-white/20 text-white px-3 py-1 rounded-full text-sm"
                  >
                    {interest}
                  </span>
                ))}
              </div>
            </motion.div>
          )}
        </motion.div>
      </AnimatePresence>
    );
  };

  return (
    <div className="h-screen bg-gray-100">
      <div className="max-w-md mx-auto h-full p-4">
        {/* Header with settings and matches buttons */}
        <div className="flex justify-between mb-4">
          <button
            onClick={goToSettings}
            className="bg-white p-3 rounded-full shadow-sm hover:bg-gray-50 transition-colors"
          >
            <Settings size={20} className="text-gray-600" />
          </button>
          <button
            onClick={goToMatches}
            className="bg-white p-3 rounded-full shadow-sm hover:bg-gray-50 transition-colors"
          >
            <Heart size={20} className="text-gray-600" />
          </button>
        </div>

        {/* Error message */}
        {error && (
          <div className="mb-4 bg-red-50 border border-red-200 text-red-600 p-3 rounded-lg text-center">
            {error}
          </div>
        )}

        {/* Main content area */}
        {renderMainContent()}

        {/* Action buttons */}
        {!loading && !dailyLimitReached && currentIndex < profiles.length && (
          <div className="flex justify-center gap-6 mt-6">
            <button
              onClick={() => {
                setDirection('left');
                handlePass();
              }}
              className="bg-white w-16 h-16 rounded-full flex items-center justify-center shadow-lg hover:scale-105 transition-transform"
            >
              <X className="text-red-500" size={32} />
            </button>

            <div className="relative group">
              <button
                onClick={() => {
                  if (superLikesRemaining > 0) {
                    setDirection('up');
                    handleSuperLike();
                  }
                }}
                disabled={superLikesRemaining === 0}
                className={`bg-white w-16 h-16 rounded-full flex items-center justify-center shadow-lg transition-transform ${
                  superLikesRemaining > 0 ? 'hover:scale-105' : 'opacity-50 cursor-not-allowed'
                }`}
              >
                <Star className="text-blue-500" size={32} fill="currentColor" />
              </button>
              <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-black/80 text-white text-sm px-2 py-1 rounded whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity">
                {superLikesRemaining} Super {superLikesRemaining === 1 ? 'Like' : 'Likes'} Left
              </div>
            </div>

            <div className="relative group">
              <button
                onClick={() => {
                  if (swipesRemaining !== 0) {
                    setDirection('right');
                    handleLike();
                  }
                }}
                disabled={swipesRemaining === 0}
                className={`bg-white w-16 h-16 rounded-full flex items-center justify-center shadow-lg transition-transform ${
                  swipesRemaining !== 0 ? 'hover:scale-105' : 'opacity-50 cursor-not-allowed'
                }`}
              >
                <Heart className="text-purple-500" size={32} />
              </button>
              <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-black/80 text-white text-sm px-2 py-1 rounded whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity">
                {swipesRemaining === -1 ? '∞' : swipesRemaining} Swipes Left
              </div>
            </div>
          </div>
        )}
      </div>

      <MatchModal 
        isOpen={showMatch} 
        onClose={handleCloseMatch} 
        match={currentMatch}
      />
    </div>
  );
};

export default SwipeScreen;
