import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';
import { MessageCircle, ChevronLeft, Info, X } from 'lucide-react';

const MatchesScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authenticatedFetch, user, getGuestProfileService } = useAuth();
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [unmatchingId, setUnmatchingId] = useState(null);
  
  // Get guest profile service if in guest mode
  const guestProfileService = user?.isGuest ? getGuestProfileService() : null;

  useEffect(() => {
    fetchMatches();
  }, [unmatchingId]);

  const fetchMatches = async () => {
    try {
      const response = await authenticatedFetch('/api/matches');
      const data = await response.json();
      
      // Ensure we have valid matches data with a fallback to empty array
      setMatches(data.matches || []);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching matches:', error);
      // Set matches to an empty array on error
      setMatches([]);
      setLoading(false);
    }
  };

  const handleUnmatch = async (matchId) => {
    if (!matchId) {
      console.error('Attempted to unmatch with invalid matchId:', matchId);
      return;
    }
    
    try {
      console.log(`Sending unmatch request for match: ${matchId}`);
      
      // First, immediately remove the match from UI to prevent further interactions
      setMatches(prevMatches => prevMatches.filter(match => match.id !== matchId));
      
      // For guest users, use the direct method first (much more reliable)
      if (user?.isGuest && guestProfileService) {
        const directSuccess = guestProfileService.safeUnmatchProfile(matchId);
        console.log(`Direct unmatch for guest user: ${directSuccess ? 'successful' : 'failed'}`);
        
        // If direct method succeeds, we're done
        if (directSuccess) {
          console.log(`Guest unmatch completed successfully for match: ${matchId}`);
          setUnmatchingId(null);
          return;
        }
      }
      
      // If direct method fails or user is not a guest, fall back to API call
      const response = await authenticatedFetch(`/api/matches/${matchId}/unmatch`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ matchId }) // Include a minimal valid JSON body
      });
      
      // If the request failed, we need to put the match back
      if (!response.ok) {
        console.error(`Failed to unmatch: ${matchId}`);
        // Refresh matches from server instead of trying to restore the match
        fetchMatches();
      } else {
        console.log(`Successfully unmatched match: ${matchId}`);
        // Just to be sure, clear unmatching ID
        setUnmatchingId(null);
      }
    } catch (error) {
      console.error('Error unmatching:', error);
      // On error, refresh matches to restore consistency
      fetchMatches();
    } finally {
      // Always reset the unmatchingId after a short delay to ensure animation completes
      setTimeout(() => {
        setUnmatchingId(null);
      }, 500);
    }
  };

  const getPhotoUrl = (photos) => {
    if (!photos) return null;

    // If photos is an array, get first photo's URL
    if (Array.isArray(photos) && photos.length > 0) {
      return photos[0].url;
    }
    // If photos is a single object, get its URL
    if (photos.url) {
      return photos.url;
    }

    return null;
  };

  const handleChatClick = (e, match) => {
    e.stopPropagation(); // Prevent drag from activating
    
    // Get the photo URL
    const profileImageUrl = getPhotoUrl(match.profileData.photos);
    
    navigate('/chat', {
      state: {
        mode: 'swipe',
        matchId: match.id,
        character: {
          id: match.profileData.name.toLowerCase(),
          name: match.profileData.name,
          description: match.profileData.bio,
          photos: match.profileData.photos,
          profileImageUrl: profileImageUrl, // Explicitly include profile image URL
          personality: match.profileData.personality
        }
      }
    });
  };

  const handleProfileClick = (e, match) => {
    e.stopPropagation(); // Prevent drag from activating
    setSelectedProfile(match);
  };

  const handleBackClick = () => {
    // Return to SwipeScreen with preserved state
    if (location.state?.returnTo === '/swipe') {
      navigate('/swipe', {
        state: {
          profiles: location.state.profiles,
          currentIndex: location.state.currentIndex
        }
      });
    } else {
      navigate('/swipe');
    }
  };

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  return (
    <div className="h-screen bg-gray-100">
      {selectedProfile ? (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          className="h-full bg-white"
        >
          <div className="relative h-[60vh]">
            {getPhotoUrl(selectedProfile.profileData.photos) ? (
              <img
                src={getPhotoUrl(selectedProfile.profileData.photos)}
                alt={selectedProfile.profileData.name}
                className="w-full h-full object-cover"
                onError={(e) => {
                  console.error('Image failed to load:', getPhotoUrl(selectedProfile.profileData.photos));
                  e.target.src = 'https://via.placeholder.com/400x600?text=Image+Failed+to+Load';
                }}
              />
            ) : (
              <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                <p className="text-gray-500">No image available</p>
              </div>
            )}
            <button
              onClick={() => setSelectedProfile(null)}
              className="absolute top-4 left-4 bg-black/20 backdrop-blur-sm p-2 rounded-full text-white"
            >
              <ChevronLeft size={24} />
            </button>
            <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/80 to-transparent p-6">
              <h2 className="text-white text-3xl font-bold mb-2">
                {selectedProfile.profileData.name}, {selectedProfile.profileData.age}
              </h2>
              <p className="text-white/90 text-lg mb-1">
                {selectedProfile.profileData.occupation}
              </p>
              <p className="text-white/80">
                {selectedProfile.profileData.location}
              </p>
            </div>
          </div>

          <div className="p-6 space-y-6">
            <div>
              <h3 className="text-lg font-semibold mb-2">About</h3>
              <p className="text-gray-600">{selectedProfile.profileData.bio}</p>
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-2">Interests</h3>
              <div className="flex flex-wrap gap-2">
                {selectedProfile.profileData.interests.map((interest, index) => (
                  <span
                    key={index}
                    className="bg-purple-100 text-purple-600 px-3 py-1 rounded-full text-sm"
                  >
                    {interest}
                  </span>
                ))}
              </div>
            </div>

            <div className="flex space-x-2">
              <button
                onClick={(e) => handleChatClick(e, selectedProfile)}
                className="bg-purple-100 text-purple-600 p-2 rounded-full hover:bg-purple-200 transition-colors relative z-10"
              >
                <MessageCircle size={20} />
              </button>
              
              {user?.isGuest && guestProfileService ? (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    console.log(`Direct unmatch request for match: ${selectedProfile.id}`);
                    // Update UI first
                    setMatches(prevMatches => prevMatches.filter(m => m.id !== selectedProfile.id));
                    // Use direct method for guest users
                    guestProfileService.safeUnmatchProfile(selectedProfile.id);
                  }}
                  className="bg-red-100 text-red-600 p-2 rounded-full hover:bg-red-200 transition-colors relative z-10"
                >
                  <X size={20} />
                </button>
              ) : (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    // Use the regular method for non-guest users
                    setUnmatchingId(selectedProfile.id);
                    handleUnmatch(selectedProfile.id);
                  }}
                  className="bg-red-100 text-red-600 p-2 rounded-full hover:bg-red-200 transition-colors relative z-10"
                >
                  <X size={20} />
                </button>
              )}
            </div>
          </div>
        </motion.div>
      ) : (
        <div className="h-full flex flex-col">
          {/* Fixed header */}
          <div className="max-w-md w-full mx-auto px-4 py-4">
            <div className="flex items-center justify-between">
              <button
                onClick={handleBackClick}
                className="bg-white p-2 rounded-full shadow-sm hover:bg-gray-50 transition-colors"
              >
                <ChevronLeft size={24} className="text-gray-600" />
              </button>
              <h1 className="text-2xl font-bold">Matches</h1>
              <div className="w-8"></div> {/* Spacer for centering */}
            </div>
          </div>
          
          {/* Scrollable content */}
          <div className="flex-1 overflow-y-auto bg-white min-h-0">
            <div className="max-w-md mx-auto px-4 pb-20 space-y-4 min-h-full">
              {matches.length === 0 ? (
                <div className="text-center py-12 text-gray-500">
                  <p>No matches yet</p>
                  <p className="text-sm">Keep swiping to find your match!</p>
                </div>
              ) : (
                <AnimatePresence mode="popLayout">
                  {matches.filter(m => m && m.id).map((match) => (
                    <motion.div
                      key={match.id}
                      layout
                      initial={{ scale: 0.8, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      exit={{ 
                        x: -300, 
                        opacity: 0,
                        transition: { duration: 0.2 }
                      }}
                      drag="x"
                      dragConstraints={{ left: 0, right: 0 }}
                      dragElastic={0.7}
                      onDragEnd={(e, info) => {
                        if (info.offset.x < -100) {
                          try {
                            // Safety check - ensure matchId exists
                            if (!match || !match.id) {
                              console.error('Cannot unmatch: match or match.id is undefined', match);
                              return;
                            }
                            
                            setUnmatchingId(match.id);
                            handleUnmatch(match.id);
                          } catch (error) {
                            console.error('Error in drag end handler:', error);
                          }
                        }
                      }}
                      className="bg-white rounded-xl p-4 shadow-sm hover:shadow-md transition-shadow flex items-center gap-4 relative"
                    >
                      {/* Drag handle - entire card except interactive elements */}
                      <div className="absolute inset-0 cursor-grab active:cursor-grabbing" />

                      {/* Unmatch indicator */}
                      <motion.div
                        className="absolute inset-0 bg-red-500/10 rounded-xl flex items-center justify-start pl-4 pointer-events-none"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: match.id === unmatchingId ? 1 : 0 }}
                      >
                        <X className="text-red-500" size={24} />
                      </motion.div>

                      {/* Interactive elements on top of drag handle */}
                      <div 
                        className="w-16 h-16 rounded-full overflow-hidden cursor-pointer relative z-10"
                        onClick={(e) => handleProfileClick(e, match)}
                      >
                        {getPhotoUrl(match.profileData.photos) ? (
                          <img
                            src={getPhotoUrl(match.profileData.photos)}
                            alt={match.profileData.name}
                            className="w-full h-full object-cover"
                            onError={(e) => {
                              console.error('Image failed to load:', getPhotoUrl(match.profileData.photos));
                              e.target.src = 'https://via.placeholder.com/64?text=?';
                            }}
                          />
                        ) : (
                          <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                            <span className="text-gray-500">?</span>
                          </div>
                        )}
                      </div>
                      
                      <div className="flex-1 relative z-10">
                        <h3 className="font-semibold">{match.profileData.name || 'Unknown'}</h3>
                        <p className="text-sm text-gray-500">
                          {match.profileData.occupation || 'No occupation'}
                        </p>
                      </div>

                      <div className="flex space-x-2">
                        <button
                          onClick={(e) => handleChatClick(e, match)}
                          className="bg-purple-100 text-purple-600 p-2 rounded-full hover:bg-purple-200 transition-colors relative z-10"
                        >
                          <MessageCircle size={20} />
                        </button>
                        
                        {user?.isGuest && guestProfileService ? (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              console.log(`Direct unmatch request for match: ${match.id}`);
                              // Update UI first
                              setMatches(prevMatches => prevMatches.filter(m => m.id !== match.id));
                              // Use direct method for guest users
                              guestProfileService.safeUnmatchProfile(match.id);
                            }}
                            className="bg-red-100 text-red-600 p-2 rounded-full hover:bg-red-200 transition-colors relative z-10"
                          >
                            <X size={20} />
                          </button>
                        ) : (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              // Use the regular method for non-guest users
                              setUnmatchingId(match.id);
                              handleUnmatch(match.id);
                            }}
                            className="bg-red-100 text-red-600 p-2 rounded-full hover:bg-red-200 transition-colors relative z-10"
                          >
                            <X size={20} />
                          </button>
                        )}
                      </div>
                    </motion.div>
                  ))}
                </AnimatePresence>
              )}
              
              {/* Sign up promo for guest users */}
              {user?.isGuest && (
                <div className="mt-8 bg-gradient-to-r from-purple-600 to-indigo-600 rounded-xl p-6 shadow-lg text-white">
                  <h3 className="text-xl font-bold mb-3">Ready to upgrade your experience?</h3>
                  <ul className="space-y-2 mb-4">
                    <li className="flex items-start">
                      <div className="mr-2 mt-0.5">•</div>
                      <div>Unlimited matches & conversations</div>
                    </li>
                    <li className="flex items-start">
                      <div className="mr-2 mt-0.5">•</div>
                      <div>Save conversations with your favorite matches</div>
                    </li>
                    <li className="flex items-start">
                      <div className="mr-2 mt-0.5">•</div>
                      <div>More daily swipes & super likes</div>
                    </li>
                    <li className="flex items-start">
                      <div className="mr-2 mt-0.5">•</div>
                      <div>Premium AI models for deeper conversations</div>
                    </li>
                  </ul>
                  <button 
                    onClick={() => navigate('/register')}
                    className="w-full bg-white text-purple-600 font-semibold py-3 rounded-lg hover:bg-gray-100 transition-colors"
                  >
                    Sign Up Now
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MatchesScreen;
