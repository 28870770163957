import React from 'react';
import { motion } from 'framer-motion';

const PhotoGeneratingIndicator = () => {
  return (
    <div className="bg-white/10 rounded-xl p-4 relative">
      <div className="flex items-center space-x-3">
        <div className="animate-pulse bg-white/20 rounded-full h-12 w-12 flex items-center justify-center">
          <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
        </div>
        <div>
          <p className="text-white">Generating photo...</p>
          <p className="text-white/70 text-sm">This may take up to 60 seconds</p>
        </div>
      </div>
    </div>
  );
};

export default PhotoGeneratingIndicator; 