// AppProvider.jsx
import React, { createContext, useContext, useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChatService } from '../services/ChatService';
import { ResponseAnalyzer } from '../services/ResponseAnalyzer';
import AIService from '../services/ai';
import GameService from '../services/GameService';
import { useAuth } from './AuthContext';
import RegistrationPrompt from '../components/RegistrationPrompt';

const AppContext = createContext();

// Global service instances
let globalServices = null;

const initializeGlobalServices = (token, authenticatedFetch) => {
  if (globalServices) {
    console.log('🌍 AppContext: Using existing service instances');
    if (token && globalServices.aiService?.setToken) {
      globalServices.aiService.setToken(token);
    }
    // Ensure authenticatedFetch is updated if already initialized
    if (globalServices.aiService) {
      globalServices.aiService.authenticatedFetch = authenticatedFetch;
    }
    return globalServices;
  }

  console.log('🌍 AppContext: Creating new service instances');
  // Pass authenticatedFetch to AIService constructor
  const aiService = new AIService(authenticatedFetch);
  if (token) {
    aiService.setToken(token);
  }

  globalServices = {
    aiService,
    chatService: new ChatService(aiService),
    responseAnalyzer: new ResponseAnalyzer(aiService),
    gameService: new GameService(aiService)
  };
  console.log('🌍 AppContext: Service instances created successfully');
  return globalServices;
};

export const AppProvider = ({ children }) => {
  const navigate = useNavigate();
  const { token, user, authenticatedFetch } = useAuth();
  
  // Subscription states first since they're used by other states
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const [creditsRemaining, setCreditsRemaining] = useState(user?.isGuest && user?.credits ? user.credits : 0);
  const [lastCreditRefresh, setLastCreditRefresh] = useState(null);

  // Swipe and Super Like states
  const [swipesRemaining, setSwipesRemaining] = useState(user?.isGuest ? 5 : 0);
  const [superLikesRemaining, setSuperLikesRemaining] = useState(user?.isGuest ? 1 : 0);
  const [purchasedSuperLikes, setPurchasedSuperLikes] = useState(0);
  const [lastLimitReset, setLastLimitReset] = useState(null);

  // Base states
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [messages, setMessages] = useState([]);
  const [messageCount, setMessageCount] = useState(0);
  const [rizzScore, setRizzScore] = useState(null);
  const [currentAnalysis, setCurrentAnalysis] = useState(null);
  const [nsfwMode, setNsfwMode] = useState(false);
  const [selectedModel, setSelectedModel] = useState('SIM v1');
  const [memoryUsage, setMemoryUsage] = useState({ used: 0, total: 5000 });
  
  // Add registration prompt state
  const [showRegistrationPrompt, setShowRegistrationPrompt] = useState(false);

  // Model access checks
  const canUseSimV2 = useMemo(() => {
    if (!subscriptionPlan?.name) return false;
    return ['hobby', 'pro', 'ultra'].includes(subscriptionPlan.name.toLowerCase());
  }, [subscriptionPlan]);

  const canUseUnfiltered = useMemo(() => {
    if (!subscriptionPlan?.name) return false;
    return ['pro', 'ultra'].includes(subscriptionPlan.name.toLowerCase());
  }, [subscriptionPlan]);

  // Game states
  const [chatMode, setChatMode] = useState('text');
  const [gameMode, setGameMode] = useState('classic');
  const [lifeBar, setLifeBar] = useState(100);
  const [timeLeft, setTimeLeft] = useState(180);
  const [isGameActive, setIsGameActive] = useState(false);
  const [isCalculatingScore, setIsCalculatingScore] = useState(false);
  
  // Add tutorial state
  const [isTutorialActive, setIsTutorialActive] = useState(false);
  const [tutorialStep, setTutorialStep] = useState(null);

  // Refs
  const gameEndInProgress = useRef(false);
  const navigationScheduled = useRef(false);
  const services = useRef(null);
  const currentMatchId = useRef(null);

  // Guest user check
  const isGuest = useMemo(() => user?.isGuest || false, [user]);

  // Immediately check if there's a guest user in localStorage when component mounts
  useEffect(() => {
    try {
      const guestData = localStorage.getItem('guestUser');
      if (guestData) {
        const parsedGuestData = JSON.parse(guestData);
        if (parsedGuestData && typeof parsedGuestData.credits === 'number') {
          console.log('🌍 AppContext: Initial load - found guest credits in localStorage:', parsedGuestData.credits);
          setCreditsRemaining(parsedGuestData.credits);
        }
      }
    } catch (e) {
      console.error('Error reading initial guest data from localStorage:', e);
    }
  }, []);

  // Initialize services and update token when it changes
  useEffect(() => {
    if (!services.current) {
      console.log('🌍 AppContext: Initializing services with token and guest status', { 
        hasToken: !!token, 
        isGuest: user?.isGuest
      });
      
      // Pass authenticatedFetch when initializing
      services.current = initializeGlobalServices(token, authenticatedFetch); 
      
      // Make sure guest mode is set if needed
      if (user?.isGuest && services.current?.aiService?.setGuestMode) {
        console.log('🌍 AppContext: Setting guest mode on newly initialized services');
        services.current.aiService.setGuestMode(true);
      }
    } else if (services.current?.aiService) {
        // Ensure authenticatedFetch is updated even if services exist
        services.current.aiService.authenticatedFetch = authenticatedFetch;
        
        if (token) {
          console.log('🌍 AppContext: Updating token in services');
          services.current.aiService.setToken(token);
        }
    }
    
    // Always check if guest mode needs to be set/updated
    if (user?.isGuest && services.current?.aiService?.setGuestMode) {
      console.log('🌍 AppContext: Ensuring guest mode is set on AI service');
      services.current.aiService.setGuestMode(true);
    }
  }, [token, user?.isGuest, authenticatedFetch]);

  // Check if we need to show tutorial based on URL state
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get('tutorial') === 'true' || window.location.pathname === '/tutorial') {
      setIsTutorialActive(true);
      setTutorialStep('welcome');
    }
  }, []);

  // Listen for credits and memory updates
  useEffect(() => {
    let memoryUpdateTimeout = null;

    const handleCreditsUpdate = (event) => {
      const { remaining } = event.detail;
      console.log('🌍 AppContext: Credits update received:', remaining);
      
      // Immediately update credits
      setCreditsRemaining(prev => {
        const newCredits = remaining;
        
        // Check if guest user is out of credits
        if (isGuest && newCredits <= 0) {
          setShowRegistrationPrompt(true);
        }
        
        // Save to localStorage if we have a user
        if (user?.id) {
          if (user.isGuest) {
            // For guest users, update the user object in localStorage directly
            try {
              const guestData = JSON.parse(localStorage.getItem('guestUser'));
              if (guestData) {
                guestData.credits = newCredits;
                localStorage.setItem('guestUser', JSON.stringify(guestData));
                console.log('🌍 AppContext: Updated guest credits in localStorage:', newCredits);
              }
            } catch (e) {
              console.error('Error updating guest credits in localStorage:', e);
            }
          } else {
            // For regular users, just save the credit value
            const storageKey = `lastCredits_${user.id}`;
            localStorage.setItem(storageKey, newCredits.toString());
            console.log('🌍 AppContext: Credits saved to localStorage:', {
              userId: user.id,
              credits: newCredits
            });
          }
        }
        
        // Force UI update
        window.requestAnimationFrame(() => {
          console.log('🌍 AppContext: Forcing UI update after credit change');
          // Double check the update went through
          if (prev === newCredits) {
            console.log('🌍 AppContext: Forcing second credit update');
            setCreditsRemaining(newCredits);
          }
        });
        
        return newCredits;
      });
    };

    const handleMemoryUpdate = (event) => {
      const { used, total } = event.detail;
      
      // Use debounce to not update memory too frequently
      clearTimeout(memoryUpdateTimeout);
      memoryUpdateTimeout = setTimeout(() => {
        setMemoryUsage({ used, total });
      }, 100);
    };

    window.addEventListener('creditsUpdate', handleCreditsUpdate);
    window.addEventListener('memoryUpdate', handleMemoryUpdate);

    return () => {
      window.removeEventListener('creditsUpdate', handleCreditsUpdate);
      window.removeEventListener('memoryUpdate', handleMemoryUpdate);
      clearTimeout(memoryUpdateTimeout);
    };
  }, [isGuest, user?.id]);

  // Initialize credits from localStorage for guest users
  useEffect(() => {
    if (user?.isGuest) {
      // Always read directly from localStorage to ensure we have the latest value
      try {
        const freshGuestData = JSON.parse(localStorage.getItem('guestUser'));
        if (freshGuestData) {
          // Load credits if available
          if (typeof freshGuestData.credits === 'number') {
            console.log('🌍 AppContext: Using fresh guest credits from localStorage:', freshGuestData.credits);
            setCreditsRemaining(freshGuestData.credits);
          } else {
            // Fallback to the user object if credits is missing
            setCreditsRemaining(user.credits || 25);
          }
          
          // Load swipes if available
          if (typeof freshGuestData.swipes === 'number') {
            console.log('🌍 AppContext: Using fresh guest swipes from localStorage:', freshGuestData.swipes);
            setSwipesRemaining(freshGuestData.swipes);
          } else {
            // Initialize swipes if not in localStorage yet
            setSwipesRemaining(5);
            // Save initial value to localStorage
            freshGuestData.swipes = 5;
            localStorage.setItem('guestUser', JSON.stringify(freshGuestData));
          }
          
          // Load super likes if available
          if (typeof freshGuestData.superLikes === 'number') {
            console.log('🌍 AppContext: Using fresh guest super likes from localStorage:', freshGuestData.superLikes);
            setSuperLikesRemaining(freshGuestData.superLikes);
          } else {
            // Initialize super likes if not in localStorage yet
            setSuperLikesRemaining(1);
            // Save initial value to localStorage
            freshGuestData.superLikes = 1;
            localStorage.setItem('guestUser', JSON.stringify(freshGuestData));
          }
        } else {
          // No guest data found, use defaults
          setCreditsRemaining(user.credits || 25);
          setSwipesRemaining(5);
          setSuperLikesRemaining(1);
        }
      } catch (e) {
        console.error('Error reading fresh guest data from localStorage:', e);
        // Fallback to defaults
        setCreditsRemaining(user.credits || 25);
        setSwipesRemaining(5);
        setSuperLikesRemaining(1);
      }
      
      console.log('🌍 AppContext: Initialized guest user with credits, swipes and super likes:', {
        credits: creditsRemaining,
        swipes: swipesRemaining,
        superLikes: superLikesRemaining
      });
    }
  }, [user]);

  // Load credits from localStorage for authenticated users
  useEffect(() => {
    if (user?.id && !user?.isGuest) {
      const storageKey = `lastCredits_${user.id}`;
      const savedCredits = localStorage.getItem(storageKey);
      if (savedCredits) {
        console.log('🌍 AppContext: Loading credits from localStorage:', {
          userId: user.id,
          credits: savedCredits
        });
        setCreditsRemaining(parseInt(savedCredits, 10));
      }
    }
  }, [user?.id, user?.isGuest]);

  // Fetch subscription and limit details on mount and token change
  useEffect(() => {
    const fetchDetails = async () => {
      if (!token || user?.isGuest) return;

      try {
        // Fetch subscription details
        const subResponse = await authenticatedFetch('/api/subscription/my-subscription');
        if (!subResponse.ok) throw new Error('Failed to fetch subscription');
        
        const subData = await subResponse.json();
        setSubscriptionPlan(subData.plan);
        setCreditsRemaining(subData.creditsRemaining);
        setLastCreditRefresh(subData.lastCreditRefresh);

        // Fetch swipe and super like limits
        const limitsResponse = await authenticatedFetch('/api/swipes/limits');
        if (!limitsResponse.ok) throw new Error('Failed to fetch limits');
        
        const limitsData = await limitsResponse.json();
        setSwipesRemaining(limitsData.swipes.remaining === 'unlimited' ? -1 : limitsData.swipes.remaining);
        setSuperLikesRemaining(limitsData.superLikes.remaining);
        setPurchasedSuperLikes(limitsData.superLikes.purchased);
        setLastLimitReset(new Date(limitsData.lastReset));
      } catch (error) {
        console.error('Error fetching details:', error);
      }
    };

    if (token && !user?.isGuest) {
      fetchDetails();
    }
  }, [token, authenticatedFetch, user?.isGuest]);

  // Function to close registration prompt
  const handleCloseRegistrationPrompt = useCallback(() => {
    setShowRegistrationPrompt(false);
  }, []);

  // Function to advance tutorial
  const advanceTutorial = useCallback((step) => {
    setTutorialStep(step);
  }, []);

  // Function to end tutorial
  const endTutorial = useCallback(() => {
    setIsTutorialActive(false);
    setTutorialStep(null);
  }, []);

  const debugLog = (message, data = {}) => {
    console.group(`🌍 AppContext: ${message}`);
    console.log('Current State:', {
      isGameActive,
      isCalculatingScore,
      rizzScore,
      messageCount,
      gameMode,
      lifeBar,
      gameEndInProgress: gameEndInProgress.current,
      navigationScheduled: navigationScheduled.current,
      hasCharacter: !!selectedCharacter,
      hasServices: !!services.current,
      serviceKeys: services.current ? Object.keys(services.current) : [],
      currentMatchId: currentMatchId.current,
      messagesCount: messages.length,
      hasToken: !!token,
      nsfwMode,
      subscriptionPlan: subscriptionPlan?.name,
      creditsRemaining
    });
    console.log('Updates:', data);
    console.groupEnd();
  };

  // Update game state function
  const updateGameState = useCallback(async (updates) => {
    debugLog('Updating game state', updates);
    
    try {
      const batchedUpdates = Object.entries(updates);
      const promises = batchedUpdates.map(([key, value]) => {
        return new Promise(resolve => {
          switch (key) {
            case 'rizzScore':
              setRizzScore(value);
              break;
            case 'currentAnalysis':
              setCurrentAnalysis(value);
              break;
            case 'isGameActive':
              setIsGameActive(value);
              break;
            case 'messages':
              setMessages(value);
              break;
            case 'messageCount':
              setMessageCount(value);
              break;
            case 'lifeBar':
              setLifeBar(value);
              break;
            case 'timeLeft':
              setTimeLeft(value);
              break;
            case 'isCalculatingScore':
              setIsCalculatingScore(value);
              break;
            case 'nsfwMode':
              setNsfwMode(value);
              break;
            case 'subscriptionPlan':
              setSubscriptionPlan(value);
              break;
            case 'creditsRemaining':
              setCreditsRemaining(value);
              break;
            case 'lastCreditRefresh':
              setLastCreditRefresh(value);
              break;
            default:
              break;
          }
          resolve();
        });
      });

      await Promise.all(promises);
      debugLog('State update complete', updates);
    } catch (error) {
      console.error('Error updating game state:', error);
      throw error;
    }
  }, []);

  const analyzeAndUpdateLifeBar = useCallback(async (aiMessage, userResponse) => {
    if (!services.current || gameMode !== 'lifeBar' || !isGameActive || gameEndInProgress.current) return;
    
    try {
      const responseQuality = await services.current.responseAnalyzer.analyzeResponse(
        aiMessage,
        userResponse
      );

      setLifeBar((prev) => {
        const newValue = responseQuality === 'good' ? Math.min(100, prev + 4) :
                        responseQuality === 'bad' ? Math.max(0, prev - 8) :
                        prev;
        return newValue;
      });
    } catch (error) {
      console.error('Response analysis error:', error);
    }
  }, [gameMode, isGameActive]);

  const handleGameEnd = useCallback(async () => {
    if (!services.current || gameEndInProgress.current || navigationScheduled.current) {
      debugLog('Game end or navigation already in progress, skipping');
      return;
    }

    debugLog('Starting game end process');
    try {
      gameEndInProgress.current = true;
      navigationScheduled.current = true;

      // First update game state
      await updateGameState({
        isGameActive: false,
        isCalculatingScore: true
      });

      // Calculate final score
      const finalAnalysis = await services.current.chatService.calculateRizzScore(messages);
      debugLog('Score calculated', { finalAnalysis });

      // Update final states
      await updateGameState({
        isCalculatingScore: false,
        currentAnalysis: finalAnalysis,
        rizzScore: finalAnalysis.score
      });

      // Schedule navigation for next tick to ensure state updates are processed
      setTimeout(() => {
        debugLog('Navigating to score screen');
        navigate('/score', { replace: true });
        navigationScheduled.current = false;
      }, 0);

    } catch (error) {
      console.error('Game end error:', error);
      await updateGameState({
        isCalculatingScore: false,
        isGameActive: false
      });
      navigationScheduled.current = false;
    } finally {
      gameEndInProgress.current = false;
    }
  }, [messages, updateGameState, navigate]);

  // Reset game function
  const resetGame = useCallback(() => {
    debugLog('Resetting game state');
    gameEndInProgress.current = false;
    navigationScheduled.current = false;
    
    // Clear memory usage from localStorage for current character
    if (selectedCharacter && user?.id) {
      const storageKey = `lastMemoryUsage_${user.id}_${selectedCharacter.name}`;
      localStorage.removeItem(storageKey);
    }
    
    updateGameState({
      messages: [],
      messageCount: 0,
      rizzScore: null,
      currentAnalysis: null,
      lifeBar: 100,
      timeLeft: 180,
      isGameActive: true,
      isCalculatingScore: false,
    });
    setSelectedCharacter(null);
  }, [updateGameState, selectedCharacter, user?.id]);

  // Game end conditions effect
  useEffect(() => {
    const shouldEndGame = (
      (gameMode === 'lifeBar' && lifeBar <= 0.1 && isGameActive && !isCalculatingScore) ||
      (gameMode === 'classic' && !isGameActive && messages.length > 0 && !isCalculatingScore) ||
      (gameMode === 'timeTrial' && timeLeft <= 0 && isGameActive && !isCalculatingScore)
    );

    if (shouldEndGame && !gameEndInProgress.current && !navigationScheduled.current) {
      debugLog('Game end condition met', { 
        gameMode, 
        lifeBar, 
        isGameActive, 
        messageCount: messages.length,
        isCalculatingScore
      });
      handleGameEnd();
    }
  }, [gameMode, lifeBar, isGameActive, messages.length, timeLeft, isCalculatingScore, handleGameEnd]);

  // Function to use a swipe
  const useSwipe = useCallback(async () => {
    // For non-guest users, use the API
    if (!user?.isGuest) {
      try {
        // Try multiple possible endpoints since we're not sure which one exists
        let endpoint = '/api/swipes/use-swipe'; // Correct endpoint from swipeRoutes.js
        let response = await authenticatedFetch(endpoint, { method: 'POST' });
        
        // If that fails, try another endpoint
        if (!response.ok && response.status === 404) {
          endpoint = '/api/users/use-swipe';
          response = await authenticatedFetch(endpoint, { method: 'POST' });
        }
        
        // If that still fails, just continue but log the error
        if (!response.ok) {
          console.warn(`Failed to use swipe API (${response.status}). Continuing anyway.`);
          // For UI purposes, assume we have unlimited swipes rather than breaking the app
          return true;
        }
        
        const data = await response.json();
        setSwipesRemaining(data.remaining === 'unlimited' ? -1 : data.remaining);
        return true;
      } catch (error) {
        console.error('Error using swipe:', error);
        if (error.message?.includes('limit') || error.status === 429) {
          setSwipesRemaining(0);
        }
        // Return true anyway to let the operation continue - the server side can handle
        // enforcing limits next time profiles are fetched
        return true;
      }
    } 
    // For guest users, handle locally
    else {
      if (swipesRemaining === 0) return false;
      
      // If unlimited, don't decrement
      if (swipesRemaining === -1) return true;
      
      // Otherwise decrement
      const newSwipesRemaining = swipesRemaining - 1;
      setSwipesRemaining(newSwipesRemaining);
      
      // Also update localStorage for guest users
      try {
        const guestData = JSON.parse(localStorage.getItem('guestUser'));
        if (guestData) {
          guestData.swipes = newSwipesRemaining;
          localStorage.setItem('guestUser', JSON.stringify(guestData));
          console.log('🌍 AppContext: Updated guest swipes in localStorage:', newSwipesRemaining);
        }
      } catch (e) {
        console.error('Error updating guest swipes in localStorage:', e);
      }
      
      return true;
    }
  }, [user?.isGuest, swipesRemaining, authenticatedFetch]);

  // Function to use a super like
  const useSuperLike = useCallback(async () => {
    // For non-guest users, use the API
    if (!user?.isGuest) {
      try {
        // Try multiple possible endpoints since we're not sure which one exists
        let endpoint = '/api/profiles/use-super-like'; // Try this first
        let response = await authenticatedFetch(endpoint, { method: 'POST' });
        
        // If that fails, try another endpoint
        if (!response.ok && response.status === 404) {
          endpoint = '/api/users/use-super-like';
          response = await authenticatedFetch(endpoint, { method: 'POST' });
        }
        
        // If that still fails, just continue but log the error
        if (!response.ok) {
          console.warn(`Failed to use super like API (${response.status}). Continuing anyway.`);
          // Continue the operation even without API confirmation
          return true;
        }
        
        const data = await response.json();
        setSuperLikesRemaining(data.remaining);
        return true;
      } catch (error) {
        console.error('Error using super like:', error);
        // Return true anyway to let the operation continue
        return true;
      }
    } 
    // For guest users, handle locally
    else {
      if (superLikesRemaining === 0) return false;
      
      // Decrement
      const newSuperLikesRemaining = superLikesRemaining - 1;
      setSuperLikesRemaining(newSuperLikesRemaining);
      
      // Also update localStorage for guest users
      try {
        const guestData = JSON.parse(localStorage.getItem('guestUser'));
        if (guestData) {
          guestData.superLikes = newSuperLikesRemaining;
          localStorage.setItem('guestUser', JSON.stringify(guestData));
          console.log('🌍 AppContext: Updated guest super likes in localStorage:', newSuperLikesRemaining);
        }
      } catch (e) {
        console.error('Error updating guest super likes in localStorage:', e);
      }
      
      return true;
    }
  }, [user?.isGuest, superLikesRemaining, authenticatedFetch]);

  const purchaseSuperLikes = useCallback(async () => {
    try {
      const response = await authenticatedFetch('/api/swipes/purchase-super-likes', {
        method: 'POST'
      });
      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to purchase super likes');
      }
      
      const data = await response.json();
      setPurchasedSuperLikes(data.purchase.amount);
      setSuperLikesRemaining(prev => prev + data.purchase.amount);
      return true;
    } catch (error) {
      console.error('Error purchasing super likes:', error);
      return false;
    }
  }, [authenticatedFetch]);

  // Memoize services for efficient re-renders
  useEffect(() => {
    if (!services.current) {
      const aiService = new AIService();
      if (token) {
        aiService.setToken(token);
      }
      
      if (user?.isGuest) {
        console.log('🌍 AppContext: Setting guest mode on AI service in memoize effect');
        aiService.setGuestMode(true);
      }
      
      services.current = {
        chatService: new ChatService(aiService),
        gameService: new GameService(),
        responseAnalyzer: new ResponseAnalyzer(),
        aiService: aiService
      };
      
      // Make services available globally for auth context
      window.appServices = services.current;
      console.log('🌍 AppContext: Services created and made available globally', {
        hasAI: !!services.current.aiService,
        isGuestMode: services.current.aiService?.isGuestMode
      });
    } else if (token && services.current?.aiService?.setToken) {
      // Add null check for aiService and setToken
      console.log('🌍 AppContext: Updating token in aiService');
      services.current.aiService.setToken(token);
    }
    
    // Add null checks to prevent errors
    if (user?.isGuest && services.current?.aiService?.setGuestMode) {
      console.log('🌍 AppContext: Setting guest mode to TRUE in memoize effect');
      services.current.aiService.setGuestMode(true);
    } else if (services.current?.aiService?.isGuestMode && !user?.isGuest && services.current?.aiService?.setGuestMode) {
      console.log('🌍 AppContext: Setting guest mode to FALSE in memoize effect');
      services.current.aiService.setGuestMode(false);
    }
  }, [token, user?.isGuest]);

  const deductCredits = async (amount) => {
    // Don't deduct credits in development
    if (process.env.NODE_ENV === 'development' && !process.env.FORCE_CREDITS) {
      console.log(`DEV MODE: Would deduct ${amount} credits`);
      return true;
    }
    
    try {
      // Check if user has enough credits
      if (creditsRemaining < amount) {
        console.log('Not enough credits:', creditsRemaining, 'needed:', amount);
        return false;
      }
      
      // Update local state immediately for better UX
      setCreditsRemaining(prev => prev - amount);
      
      // Make API call to deduct credits on the server
      const response = await authenticatedFetch('/api/subscription/deduct-credits', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          amount,
          reason: 'image_generation'
        })
      });
      
      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to deduct credits');
      }
      
      // Get updated credits amount from server
      const data = await response.json();
      setCreditsRemaining(data.creditsRemaining);
      
      return true;
    } catch (error) {
      console.error('Error deducting credits:', error);
      // Restore credits if server call failed
      setCreditsRemaining(prev => prev + amount);
      return false;
    }
  };

  // Memoize context value
  const value = useMemo(
    () => ({
      // Base states
      selectedCharacter,
      setSelectedCharacter,
      messages,
      setMessages,
      messageCount,
      setMessageCount,
      rizzScore,
      setRizzScore,
      currentAnalysis,
      setCurrentAnalysis,
      nsfwMode,
      setNsfwMode,
      selectedModel,
      setSelectedModel,
      canUseSimV2,
      canUseUnfiltered,
      memoryUsage,
      setMemoryUsage,

      // Subscription states
      subscriptionPlan,
      setSubscriptionPlan,
      creditsRemaining,
      setCreditsRemaining,
      lastCreditRefresh,
      setLastCreditRefresh,

      // Swipe and Super Like states
      swipesRemaining,
      setSwipesRemaining,
      superLikesRemaining,
      setSuperLikesRemaining,
      purchasedSuperLikes,
      lastLimitReset,
      useSwipe,
      useSuperLike,
      purchaseSuperLikes,

      // Game states
      chatMode,
      setChatMode,
      gameMode,
      setGameMode,
      lifeBar,
      setLifeBar,
      timeLeft,
      setTimeLeft,
      isGameActive,
      setIsGameActive,
      isCalculatingScore,
      setIsCalculatingScore,

      // Methods
      updateGameState,
      resetGame,
      analyzeAndUpdateLifeBar,
      handleGameEnd,

      // Services
      aiService: services.current?.aiService,
      gameService: services.current?.gameService,

      // Match tracking
      currentMatchId: currentMatchId.current,
      setCurrentMatchId: (id) => {
        if (id !== currentMatchId.current) {
          debugLog('Switching match', { from: currentMatchId.current, to: id });
          currentMatchId.current = id;
          setMessages([]); // Clear messages when switching matches
        }
      },

      // Tutorial states
      isTutorialActive,
      tutorialStep,
      advanceTutorial,
      endTutorial,

      // Guest user check
      isGuest,

      deductCredits
    }),
    [
      selectedCharacter,
      messages,
      messageCount,
      rizzScore,
      currentAnalysis,
      chatMode,
      gameMode,
      lifeBar,
      timeLeft,
      isGameActive,
      isCalculatingScore,
      nsfwMode,
      selectedModel,
      subscriptionPlan,
      creditsRemaining,
      lastCreditRefresh,
      swipesRemaining,
      superLikesRemaining,
      purchasedSuperLikes,
      lastLimitReset,
      useSwipe,
      useSuperLike,
      purchaseSuperLikes,
      updateGameState,
      resetGame,
      analyzeAndUpdateLifeBar,
      handleGameEnd,
      services.current,
      isTutorialActive,
      tutorialStep,
      advanceTutorial,
      endTutorial,
      isGuest,
      deductCredits
    ]
  );

  return <AppContext.Provider value={value}>
    {children}
    {showRegistrationPrompt && <RegistrationPrompt onClose={handleCloseRegistrationPrompt} />}
  </AppContext.Provider>;
};

export const useApp = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useApp must be used within an AppProvider');
  }
  return context;
};

export default AppProvider;
