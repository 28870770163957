import React, { useEffect, useRef } from 'react';
import { PaperAirplaneIcon } from '@heroicons/react/24/solid';
import { useApp } from '../../contexts/AppContext';

const ChatInput = ({
  inputText,
  setInputText,
  handleKeyPress,
  messageCount,
  isTyping,
  handleSendMessage,
  disabled,
}) => {
  const { 
    nsfwMode, 
    setNsfwMode, 
    creditsRemaining, 
    subscriptionPlan,
    canUseUnfiltered 
  } = useApp();

  const textareaRef = useRef(null);

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      textarea.chat-input {
        -webkit-text-fill-color: white !important;
        color: white !important;
        background-color: rgba(255, 255, 255, 0.1) !important;
        -webkit-user-select: text !important;
        user-select: text !important;
      }

      textarea.chat-input::placeholder {
        color: rgba(255, 255, 255, 0.5) !important;
        -webkit-text-fill-color: rgba(255, 255, 255, 0.5) !important;
      }

      @supports (-webkit-touch-callout: none) {
        textarea.chat-input {
          -webkit-text-fill-color: white !important;
          color: white !important;
        }
      }
    `;
    document.head.appendChild(style);
    return () => document.head.removeChild(style);
  }, []);

  const handleInputClick = () => {
    if (textareaRef.current) {
      textareaRef.current.focus();
      // Force keyboard on iOS
      setTimeout(() => {
        textareaRef.current?.focus();
      }, 100);
    }
  };

  return (
    <div className="bg-black/20 p-4 pb-[calc(1rem+env(safe-area-inset-bottom))]">
      <div className="flex justify-between items-center mb-2">
        <div className="flex items-center">
          <div className="text-white/60 text-sm">
            Credits: {creditsRemaining?.toLocaleString() || 0}
          </div>
        </div>
        <div className="relative group">
          <button
            onClick={() => canUseUnfiltered && setNsfwMode(!nsfwMode)}
            className={`px-3 py-1 rounded-full text-sm transition-all ${
              nsfwMode 
                ? 'bg-red-500/80 hover:bg-red-600/80 text-white' 
                : canUseUnfiltered
                ? 'bg-white/20 hover:bg-white/30 text-white/60'
                : 'bg-white/10 text-white/40 cursor-not-allowed'
            }`}
          >
            UNFILTERED {nsfwMode ? 'ON' : 'OFF'} {!canUseUnfiltered && '🔒'}
          </button>
          {!canUseUnfiltered && (
            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-1 bg-black/80 text-white text-xs rounded whitespace-nowrap opacity-0 group-hover:opacity-100 pointer-events-none transition-opacity">
              Upgrade to Pro or higher to unlock
            </div>
          )}
        </div>
      </div>
      
      <div className="relative flex items-center">
        <textarea
          ref={textareaRef}
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          onKeyPress={handleKeyPress}
          onClick={handleInputClick}
          placeholder={disabled ? "Game ended" : "Type your message..."}
          disabled={disabled || isTyping}
          className={`chat-input w-full rounded-full py-3 px-4 pr-12 
            bg-transparent
            border border-white/20
            text-white placeholder-white/50 
            focus:outline-none focus:ring-2 focus:ring-white/30 
            resize-none overflow-hidden
            ${disabled || isTyping ? 'opacity-50 cursor-not-allowed' : ''}
            ${inputText.length > 0 ? 'pr-16' : ''}`}
          rows="1"
          inputMode="text"
          enterKeyHint="send"
          style={{
            minHeight: '48px',
            maxHeight: '120px',
            WebkitTextFillColor: 'white',
            caretColor: 'white',
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            color: 'white',
            WebkitAppearance: 'none',
            touchAction: 'manipulation',
            userSelect: 'text',
            WebkitUserSelect: 'text'
          }}
        />
        
        <button
          onClick={handleSendMessage}
          disabled={disabled || isTyping || !inputText.trim()}
          className={`absolute right-2 p-2 rounded-full transition-all
            ${inputText.trim() 
              ? 'bg-white/20 hover:bg-white/30 text-white' 
              : 'text-white/30'}
            ${disabled || isTyping ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
          `}
        >
          <PaperAirplaneIcon className="h-5 w-5" />
        </button>
      </div>

      {isTyping && (
        <div className="text-white/60 text-sm mt-2 text-center">
          AI is typing...
        </div>
      )}
    </div>
  );
};

export default ChatInput;
