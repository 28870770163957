import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const Register = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { register, upgradeGuestUser, getGuestProfileService } = useAuth();
  
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [interestedIn, setInterestedIn] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isTransferringMatches, setIsTransferringMatches] = useState(false);
  const [isGuestUpgrade, setIsGuestUpgrade] = useState(false);
  const [guestMatchCount, setGuestMatchCount] = useState(0);
  
  // Check if user is coming from guest mode
  useEffect(() => {
    try {
      const guestData = localStorage.getItem('guestUser');
      if (guestData) {
        const parsedData = JSON.parse(guestData);
        if (parsedData && parsedData.isGuest) {
          console.log('User is coming from guest mode');
          setIsGuestUpgrade(true);
          setName(parsedData.name || '');
          
          // Make sure the gender preference format is correct (male/female)
          let preferenceValue = parsedData.interested_in;
          if (preferenceValue) {
            // Normalize to lowercase and ensure it's one of our expected values
            preferenceValue = preferenceValue.toLowerCase();
            if (preferenceValue === 'men') preferenceValue = 'male';
            if (preferenceValue === 'women') preferenceValue = 'female';
            
            // Only set if it's now a valid value
            if (['male', 'female'].includes(preferenceValue)) {
              setInterestedIn(preferenceValue);
              console.log('Setting interested in:', preferenceValue);
            }
          }
          
          // Check if user has matches to transfer
          const guestService = getGuestProfileService();
          if (guestService) {
            const matches = guestService.getMatches();
            if (matches && matches.length > 0) {
              setGuestMatchCount(matches.length);
            }
          }
        }
      }
    } catch (e) {
      console.error('Error checking for guest data:', e);
    }
  }, [getGuestProfileService]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Always validate gender preference, even for guest upgrades
    if (!interestedIn) {
      setError('Please select your gender preference');
      return;
    }
    
    // Normalize interestedIn value to make sure it matches what backend expects
    const normalizedInterestedIn = interestedIn.toLowerCase();
    if (!['male', 'female'].includes(normalizedInterestedIn)) {
      setError('Please select a valid gender preference');
      return;
    }

    // Validate age (must be at least 18)
    if (birthdate) {
      const birthdateObj = new Date(birthdate);
      const today = new Date();
      let age = today.getFullYear() - birthdateObj.getFullYear();
      const monthDiff = today.getMonth() - birthdateObj.getMonth();
      
      // If birthdate month is after current month or same month but birthdate day is after current day, subtract 1 from age
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthdateObj.getDate())) {
        age--;
      }
    
      if (age < 18) {
        setError('You must be at least 18 years old to register');
        return;
      }
    }
    
    setError('');
    setIsLoading(true);
    
    try {
      // If user is upgrading from guest, use upgradeGuestUser function
      if (isGuestUpgrade) {
        setIsTransferringMatches(true);
        
        // Get guest data to log for debugging
        const guestData = localStorage.getItem('guestUser') 
          ? JSON.parse(localStorage.getItem('guestUser')) 
          : null;
          
        console.log('Upgrading guest with data:', {
          email,
          name: guestData?.name,
          interestedIn: normalizedInterestedIn,
          guestInterestValue: guestData?.interested_in
        });
        
        const result = await upgradeGuestUser(email, password);
        
        if (result.success) {
          navigate('/verification-pending', {
            state: { 
              email,
              message: result.message
            },
            replace: true
          });
        } else {
          setError(result.error || 'Registration failed. Please try again.');
        }
      } else {
        // Normal registration
        const response = await register(email, password, name, normalizedInterestedIn, birthdate);
        console.log('Registration successful:', response);
        navigate('/verification-pending', {
          state: { email },
          replace: true
        });
      }
    } catch (err) {
      console.error('Registration error:', err);
      setError(err.response?.data?.message || 'Registration failed. Please try again.');
    } finally {
      setIsLoading(false);
      setIsTransferringMatches(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-600 via-purple-500 to-pink-500 flex items-center justify-center p-6">
      <div className="w-full max-w-md bg-white/10 backdrop-blur-lg rounded-xl p-6 space-y-6">
        <h2 className="text-2xl font-bold text-white text-center">
          {isGuestUpgrade ? 'Create Your Account' : 'Create Account'}
        </h2>
        
        {isGuestUpgrade && guestMatchCount > 0 && (
          <div className="bg-white/10 p-4 rounded-lg text-white">
            <p className="font-semibold">We'll transfer your data:</p>
            <ul className="list-disc pl-5 mt-2 text-sm">
              <li>{guestMatchCount} matches</li>
              <li>All your chat conversations</li>
              <li>Your profile preferences</li>
            </ul>
          </div>
        )}
        
        {error && (
          <div className="bg-red-500/10 border border-red-500/50 text-red-100 p-3 rounded">
            {error}
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          {!isGuestUpgrade && (
            <div>
              <label className="block text-white mb-2">Name</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2 text-white focus:outline-none focus:border-white/30"
                required
                disabled={isLoading}
              />
            </div>
          )}
          <div>
            <label className="block text-white mb-2">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2 text-white focus:outline-none focus:border-white/30"
              required
              disabled={isLoading}
            />
          </div>
          <div>
            <label className="block text-white mb-2">Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2 text-white focus:outline-none focus:border-white/30"
              required
              disabled={isLoading}
            />
          </div>
          {!isGuestUpgrade && (
            <>
              <div>
                <label className="block text-white mb-2">Birthdate</label>
                <input
                  type="date"
                  value={birthdate}
                  onChange={(e) => setBirthdate(e.target.value)}
                  className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2 text-white focus:outline-none focus:border-white/30"
                  required
                  disabled={isLoading}
                />
                <p className="text-white/60 text-xs mt-1">You must be at least 18 years old to register</p>
              </div>
              <div>
                <label className="block text-white mb-2">I'm interested in</label>
                <div className="grid grid-cols-2 gap-4">
                  <button
                    type="button"
                    onClick={() => setInterestedIn('female')}
                    className={`px-4 py-2 rounded-lg border transition-all ${
                      interestedIn === 'female'
                        ? 'bg-white/20 border-white text-white'
                        : 'bg-white/5 border-white/10 text-white/60 hover:bg-white/10'
                    }`}
                    disabled={isLoading}
                  >
                    Women
                  </button>
                  <button
                    type="button"
                    onClick={() => setInterestedIn('male')}
                    className={`px-4 py-2 rounded-lg border transition-all ${
                      interestedIn === 'male'
                        ? 'bg-white/20 border-white text-white'
                        : 'bg-white/5 border-white/10 text-white/60 hover:bg-white/10'
                    }`}
                    disabled={isLoading}
                  >
                    Men
                  </button>
                </div>
              </div>
            </>
          )}
          <button
            type="submit"
            disabled={isLoading}
            className={`w-full bg-white/10 hover:bg-white/20 text-white font-bold py-2 px-4 rounded-lg transition-all ${
              isLoading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {isLoading 
              ? (isTransferringMatches 
                ? 'Transferring Your Data...' 
                : 'Creating Account...') 
              : (isGuestUpgrade 
                ? 'Create Account & Transfer Data' 
                : 'Create Account')}
          </button>
        </form>
        <p className="text-white/60 text-center">
          Already have an account?{' '}
          <button
            onClick={() => navigate('/login')}
            className="text-white underline"
            disabled={isLoading}
          >
            Log in
          </button>
        </p>
      </div>
    </div>
  );
};

export default Register;
