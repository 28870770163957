// ChatScreen.jsx
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { motion } from 'framer-motion';
import { useApp } from '../../contexts/AppContext';
import { useAuth } from '../../contexts/AuthContext';
import ChatHeader from './ChatHeader';
import ChatMessages from './ChatMessages';
import ChatInput from './ChatInput';
import ChatError from './ChatError';
import LoadingScreen from './LoadingScreen';
import VoiceChat from './VoiceChat';
import GameHUD from '../GameHUD';
import MemoryBar from './MemoryBar';
import { useLocation } from 'react-router-dom';
import PhotoGenerationConfirmation from './PhotoGenerationConfirmation';

import { useGameLogic } from './hooks/useGameLogic';
import { useVoiceChat } from './hooks/useVoiceChat';
import { useMessageHandling } from './hooks/useMessageHandling';
import { useInitialization } from './hooks/useInitialization';
import { useTextingBehavior } from './hooks/useTextingBehavior';
import { chatStyle } from '../../config/characters';
import { createCharacterImageTool } from '../../tools/CharacterImageTool';
import { tool } from 'ai';

const ChatScreen = () => {
  const location = useLocation();
  const { 
    chatMode, 
    isGameActive, 
    gameMode,
    isCalculatingScore,
    aiService: appAIService,
    messages,
    setMessages,
    deductCredits
  } = useApp();
  const { user, authenticatedFetch } = useAuth();

  // State for pending photo requests
  const [pendingPhotoRequest, setPendingPhotoRequest] = useState(null);
  // State for photo generation confirmation modal
  const [photoRequestData, setPhotoRequestData] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isGeneratingPhoto, setIsGeneratingPhoto] = useState(false);
  const [resetPhotoClicks, setResetPhotoClicks] = useState(false);

  // Get texting behavior hook for cleanup
  const { clearAllTimeouts } = useTextingBehavior();

  // Only initialize voice chat when in voice mode
  const voiceChatHook = useMemo(() => chatMode === 'voice' ? useVoiceChat(chatMode, appAIService) : {
    isVoiceMode: false,
    isAssistantSpeaking: false,
    volumeLevel: 0,
    error: null,
    isInitialized: false,
    vapiRef: { current: null },
    reinitialize: () => {}
  }, [chatMode, appAIService]);

  const { 
    isVoiceMode, 
    isAssistantSpeaking, 
    volumeLevel, 
    error: voiceError,
    isInitialized,
    vapiRef,
    reinitialize
  } = voiceChatHook;

  const {
    serviceStatus,
    error: initError,
    setError: setInitError,
    isTyping: initTyping,
    messagesEndRef,
    currentPersonality,
    isGenerating,
    getNextMessageId
  } = useInitialization();

  const {
    lifeBar,
    analyzeAndUpdateLifeBar,
    updateLastResponseTime,
  } = useGameLogic();

  const {
    inputText,
    setInputText,
    handleKeyPress,
    handleSendMessage: baseHandleSendMessage,
    isTyping: messageTyping,
    messageCount,
    error: messageError,
    getNextMessageId: msgHandlerGetNextMessageId,
    storeMessageInRAG,
    setTools
  } = useMessageHandling(isGameActive, currentPersonality);

  // Use whichever getNextMessageId is available
  const getMessageId = getNextMessageId || msgHandlerGetNextMessageId;

  // Store reference to messageHandler functions
  const messageHandler = {
    setTools
  };

  // Wrap handleSendMessage to update last response time
  const handleSendMessage = async () => {
    // If there's a pending photo request, remind the user to accept/decline before continuing
    if (pendingPhotoRequest) {
      setPendingPhotoRequest(null); // Clear it anyway to prevent blocking
    }
    
    await baseHandleSendMessage();
    updateLastResponseTime();
  };

  // Handle clicking on a photo preview
  const handleOpenPhotoRequest = (photoData) => {
    setPhotoRequestData(photoData);
    setIsConfirmationModalOpen(true);
    // Don't toggle reset here - the preview should stay in Processing state until confirmed or canceled
  };

  // Handle declining photo generation
  const handleCancelPhotoGeneration = () => {
    setIsConfirmationModalOpen(false);
    setPhotoRequestData(null);
    // Toggle resetPhotoClicks to trigger the useEffect in SnapchatStylePreview
    setResetPhotoClicks(prev => !prev);
    console.log('🔍 Canceled photo generation, toggled resetPhotoClicks');
  };

  // Handle accepting a photo request - now opens the confirmation modal
  const handleAcceptPhoto = async (photoData) => {
    handleOpenPhotoRequest(photoData);
    // We don't toggle reset here as we want to keep the "Processing" state
  };

  // Handle actual photo generation after confirmation
  const handleGeneratePhoto = async () => {
    try {
      setIsConfirmationModalOpen(false);
      setIsGeneratingPhoto(true);
      
      if (!photoRequestData) {
        setInitError('Invalid photo data received');
        setIsGeneratingPhoto(false);
        return;
      }
      
      // Deduct credits
      const success = await deductCredits(photoRequestData.creditCost);
      
      if (!success) {
        // Not enough credits
        setMessages(prev => [...prev, {
          id: getMessageId(),
          text: "You don't have enough credits to generate this photo.",
          sender: 'system',
          type: 'text'
        }]);
        // Toggle reset since we're canceling the photo request
        setResetPhotoClicks(prev => !prev);
        setIsGeneratingPhoto(false);
        return;
      }
      
      // Add a loading message
      const loadingMsgId = getMessageId();
      setMessages(prev => [...prev, {
        id: loadingMsgId,
        type: 'photoGenerating',
        sender: 'ai',
        character: currentPersonality?.name
      }]);
      
      // Make the API call to generate the image with all the data from the photo request
      const response = await authenticatedFetch('/api/images/generate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          characterName: photoRequestData.characterName || currentPersonality?.name,
          characterDescription: photoRequestData.characterDescription || currentPersonality?.description || currentPersonality?.bio || '',
          specificPrompt: photoRequestData.specificPrompt,
          seed: photoRequestData.seed,
          profileImageUrl: photoRequestData.profileImageUrl,
          matchId: location.state?.matchId || photoRequestData.matchId,
          characterId: photoRequestData.characterId || currentPersonality?.id
        })
      });
      
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to generate image');
      }
      
      const result = await response.json();
      
      // Replace loading message with photo
      setMessages(prev => prev.map(msg => 
        msg.id === loadingMsgId ? {
          id: msg.id,
          sender: 'ai',
          character: currentPersonality?.name,
          type: 'photo',
          photoUrl: result.imageUrl,
          text: ''
        } : msg
      ));
      
      // Save to match if needed
      if (location.state?.matchId) {
        try {
          await authenticatedFetch(`/api/matches/${location.state.matchId}/photo-messages`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              imageUrl: result.imageUrl,
              prompt: result.prompt,
              isAI: true
            })
          });
        } catch (error) {
          console.error('Error saving photo message:', error);
        }
      }
      
    } catch (error) {
      console.error('Error generating photo:', error);
      setInitError('Failed to generate photo: ' + error.message);
      
      // Add error message
      setMessages(prev => [...prev, {
        id: getMessageId(),
        text: "Sorry, I couldn't generate that photo. Please try again later.",
        sender: 'ai',
        character: currentPersonality?.name
      }]);
      
      // Make sure to reset the clicked state on error too
      setResetPhotoClicks(prev => !prev);
    } finally {
      setPhotoRequestData(null);
      setIsGeneratingPhoto(false);
    }
  };
  
  // Handle declining a photo request
  const handleDeclinePhoto = () => {
    setPendingPhotoRequest(null);
  };

  // Process tool calls from AI response
  const processToolCallsFromResponse = useCallback((result) => {
    if (!result || !result.toolCalls || !result.toolCalls.length) return null;
    
    // Look for the photo generation tool call
    const photoCall = result.toolCalls.find(call => call.toolName === 'generateCharacterPhoto');
    if (!photoCall) return null;
    
    // Get the result from the tool
    const photoResult = result.toolResults?.find(result => 
      result.toolCallId === photoCall.toolCallId
    )?.result;
    
    if (photoResult && photoResult.readyToView) {
      return photoResult;
    }
    
    return null;
  }, []);

  // Add the photo tool to the message handling hook
  useEffect(() => {
    if (currentPersonality && messageHandler?.setTools) {
      // Create the character image tool
      const characterInfo = {
        id: currentPersonality.id || currentPersonality.name?.toLowerCase(),
        name: currentPersonality.name,
        description: currentPersonality.description || currentPersonality.bio,
        personality: currentPersonality.personality,
        profileImageUrl: currentPersonality.profileImageUrl || 
                        (currentPersonality.photos && currentPersonality.photos.length > 0 ? 
                          currentPersonality.photos[0].url || currentPersonality.photos[0] : 
                          null),
        matchId: location.state?.matchId
      };
      
      // Log character info for debugging
      console.log('📸 Setting up character image tool:', {
        characterName: characterInfo.name,
        hasProfileImage: !!characterInfo.profileImageUrl,
        profileImagePreview: characterInfo.profileImageUrl ? 
          characterInfo.profileImageUrl.substring(0, 30) + '...' : 'none'
      });
      
      const photoTool = createCharacterImageTool(characterInfo, authenticatedFetch);
      
      // Add the tool to the message handler
      messageHandler.setTools([photoTool]);
    }
  }, [currentPersonality, location.state?.matchId, authenticatedFetch]);

  // Combine typing states
  const isTyping = initTyping || messageTyping;

  // Auto-scroll effect
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'auto' });
    }
  }, [messages]);

  // Cleanup all timeouts when component unmounts
  useEffect(() => {
    return () => {
      clearAllTimeouts();
    };
  }, [clearAllTimeouts]);

  // Show loading screen while service is initializing
  if (serviceStatus !== 'ready') {
    return <LoadingScreen status={serviceStatus} />;
  }

  // Show loading screen while waiting for profile (only in regular chat mode)
  // or when generating new profiles after running out
  if ((!currentPersonality && !location.state?.matchId) || isGenerating) {
    return (
      <LoadingScreen 
        status="generating" 
        message={isGenerating ? "Generating new profiles..." : "Finding someone to chat with..."} 
        hasRetry={false}
      />
    );
  }

  // Show loading screen while calculating score
  if (isCalculatingScore) {
    return (
      <LoadingScreen 
        status="calculating" 
        message="Calculating your Rizz score..." 
        hasRetry={false}
      />
    );
  }

  // Show loading screen while initializing voice chat
  if (chatMode === 'voice' && !isInitialized && gameMode !== 'lifebar') {
    return (
      <LoadingScreen 
        status="initializing" 
        message={voiceError || "Initializing voice chat..."}
        hasRetry={true}
        onRetry={reinitialize}
      />
    );
  }

  return (
    <div className="fixed inset-0 h-[100svh] flex flex-col bg-gradient-to-b from-purple-500 to-pink-500">
      <ChatHeader type={chatStyle.type} />
      <GameHUD currentLifeBar={lifeBar} />
      <MemoryBar />

      <ChatMessages
        messages={messages}
        isTyping={isTyping}
        chatMode={chatMode}
        messagesEndRef={messagesEndRef}
        onAcceptPhoto={handleAcceptPhoto}
        onDeclinePhoto={handleDeclinePhoto}
        resetPhotoClicks={resetPhotoClicks}
      />

      {chatMode === 'text' ? (
        <ChatInput
          inputText={inputText}
          setInputText={setInputText}
          handleKeyPress={handleKeyPress}
          messageCount={messageCount}
          isTyping={isTyping}
          handleSendMessage={handleSendMessage}
          disabled={!isGameActive || isCalculatingScore || (pendingPhotoRequest !== null) || isGeneratingPhoto}
        />
      ) : (
        <VoiceChat
          vapiRef={vapiRef}
          isVoiceMode={isVoiceMode}
          isAssistantSpeaking={isAssistantSpeaking}
          volumeLevel={volumeLevel}
          type={chatStyle.type}
          disabled={!isGameActive || isCalculatingScore}
          error={voiceError}
          isInitialized={isInitialized}
          reinitialize={reinitialize}
        />
      )}

      <ChatError
        error={initError || voiceError || messageError}
        setError={setInitError}
      />
      
      {/* Photo Generation Confirmation Modal */}
      <PhotoGenerationConfirmation
        photoData={photoRequestData}
        onAccept={handleGeneratePhoto}
        onDecline={handleCancelPhotoGeneration}
        isOpen={isConfirmationModalOpen}
      />
    </div>
  );
};

export default React.memo(ChatScreen);
