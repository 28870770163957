// Configure API URL based on environment
export const API_URL = import.meta.env.PROD 
  ? 'https://api.gotgames.app'  // Production API endpoint
  : import.meta.env.VITE_API_URL; // Development API endpoint from .env

// Log configuration for debugging
console.log('API Configuration:', {
  isProd: import.meta.env.PROD,
  apiUrl: API_URL,
  envVars: {
    VITE_API_URL: import.meta.env.VITE_API_URL,
    BASE_URL: import.meta.env.BASE_URL,
    MODE: import.meta.env.MODE
  }
});
