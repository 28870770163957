import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { Image, Clock, Check } from 'lucide-react';

const SnapchatStylePreview = ({ photoData, onOpen, character, resetClicked }) => {
  // Track whether this preview has been clicked already
  const [hasBeenClicked, setHasBeenClicked] = useState(false);
  // Track the previous resetClicked value
  const prevResetClickedRef = useRef(resetClicked);
  
  // Reset the clicked state when resetClicked changes
  useEffect(() => {
    // Only reset if resetClicked has actually changed from its previous value
    if (resetClicked !== prevResetClickedRef.current) {
      console.log('🔍 Reset detected! Resetting clicked state');
      setHasBeenClicked(false);
      prevResetClickedRef.current = resetClicked;
    }
  }, [resetClicked]);
  
  console.log('🔍 RENDERING SnapchatStylePreview:', { 
    character,
    hasPhotoData: !!photoData,
    photoDataKeys: photoData ? Object.keys(photoData) : [],
    hasBeenClicked,
    resetClicked,
    prevResetClicked: prevResetClickedRef.current
  });
  
  // Handle click with state tracking
  const handleClick = () => {
    if (!hasBeenClicked) {
      console.log('🔍 SnapchatStylePreview clicked, setting hasBeenClicked to true');
      setHasBeenClicked(true);
      
      // Make sure photoData exists before calling onOpen
      if (photoData) {
        console.log('🔍 SnapchatStylePreview calling onOpen with photoData:', photoData);
        onOpen(photoData);
      } else {
        console.error('🔍 SnapchatStylePreview: No photoData available when clicking');
      }
    } else {
      console.log('🔍 SnapchatStylePreview: Ignoring click as already clicked');
    }
  };
  
  // If we don't have photoData, don't render anything
  if (!photoData) {
    console.error('🔍 SnapchatStylePreview: Missing photoData, not rendering');
    return null;
  }
  
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.2 }}
      className="self-start mb-4 max-w-[80%]"
    >
      <div 
        onClick={handleClick}
        className={`bg-gradient-to-r ${hasBeenClicked ? 'from-gray-500 to-gray-600' : 'from-purple-500 to-pink-500'} rounded-xl p-4 relative ${hasBeenClicked ? 'cursor-default' : 'cursor-pointer hover:opacity-90 transition-opacity'}`}
      >
        <div className="flex items-center space-x-3">
          <div className={`${hasBeenClicked ? 'bg-white/10' : 'bg-white/20'} rounded-full p-3`}>
            {hasBeenClicked ? <Check className="w-6 h-6 text-white" /> : <Image className="w-6 h-6 text-white" />}
          </div>
          <div className="flex-1">
            <h3 className="text-white font-medium">{character || 'Photo'}</h3>
            <p className="text-white/70 text-sm flex items-center">
              <Clock className="w-3 h-3 inline mr-1" />
              {hasBeenClicked ? 'Processing...' : 'Tap to view'}
            </p>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default SnapchatStylePreview; 