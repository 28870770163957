import { useState, useEffect, useRef } from 'react';
import { useApp } from '../../../contexts/AppContext';
import { useAuth } from '../../../contexts/AuthContext';
import { useLocation } from 'react-router-dom';
import { baseSystemPrompt, generatePersonalityPrompt } from '../../../config/characters';
import ProfileService from '../../../services/ProfileService';

export const useInitialization = () => {
  const location = useLocation();
  const { 
    messages, 
    setMessages, 
    setIsGameActive, 
    aiService: appAIService,
    resetGame,
    setMemoryUsage,
    setSelectedCharacter
  } = useApp();
  const { user, authenticatedFetch } = useAuth();
  const [isTyping, setIsTyping] = useState(false);
  const [error, setError] = useState(null);
  const [serviceStatus, setServiceStatus] = useState('checking');
  const [personality, setPersonality] = useState(location.state?.character || null);
  const [isGenerating, setIsGenerating] = useState(false);
  const messagesEndRef = useRef(null);
  const messageIdRef = useRef(1);
  const isGeneratingRef = useRef(false);
  const initAttempts = useRef(0);
  const hasInitialized = useRef(false);
  const currentMatchId = useRef(location.state?.matchId);
  const isLoadingMessages = useRef(false);
  const profileService = useRef(null);

  // Use environment variable from Vercel/Vite
  const API_URL = process.env.VITE_API_URL || 'http://localhost:5023';

  const debugLog = (message, data = {}) => {
    console.group(`🎭 Personality System: ${message}`);
    console.log('State:', {
      hasPersonality: !!personality,
      serviceStatus,
      messagesCount: messages.length,
      isTyping,
      hasError: !!error,
      isGenerating: isGeneratingRef.current,
      initAttempts: initAttempts.current,
      hasAIService: !!appAIService,
      userInterestedIn: user?.interested_in,
      userName: user?.name,
      mode: location.state?.mode,
      hasInitialized: hasInitialized.current,
      matchId: location.state?.matchId,
      currentMatchId: currentMatchId.current,
      isLoadingMessages: isLoadingMessages.current,
      userAgePrefs: user ? `${user.min_age_preference}-${user.max_age_preference}` : 'default'
    });
    if (Object.keys(data).length > 0) {
      console.log('Data:', data);
    }
    console.groupEnd();
  };

  // Initialize profile service
  useEffect(() => {
    if (!profileService.current && authenticatedFetch) {
      profileService.current = new ProfileService(authenticatedFetch);
    }
  }, [authenticatedFetch]);

  // Reset state when component unmounts
  useEffect(() => {
    return () => {
      hasInitialized.current = false;
      currentMatchId.current = null;
      isLoadingMessages.current = false;
      setMessages([]);
      setPersonality(null);
      setSelectedCharacter(null); // Clear global character state too
      setIsGenerating(false);
    };
  }, [setMessages]);

  // Handle chat switching and initialization
  useEffect(() => {
    const initializeChat = async () => {
      // If we're switching chats or haven't initialized yet
      if (location.state?.matchId !== currentMatchId.current || !hasInitialized.current) {
        debugLog('Initializing chat', {
          from: currentMatchId.current,
          to: location.state?.matchId,
          hasInitialized: hasInitialized.current
        });

        // Reset state when switching chats
        if (currentMatchId.current !== location.state?.matchId) {
          resetGame();
          setMessages([]);
          // Only reset personality and character if we're not going to a match chat
          if (!location.state?.matchId) {
            setPersonality(null);
            setSelectedCharacter(null);
          }
        }

        // Update current match ID
        currentMatchId.current = location.state?.matchId;
        hasInitialized.current = false;

        // Load messages for match chat
        if (location.state?.matchId && !isLoadingMessages.current) {
          isLoadingMessages.current = true;
          try {
            // Set personality first so memory load has both character and user ID
            // Set both local and global character state
            if (location.state.character) {
              setPersonality(location.state.character);
              setSelectedCharacter(location.state.character);
            }

            // For guest users, make sure to refresh localStorage data first
            if (user?.isGuest) {
              const guestService = await import('../../../services/GuestProfileService')
                .then(module => new module.default());
              
              // Explicitly refresh messages from localStorage
              debugLog('Refreshing guest messages from localStorage');
              guestService.refreshMessagesFromStorage();
              
              // Check if this match exists in the guest service
              const match = guestService.getMatch(location.state.matchId);
              if (!match) {
                setError(`Match not found: ${location.state.matchId}`);
                return;
              }
              
              // Get messages for this match
              const matchMessages = guestService.getMessages(location.state.matchId);
              
              if (matchMessages && matchMessages.length > 0) {
                debugLog(`Loaded ${matchMessages.length} messages for match ${location.state.matchId}`);
                
                // Process each message to ensure proper format
                const processedMessages = matchMessages.map(msg => {
                  // Check if content is JSON string and parse if needed
                  if (msg.messageType === 'photo' || (msg.content && typeof msg.content === 'string' && msg.content.startsWith('{'))) {
                    try {
                      const photoData = JSON.parse(msg.content);
                      
                      // Convert JSON photo message to proper format
                      if (photoData.type === 'photo' && photoData.url) {
                        return {
                          id: msg.id,
                          type: 'photo',
                          photoUrl: photoData.url,
                          sender: msg.sender || (msg.senderId ? 'user' : 'ai'),
                          character: location.state.character.name,
                          prompt: photoData.prompt
                        };
                      }
                    } catch (e) {
                      console.error('Error parsing photo message JSON:', e);
                    }
                  }
                  
                  // For regular text messages or if JSON parsing failed
                  return {
                    id: msg.id,
                    type: msg.messageType || 'text',
                    text: msg.text || msg.content,
                    sender: msg.sender || (msg.senderId ? 'user' : 'ai'),
                    character: location.state.character.name
                  };
                });
                
                setMessages(processedMessages);
                debugLog('Set processed match messages into state', { count: processedMessages.length });
              } else {
                debugLog(`No messages found for match ${location.state.matchId}`);
              }
            } else {
              // For logged-in users, fetch messages from API
              try {
                const response = await authenticatedFetch(`/api/matches/${location.state.matchId}/messages`);
                if (response.ok) {
                  const data = await response.json();
                  debugLog(`Loaded ${data.messages.length} messages from API for match ${location.state.matchId}`);
                  
                  // Process messages to ensure proper format
                  const processedMessages = data.messages.map(msg => {
                    // Check if content is JSON string with photo data
                    if (msg.messageType === 'photo' || (msg.content && typeof msg.content === 'string' && (msg.content.startsWith('{') || msg.content.includes('"type":"photo"')))) {
                      try {
                        const photoData = JSON.parse(msg.content);
                        
                        // Convert photo message to proper format
                        if (photoData.type === 'photo' && photoData.url) {
                          return {
                            id: msg.id,
                            type: 'photo',
                            photoUrl: photoData.url,
                            sender: msg.senderId ? 'user' : 'ai',
                            character: location.state.character.name,
                            prompt: photoData.prompt
                          };
                        }
                      } catch (e) {
                        console.error('Error parsing message JSON:', e, msg.content);
                      }
                    }
                    
                    // For regular text messages or if JSON parsing failed
                    return {
                      id: msg.id,
                      type: msg.messageType || 'text',
                      text: msg.content,
                      sender: msg.senderId ? 'user' : 'ai',
                      character: location.state.character.name
                    };
                  });
                  
                  setMessages(processedMessages);
                  debugLog('Set processed messages into state', { count: processedMessages.length });
                } else {
                  const error = await response.json();
                  setError(`Failed to load messages: ${error.message}`);
                }
              } catch (error) {
                console.error('Error loading messages:', error);
                setError(`Failed to load messages: ${error.message}`);
              }
            }

            // Fetch memory usage after loading messages
            try {
              debugLog('Fetching memory usage', {
                userId: user.id,
                characterId: location.state.character.name
              });

              const memoryData = await loadMemoryUsage(location.state.character.name);
              debugLog('Memory usage loaded', {
                used: memoryData.used,
                total: memoryData.total
              });
              setMemoryUsage(memoryData);
            } catch (error) {
              console.error('Error loading memory usage:', error);
            }

            hasInitialized.current = true;
            setIsGameActive(true);
          } catch (error) {
            console.error('Error loading match messages:', error);
            setError('Failed to load chat history. Please try again.');
          } finally {
            isLoadingMessages.current = false;
          }
        }
        // Handle swipe mode initialization
        else if (location.state?.mode === 'swipe' && location.state?.character) {
          debugLog('Using swipe mode character', { character: location.state.character });
          setPersonality(location.state.character);
          setSelectedCharacter(location.state.character);
          
          // Fetch memory usage for swipe mode too
          try {
            debugLog('Fetching memory usage for swipe mode', {
              userId: user.id,
              characterId: location.state.character.name
            });

            const memoryData = await loadMemoryUsage(location.state.character.name);
            debugLog('Memory usage loaded for swipe mode', {
              used: memoryData.used,
              total: memoryData.total
            });
            setMemoryUsage(memoryData);
          } catch (error) {
            console.error('Error loading memory usage for swipe mode:', error);
          }

          setIsGameActive(true);
          hasInitialized.current = true;
        }
        // Handle regular chat initialization
        else if (!hasInitialized.current && !isGeneratingRef.current && user?.interested_in && serviceStatus === 'ready') {
          debugLog('Starting regular chat initialization');
          
          try {
            isGeneratingRef.current = true;
            setIsGenerating(true);
            setPersonality(null); // Clear personality while generating new one

            // Get next profile from profile service
            const profile = await profileService.current.getNextProfile(user.interested_in);
            debugLog('Got next profile', { profile });

            setPersonality(profile);
            setSelectedCharacter(profile);
            setIsGameActive(true);
            hasInitialized.current = true;
            debugLog('Chat initialized successfully');
          } catch (error) {
            console.error('Error initializing chat:', error);
            debugLog('Chat initialization failed', { 
              error: error.message,
              stack: error.stack,
              attempt: initAttempts.current
            });
            setError('Failed to start chat. Please refresh the page.');
          } finally {
            isGeneratingRef.current = false;
            setIsGenerating(false);
          }
        }
      }
    };

    initializeChat();
  }, [
    location.state?.matchId,
    location.state?.mode,
    location.state?.character,
    serviceStatus,
    user?.interested_in,
    appAIService,
    setMessages,
    setIsGameActive,
    authenticatedFetch,
    resetGame,
    user
  ]);

  // Initialize services and check health
  useEffect(() => {
    const initializeServices = async () => {
      if (!appAIService) {
        debugLog('AIService not initialized yet, skipping health check');
        return;
      }

      try {
        debugLog('Starting service health check');
        const response = await fetch(`${API_URL}/health`);
        const isHealthy = response.ok;
        setServiceStatus(isHealthy ? 'ready' : 'error');
        debugLog('Service health check complete', { isHealthy });
      } catch (error) {
        console.error('Health check failed:', error);
        debugLog('Health check failed', { 
          error: error.message,
          stack: error.stack 
        });
        setServiceStatus('error');
        setError('Unable to connect to AI service. Please try again later.');
      }
    };

    initializeServices();
  }, [API_URL, appAIService]);

  // Auto-scroll effect
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  // Load memory usage
  const loadMemoryUsage = async (characterId) => {
    try {
      // For guest users or character test mode, return default values
      if (user?.isGuest || location.pathname === '/test') {
        console.log('Guest user or test mode: Using default memory usage');
        setMemoryUsage({ used: 0, total: 5000 });
        return { used: 0, total: 5000 };
      }
      
      const response = await authenticatedFetch(`/api/chat/memory/last-usage?userId=${user?.id || 'guest'}&characterId=${characterId}`);
      
      if (!response.ok) {
        throw new Error(`${response.status}`);
      }
      
      const data = await response.json();
      setMemoryUsage(data);
      return data;
    } catch (error) {
      console.warn('Failed to load memory usage:', error.message);
      // Use a default in case of error
      setMemoryUsage({ used: 0, total: 5000 });
      return { used: 0, total: 5000 };
    }
  };

  return {
    serviceStatus,
    error,
    setError,
    messages,
    isTyping,
    setIsTyping,
    messagesEndRef,
    messageIdRef,
    currentPersonality: personality,
    isGenerating,
  };
};

export default useInitialization;
