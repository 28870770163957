import React, { memo, useState, useEffect } from 'react';
import { useApp } from '../contexts/AppContext';
import { useAuth } from '../contexts/AuthContext';
import ErrorBoundary from './ErrorBoundary';
import { motion, AnimatePresence } from 'framer-motion';

const CreditDisplay = memo(() => {
  const { creditsRemaining } = useApp();
  const { user } = useAuth();
  const [prevCredits, setPrevCredits] = useState(creditsRemaining);
  const [isUpdating, setIsUpdating] = useState(false);
  const [displayCredits, setDisplayCredits] = useState(creditsRemaining);
  
  // If credits are showing as 0 for guest users, check localStorage directly
  useEffect(() => {
    // For guest users showing 0 credits, try to read from localStorage
    if (user?.isGuest && creditsRemaining === 0) {
      try {
        const guestData = localStorage.getItem('guestUser');
        if (guestData) {
          const parsedData = JSON.parse(guestData);
          if (parsedData && typeof parsedData.credits === 'number' && parsedData.credits > 0) {
            console.log('CreditDisplay: Found non-zero credits in localStorage:', parsedData.credits);
            setDisplayCredits(parsedData.credits);
            return;
          }
        }
      } catch (e) {
        console.error('Error reading guest credits in CreditDisplay:', e);
      }
    }
    
    // Otherwise, use the value from context
    setDisplayCredits(creditsRemaining);
  }, [creditsRemaining, user?.isGuest]);

  useEffect(() => {
    if (displayCredits !== prevCredits) {
      setIsUpdating(true);
      setPrevCredits(displayCredits);
      
      // Clear updating state after animation
      const timer = setTimeout(() => {
        setIsUpdating(false);
      }, 1000);
      
      return () => clearTimeout(timer);
    }
  }, [displayCredits, prevCredits]);

  return (
    <div className="text-sm text-white/60 relative">
      <AnimatePresence>
        {isUpdating && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="absolute -top-4 left-0 right-0 text-center text-xs text-yellow-400"
          >
            updating...
          </motion.div>
        )}
      </AnimatePresence>
      <motion.span
        key={displayCredits}
        initial={{ opacity: 0.5, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        className={isUpdating ? 'text-yellow-400' : ''}
      >
        {displayCredits} credits
      </motion.span>
    </div>
  );
});

CreditDisplay.displayName = 'CreditDisplay';

const CreditDisplayWithBoundary = () => (
  <ErrorBoundary fallback={<div className="text-sm text-white/60">-- credits</div>}>
    <CreditDisplay />
  </ErrorBoundary>
);

export default CreditDisplayWithBoundary;
